#app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

*:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}
