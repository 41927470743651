@import "../../variables";

// For styling the base react select components (control and input)
@mixin reactSelectBase($prefix) {
  // stylelint-disable
  @value colorSelected #{$colorLightGray};
  @value colorSelectedLight #{$colorHoverGray};
  @value colorSelectedMedium #{$colorHoverGray};
  // stylelint-enable

  :global {
    .#{$prefix}__control {
      border: $border solid $colorLightGray !important;
      box-shadow: none !important;
      outline: none !important;

      @include textBody;
    }

    .#{$prefix}__control--is-focused {
      border: $border solid $colorSelected !important;
      box-shadow: 0 0 7px 0 rgba($colorBlack, .5) !important;
    }

    .#{$prefix}__input input {
      line-height: normal;

      @include textBody;
    }

    .#{$prefix}__option--is-selected {
      color: $colorText !important;
    }
  }
}

// For styling react select components including menus
@mixin reactSelectWithMenu($prefix) {
  :global {
    .#{$prefix}__control--menu-is-open {
      border-radius: $border-radius $border-radius 0 0 !important;
    }

    .#{$prefix}__menu {
      margin: 0 !important;
      border: $border solid $colorSelected !important;
      border-top: 0 !important;
      border-radius: 0 0 $border-radius $border-radius !important;
      box-shadow: 0 0 7px 0 rgba($colorBlack, .5) !important;
      min-width: 140px;

      @include textBody;
    }

    .#{$prefix}__menu-list {
      padding: 0 !important;
      min-width: 140px;
    }
  }
}

// For styling react select components including menus that open upwards
@mixin reactSelectWithMenuOpenUp($prefix) {
  :global {
    .#{$prefix}__control--menu-is-open {
      border-radius: 0 0 $border-radius $border-radius !important;
    }

    .#{$prefix}__menu {
      margin: 0 !important;
      border: $border solid $colorSelected !important;
      border-bottom: 0 !important;
      border-radius: $border-radius $border-radius 0 0 !important;
      box-shadow: none !important;
      min-width: 140px;

      @include textBody;
    }

    .#{$prefix}__menu-list {
      padding: 0 !important;
      min-width: 140px;
    }
  }
}
