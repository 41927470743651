@import url(https://fonts.googleapis.com/css?family=Hind:300,500,700);
@import url(https://fonts.googleapis.com/css?family=Assistant:400,700&display=swap);
.styles_circleCheck__J_GSZ {
  border-radius: 50%;
  background-color: green;
  padding: 4px;
}
.styles_circleCheck__J_GSZ * {
  fill: white;
}
.styles_textCommon__1qDaT, .styles_textBold__3Zegj, .styles_textSecondary__k4mqe, .styles_textDefault__2lyns {
  line-height: 1.4em;
}

.styles_textDefault__2lyns {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__k4mqe {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__3Zegj {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__-cr1j {
  font-weight: 700;
}

.styles_alignRight__W4ofg {
  text-align: right;
}

.styles_alignCenter__23iv- {
  text-align: center;
}

.styles_tagLabel__1VrTH {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__2lQ0d {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__3qz0Z, .styles_notification__1mRPL .styles_label__NeeEC {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__2_7Al {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__GiCGP,
.styles_textHeading__QB2z7,
.styles_textSubheading__229K- {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__kYwI3 {
  color: #9b9b9b;
}

.styles_container__3RBta {
  position: relative;
}

.styles_padHeading__gG6UD {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__VhY1M {
  padding-top: 1rem;
}

.styles_padY__37QoI, .styles_subtitle__2Lq5j {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__2Itzb {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__1-yov {
  margin-top: 1rem;
}

.styles_marginY__3xeS4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__29eUF {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__1bq44 {
  margin: 0;
  padding: 0;
}

.styles_wrapper__1l7Cp {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__1l7Cp > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__1dgM9 {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__1dgM9 > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__3AMtE {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__3AMtE > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__hDlVY {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__hDlVY > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__CvuGy, .styles_subtitle__2Lq5j {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__CvuGy > *, .styles_subtitle__2Lq5j > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__2R0Sr {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__2R0Sr > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__GiCGP,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__QB2z7,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__229K-,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__1wKbX {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__36bIz {
    padding: 1rem;
  }
  .styles_hideMedium__3NJmF {
    display: none !important;
  }
  .styles_hideLarge__PE1Ui {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__36bIz {
    padding: 1rem;
  }
  .styles_subtitle__2Lq5j {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__2t-MR {
    display: none !important;
  }
  .styles_rowResize__3CcL2 > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__3CcL2 > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__36bIz {
    padding: 0.5rem;
  }
  .styles_padX__2Itzb {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__2lyns {
    font-size: 1.2rem;
  }
  .styles_textSecondary__k4mqe {
    font-size: 1.02rem;
  }
  .styles_textBold__3Zegj {
    font-size: 1.2rem;
  }
  .styles_textHint__2lQ0d {
    font-size: 0.84rem;
  }
  .styles_textLabel__3qz0Z, .styles_notification__1mRPL .styles_label__NeeEC {
    font-size: 1.2rem;
  }
  .styles_navLabel__2_7Al {
    font-size: 0.84rem;
  }
  .styles_tagLabel__1VrTH {
    font-size: 0.85rem;
  }
}
.styles_notification__1mRPL {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  flex-grow: 1;
  align-self: stretch;
  background: rgba(246, 204, 70, 0.85);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
}
.styles_notification__1mRPL .styles_icon__HzhCz {
  padding: 0.35rem 0;
  display: flex;
  justify-content: center;
}
.styles_notification__1mRPL .styles_icon__HzhCz svg {
  width: 1rem;
  height: 1rem;
}
.styles_notification__1mRPL .styles_icon__HzhCz svg path,
.styles_notification__1mRPL .styles_icon__HzhCz svg g {
  fill: #000;
}
.styles_notification__1mRPL .styles_label__NeeEC {
  color: #000;
  padding: 0.35rem;
}
.styles_notification__1mRPL.styles_mobile__2vm4e {
  position: relative;
  padding: 0 0.5rem;
  justify-content: flex-start;
  width: auto !important;
}
.styles_notification__1mRPL.styles_logging__3gdg1 {
  background: rgba(30, 159, 118, 0.95);
}
.styles_notification__1mRPL.styles_logging__3gdg1 .styles_label__NeeEC {
  color: #fff;
}
.styles_notification__1mRPL.styles_logging__3gdg1 .styles_icon__HzhCz svg * {
  fill: #fff;
}
.styles_notification__1mRPL.styles_inline__3zQfZ {
  border-radius: 3px;
  background: #fff4c9;
  position: relative;
  z-index: 10;
  padding: 0 1rem;
  margin: 0.5rem;
}
.styles_textCommon__-iuB2, .styles_textBold__3F-r8, .styles_textSecondary__2XEPy, .styles_textDefault__3AJl7 {
  line-height: 1.4em;
}

.styles_textDefault__3AJl7 {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__2XEPy {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__3F-r8 {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__1WGQP {
  font-weight: 700;
}

.styles_alignRight__qw1vO {
  text-align: right;
}

.styles_alignCenter__3QdKE {
  text-align: center;
}

.styles_tagLabel__2Zc9e {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__3u1QH {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__Du7o5, .styles_notification__1W_Ae .styles_label__CObEe {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__3GunF {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__27scW,
.styles_textHeading__zlwcB,
.styles_textSubheading__1W4lJ {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__y7JTw {
  color: #9b9b9b;
}

.styles_container__1BkX7 {
  position: relative;
}

.styles_padHeading__NBoHU {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__3rUKD {
  padding-top: 1rem;
}

.styles_padY__k18sE, .styles_subtitle__4n-ka {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__3aSj6 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__xLlNL {
  margin-top: 1rem;
}

.styles_marginY__1HhAU {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__1I0y3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__1IFvW {
  margin: 0;
  padding: 0;
}

.styles_wrapper__bNuwp {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__bNuwp > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__33688 {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__33688 > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight___KkrO {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight___KkrO > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__2NW6Z {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__2NW6Z > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__AnzZ9, .styles_subtitle__4n-ka {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__AnzZ9 > *, .styles_subtitle__4n-ka > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__1iYMV {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__1iYMV > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__27scW,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__zlwcB,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__1W4lJ,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__1AT2D {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__4QpIf {
    padding: 1rem;
  }
  .styles_hideMedium__2f1C1 {
    display: none !important;
  }
  .styles_hideLarge__2y7fV {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__4QpIf {
    padding: 1rem;
  }
  .styles_subtitle__4n-ka {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__J5s1a {
    display: none !important;
  }
  .styles_rowResize__acu_Z > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__acu_Z > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__4QpIf {
    padding: 0.5rem;
  }
  .styles_padX__3aSj6 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__3AJl7 {
    font-size: 1.2rem;
  }
  .styles_textSecondary__2XEPy {
    font-size: 1.02rem;
  }
  .styles_textBold__3F-r8 {
    font-size: 1.2rem;
  }
  .styles_textHint__3u1QH {
    font-size: 0.84rem;
  }
  .styles_textLabel__Du7o5, .styles_notification__1W_Ae .styles_label__CObEe {
    font-size: 1.2rem;
  }
  .styles_navLabel__3GunF {
    font-size: 0.84rem;
  }
  .styles_tagLabel__2Zc9e {
    font-size: 0.85rem;
  }
}
.styles_notification__1W_Ae {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  flex-grow: 1;
  align-self: stretch;
  background: rgba(246, 204, 70, 0.85);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
}
.styles_notification__1W_Ae .styles_icon__uOTpY {
  padding: 0.35rem 0;
  display: flex;
  justify-content: center;
}
.styles_notification__1W_Ae .styles_icon__uOTpY svg {
  width: 1rem;
  height: 1rem;
}
.styles_notification__1W_Ae .styles_icon__uOTpY svg path,
.styles_notification__1W_Ae .styles_icon__uOTpY svg g {
  fill: #000;
}
.styles_notification__1W_Ae .styles_label__CObEe {
  color: #000;
  padding: 0.35rem;
}
.styles_notification__1W_Ae .styles_label__CObEe a,
.styles_notification__1W_Ae .styles_label__CObEe a:visited {
  color: #1E9F76;
}
.styles_notification__1W_Ae .styles_label__CObEe a:hover,
.styles_notification__1W_Ae .styles_label__CObEe a:visited:hover {
  color: rgba(30, 159, 118, 0.75);
}
.styles_notification__1W_Ae.styles_mobile__JTEWi {
  position: relative;
  padding: 0 0.5rem;
  justify-content: flex-start;
  width: auto !important;
}
.styles_notification__1W_Ae.styles_inline__2vpKA {
  border-radius: 3px;
  background: #fff4c9;
  position: relative;
  z-index: 1;
  padding: 0 1rem;
  margin: 0.5rem;
}
.styles_textCommon__1bzva, .styles_textBold__3VavL, .styles_textSecondary__3paNh, .styles_textDefault__1FS9g {
  line-height: 1.4em;
}

.styles_textDefault__1FS9g {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__3paNh {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__3VavL {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__2aptT {
  font-weight: 700;
}

.styles_alignRight__16PVx {
  text-align: right;
}

.styles_alignCenter__30kZI {
  text-align: center;
}

.styles_tagLabel__1qPoQ {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__2K6Ns {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__2nXKo {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__3WCd- {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__3sQpc,
.styles_textHeading__12TVS,
.styles_textSubheading__1cQty {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__2ltur {
  color: #9b9b9b;
}

.styles_container__TqZ39 {
  position: relative;
}

.styles_padHeading__2sN_3 {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__1T1H4 {
  padding-top: 1rem;
}

.styles_padY__H7cLW, .styles_subtitle__1hgNi {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__1Fmqi {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__1bFMy {
  margin-top: 1rem;
}

.styles_marginY__y2dTk {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__HgqYO {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__3rQf8 {
  margin: 0;
  padding: 0;
}

.styles_wrapper__2qtU9 {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__2qtU9 > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__2LSZ- {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__2LSZ- > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__34knJ {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__34knJ > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__XcVLP {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__XcVLP > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__2JVIo, .styles_subtitle__1hgNi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__2JVIo > *, .styles_subtitle__1hgNi > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__2pYDY {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__2pYDY > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__3sQpc,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__12TVS,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__1cQty,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__2Rtnu {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__2-hMj {
    padding: 1rem;
  }
  .styles_hideMedium__1mAHh {
    display: none !important;
  }
  .styles_hideLarge__2Gmcr {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__2-hMj {
    padding: 1rem;
  }
  .styles_subtitle__1hgNi {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__2iltw {
    display: none !important;
  }
  .styles_rowResize__3KxB2 > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__3KxB2 > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__2-hMj {
    padding: 0.5rem;
  }
  .styles_padX__1Fmqi {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__1FS9g {
    font-size: 1.2rem;
  }
  .styles_textSecondary__3paNh {
    font-size: 1.02rem;
  }
  .styles_textBold__3VavL {
    font-size: 1.2rem;
  }
  .styles_textHint__2K6Ns {
    font-size: 0.84rem;
  }
  .styles_textLabel__2nXKo {
    font-size: 1.2rem;
  }
  .styles_navLabel__3WCd- {
    font-size: 0.84rem;
  }
  .styles_tagLabel__1qPoQ {
    font-size: 0.85rem;
  }
}
.styles_textCommon__i2-RE, .styles_textBold__3MwJ0, .styles_inputHuman__3GJbN .styles_check__1CNzC .styles_item__2xZ1- > .styles_label__2pzO7, .styles_textSecondary__3Hpnf, .styles_textDefault__FEPrx {
  line-height: 1.4em;
}

.styles_textDefault__FEPrx {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__3Hpnf {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__3MwJ0, .styles_inputHuman__3GJbN .styles_check__1CNzC .styles_item__2xZ1- > .styles_label__2pzO7 {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__XJ-Vi {
  font-weight: 700;
}

.styles_alignRight__3R0LB {
  text-align: right;
}

.styles_alignCenter__1A_71 {
  text-align: center;
}

.styles_tagLabel__sl4gJ {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__-vrLB {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__dgzXG, .styles_inputRadio__3SeUq .styles_check__1CNzC .styles_item__2xZ1- {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__1qxzr {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__366I8,
.styles_textHeading__S9n63,
.styles_textSubheading__2V00L {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__iVQId {
  color: #9b9b9b;
}

.styles_container__1pECl {
  position: relative;
}

.styles_padHeading__35uji {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__3IKNq {
  padding-top: 1rem;
}

.styles_padY__2rOky, .styles_subtitle__3uzaD {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__Q1MCJ {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__2g1Yk {
  margin-top: 1rem;
}

.styles_marginY__dgIXB {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__3auh3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__2kbnY {
  margin: 0;
  padding: 0;
}

.styles_wrapper__16hBF {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__16hBF > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__ADgDc {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__ADgDc > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__J4M-g {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__J4M-g > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__2SSz6 {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__2SSz6 > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__2MqAU, .styles_subtitle__3uzaD {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__2MqAU > *, .styles_subtitle__3uzaD > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__1inpW {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__1inpW > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__366I8,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__S9n63,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__2V00L,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__1W66o {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__3ni_X {
    padding: 1rem;
  }
  .styles_hideMedium__2sSKM {
    display: none !important;
  }
  .styles_hideLarge__Bbvpt {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__3ni_X {
    padding: 1rem;
  }
  .styles_subtitle__3uzaD {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__1JWoM {
    display: none !important;
  }
  .styles_rowResize__1cqrC > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__1cqrC > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__3ni_X {
    padding: 0.5rem;
  }
  .styles_padX__Q1MCJ {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__FEPrx {
    font-size: 1.2rem;
  }
  .styles_textSecondary__3Hpnf {
    font-size: 1.02rem;
  }
  .styles_textBold__3MwJ0, .styles_inputHuman__3GJbN .styles_check__1CNzC .styles_item__2xZ1- > .styles_label__2pzO7 {
    font-size: 1.2rem;
  }
  .styles_textHint__-vrLB {
    font-size: 0.84rem;
  }
  .styles_textLabel__dgzXG, .styles_inputRadio__3SeUq .styles_check__1CNzC .styles_item__2xZ1- {
    font-size: 1.2rem;
  }
  .styles_navLabel__1qxzr {
    font-size: 0.84rem;
  }
  .styles_tagLabel__sl4gJ {
    font-size: 0.85rem;
  }
}
input:disabled {
  cursor: not-allowed !important;
}

.styles_inputCommon__13Dvu .styles_labelRow__3l5E4, .styles_inputFileUpload__2b2zk .styles_labelRow__3l5E4, .styles_inputSelect__3bEop .styles_labelRow__3l5E4, .styles_inputText__kBwo9 .styles_labelRow__3l5E4,
.styles_inputTextArea__2_gZA .styles_labelRow__3l5E4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.styles_inputCommon__13Dvu .styles_input__2TF77, .styles_inputFileUpload__2b2zk .styles_input__2TF77, .styles_inputSelect__3bEop .styles_input__2TF77, .styles_inputText__kBwo9 .styles_input__2TF77,
.styles_inputTextArea__2_gZA .styles_input__2TF77 {
  display: flex;
  position: relative;
}
.styles_inputCommon__13Dvu .styles_input__2TF77 input, .styles_inputFileUpload__2b2zk .styles_input__2TF77 input, .styles_inputSelect__3bEop .styles_input__2TF77 input, .styles_inputText__kBwo9 .styles_input__2TF77 input,
.styles_inputTextArea__2_gZA .styles_input__2TF77 input,
.styles_inputCommon__13Dvu .styles_input__2TF77 textarea,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 textarea,
.styles_inputSelect__3bEop .styles_input__2TF77 textarea,
.styles_inputText__kBwo9 .styles_input__2TF77 textarea,
.styles_inputTextArea__2_gZA .styles_input__2TF77 textarea,
.styles_inputCommon__13Dvu .styles_input__2TF77 select,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 select,
.styles_inputSelect__3bEop .styles_input__2TF77 select,
.styles_inputText__kBwo9 .styles_input__2TF77 select,
.styles_inputTextArea__2_gZA .styles_input__2TF77 select {
  background: #fff;
  font-weight: 300;
  width: 100%;
  -webkit-appearance: none;
  outline: 0;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  line-height: normal !important;
  border: 1px solid #cecece;
  border-radius: 3px;
  font-size: 1rem;
  padding: 0.5rem;
}
.styles_inputCommon__13Dvu .styles_input__2TF77 input::-webkit-input-placeholder, .styles_inputFileUpload__2b2zk .styles_input__2TF77 input::-webkit-input-placeholder, .styles_inputSelect__3bEop .styles_input__2TF77 input::-webkit-input-placeholder, .styles_inputText__kBwo9 .styles_input__2TF77 input::-webkit-input-placeholder,
.styles_inputTextArea__2_gZA .styles_input__2TF77 input::-webkit-input-placeholder,
.styles_inputCommon__13Dvu .styles_input__2TF77 textarea::-webkit-input-placeholder,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 textarea::-webkit-input-placeholder,
.styles_inputSelect__3bEop .styles_input__2TF77 textarea::-webkit-input-placeholder,
.styles_inputText__kBwo9 .styles_input__2TF77 textarea::-webkit-input-placeholder,
.styles_inputTextArea__2_gZA .styles_input__2TF77 textarea::-webkit-input-placeholder,
.styles_inputCommon__13Dvu .styles_input__2TF77 select::-webkit-input-placeholder,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 select::-webkit-input-placeholder,
.styles_inputSelect__3bEop .styles_input__2TF77 select::-webkit-input-placeholder,
.styles_inputText__kBwo9 .styles_input__2TF77 select::-webkit-input-placeholder,
.styles_inputTextArea__2_gZA .styles_input__2TF77 select::-webkit-input-placeholder {
  color: #c3c3c3;
  font-weight: 300;
  line-height: normal !important;
}
.styles_inputCommon__13Dvu .styles_input__2TF77 input::-moz-placeholder, .styles_inputFileUpload__2b2zk .styles_input__2TF77 input::-moz-placeholder, .styles_inputSelect__3bEop .styles_input__2TF77 input::-moz-placeholder, .styles_inputText__kBwo9 .styles_input__2TF77 input::-moz-placeholder,
.styles_inputTextArea__2_gZA .styles_input__2TF77 input::-moz-placeholder,
.styles_inputCommon__13Dvu .styles_input__2TF77 textarea::-moz-placeholder,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 textarea::-moz-placeholder,
.styles_inputSelect__3bEop .styles_input__2TF77 textarea::-moz-placeholder,
.styles_inputText__kBwo9 .styles_input__2TF77 textarea::-moz-placeholder,
.styles_inputTextArea__2_gZA .styles_input__2TF77 textarea::-moz-placeholder,
.styles_inputCommon__13Dvu .styles_input__2TF77 select::-moz-placeholder,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 select::-moz-placeholder,
.styles_inputSelect__3bEop .styles_input__2TF77 select::-moz-placeholder,
.styles_inputText__kBwo9 .styles_input__2TF77 select::-moz-placeholder,
.styles_inputTextArea__2_gZA .styles_input__2TF77 select::-moz-placeholder {
  color: #c3c3c3;
  font-weight: 300;
  line-height: normal !important;
}
.styles_inputCommon__13Dvu .styles_input__2TF77 input:-ms-input-placeholder, .styles_inputFileUpload__2b2zk .styles_input__2TF77 input:-ms-input-placeholder, .styles_inputSelect__3bEop .styles_input__2TF77 input:-ms-input-placeholder, .styles_inputText__kBwo9 .styles_input__2TF77 input:-ms-input-placeholder,
.styles_inputTextArea__2_gZA .styles_input__2TF77 input:-ms-input-placeholder,
.styles_inputCommon__13Dvu .styles_input__2TF77 textarea:-ms-input-placeholder,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 textarea:-ms-input-placeholder,
.styles_inputSelect__3bEop .styles_input__2TF77 textarea:-ms-input-placeholder,
.styles_inputText__kBwo9 .styles_input__2TF77 textarea:-ms-input-placeholder,
.styles_inputTextArea__2_gZA .styles_input__2TF77 textarea:-ms-input-placeholder,
.styles_inputCommon__13Dvu .styles_input__2TF77 select:-ms-input-placeholder,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 select:-ms-input-placeholder,
.styles_inputSelect__3bEop .styles_input__2TF77 select:-ms-input-placeholder,
.styles_inputText__kBwo9 .styles_input__2TF77 select:-ms-input-placeholder,
.styles_inputTextArea__2_gZA .styles_input__2TF77 select:-ms-input-placeholder {
  color: #c3c3c3;
  font-weight: 300;
  line-height: normal !important;
}
.styles_inputCommon__13Dvu .styles_input__2TF77 input::-ms-input-placeholder, .styles_inputFileUpload__2b2zk .styles_input__2TF77 input::-ms-input-placeholder, .styles_inputSelect__3bEop .styles_input__2TF77 input::-ms-input-placeholder, .styles_inputText__kBwo9 .styles_input__2TF77 input::-ms-input-placeholder,
.styles_inputTextArea__2_gZA .styles_input__2TF77 input::-ms-input-placeholder,
.styles_inputCommon__13Dvu .styles_input__2TF77 textarea::-ms-input-placeholder,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 textarea::-ms-input-placeholder,
.styles_inputSelect__3bEop .styles_input__2TF77 textarea::-ms-input-placeholder,
.styles_inputText__kBwo9 .styles_input__2TF77 textarea::-ms-input-placeholder,
.styles_inputTextArea__2_gZA .styles_input__2TF77 textarea::-ms-input-placeholder,
.styles_inputCommon__13Dvu .styles_input__2TF77 select::-ms-input-placeholder,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 select::-ms-input-placeholder,
.styles_inputSelect__3bEop .styles_input__2TF77 select::-ms-input-placeholder,
.styles_inputText__kBwo9 .styles_input__2TF77 select::-ms-input-placeholder,
.styles_inputTextArea__2_gZA .styles_input__2TF77 select::-ms-input-placeholder {
  color: #c3c3c3;
  font-weight: 300;
  line-height: normal !important;
}
.styles_inputCommon__13Dvu .styles_input__2TF77 input::placeholder, .styles_inputFileUpload__2b2zk .styles_input__2TF77 input::placeholder, .styles_inputSelect__3bEop .styles_input__2TF77 input::placeholder, .styles_inputText__kBwo9 .styles_input__2TF77 input::placeholder,
.styles_inputTextArea__2_gZA .styles_input__2TF77 input::placeholder,
.styles_inputCommon__13Dvu .styles_input__2TF77 textarea::placeholder,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 textarea::placeholder,
.styles_inputSelect__3bEop .styles_input__2TF77 textarea::placeholder,
.styles_inputText__kBwo9 .styles_input__2TF77 textarea::placeholder,
.styles_inputTextArea__2_gZA .styles_input__2TF77 textarea::placeholder,
.styles_inputCommon__13Dvu .styles_input__2TF77 select::placeholder,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 select::placeholder,
.styles_inputSelect__3bEop .styles_input__2TF77 select::placeholder,
.styles_inputText__kBwo9 .styles_input__2TF77 select::placeholder,
.styles_inputTextArea__2_gZA .styles_input__2TF77 select::placeholder {
  color: #c3c3c3;
  font-weight: 300;
  line-height: normal !important;
}
.styles_inputCommon__13Dvu .styles_input__2TF77 input:focus, .styles_inputFileUpload__2b2zk .styles_input__2TF77 input:focus, .styles_inputSelect__3bEop .styles_input__2TF77 input:focus, .styles_inputText__kBwo9 .styles_input__2TF77 input:focus,
.styles_inputTextArea__2_gZA .styles_input__2TF77 input:focus,
.styles_inputCommon__13Dvu .styles_input__2TF77 textarea:focus,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 textarea:focus,
.styles_inputSelect__3bEop .styles_input__2TF77 textarea:focus,
.styles_inputText__kBwo9 .styles_input__2TF77 textarea:focus,
.styles_inputTextArea__2_gZA .styles_input__2TF77 textarea:focus,
.styles_inputCommon__13Dvu .styles_input__2TF77 select:focus,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 select:focus,
.styles_inputSelect__3bEop .styles_input__2TF77 select:focus,
.styles_inputText__kBwo9 .styles_input__2TF77 select:focus,
.styles_inputTextArea__2_gZA .styles_input__2TF77 select:focus {
  border: 1px solid #9decd3;
  box-shadow: 0 1px 4px 0 rgba(87, 154, 244, 0.3);
  outline: 0;
}
.styles_inputCommon__13Dvu .styles_input__2TF77 input::-webkit-inner-spin-button, .styles_inputFileUpload__2b2zk .styles_input__2TF77 input::-webkit-inner-spin-button, .styles_inputSelect__3bEop .styles_input__2TF77 input::-webkit-inner-spin-button, .styles_inputText__kBwo9 .styles_input__2TF77 input::-webkit-inner-spin-button,
.styles_inputTextArea__2_gZA .styles_input__2TF77 input::-webkit-inner-spin-button, .styles_inputCommon__13Dvu .styles_input__2TF77 input::-webkit-outer-spin-button, .styles_inputFileUpload__2b2zk .styles_input__2TF77 input::-webkit-outer-spin-button, .styles_inputSelect__3bEop .styles_input__2TF77 input::-webkit-outer-spin-button, .styles_inputText__kBwo9 .styles_input__2TF77 input::-webkit-outer-spin-button,
.styles_inputTextArea__2_gZA .styles_input__2TF77 input::-webkit-outer-spin-button,
.styles_inputCommon__13Dvu .styles_input__2TF77 textarea::-webkit-inner-spin-button,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 textarea::-webkit-inner-spin-button,
.styles_inputSelect__3bEop .styles_input__2TF77 textarea::-webkit-inner-spin-button,
.styles_inputText__kBwo9 .styles_input__2TF77 textarea::-webkit-inner-spin-button,
.styles_inputTextArea__2_gZA .styles_input__2TF77 textarea::-webkit-inner-spin-button,
.styles_inputCommon__13Dvu .styles_input__2TF77 textarea::-webkit-outer-spin-button,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 textarea::-webkit-outer-spin-button,
.styles_inputSelect__3bEop .styles_input__2TF77 textarea::-webkit-outer-spin-button,
.styles_inputText__kBwo9 .styles_input__2TF77 textarea::-webkit-outer-spin-button,
.styles_inputTextArea__2_gZA .styles_input__2TF77 textarea::-webkit-outer-spin-button,
.styles_inputCommon__13Dvu .styles_input__2TF77 select::-webkit-inner-spin-button,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 select::-webkit-inner-spin-button,
.styles_inputSelect__3bEop .styles_input__2TF77 select::-webkit-inner-spin-button,
.styles_inputText__kBwo9 .styles_input__2TF77 select::-webkit-inner-spin-button,
.styles_inputTextArea__2_gZA .styles_input__2TF77 select::-webkit-inner-spin-button,
.styles_inputCommon__13Dvu .styles_input__2TF77 select::-webkit-outer-spin-button,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 select::-webkit-outer-spin-button,
.styles_inputSelect__3bEop .styles_input__2TF77 select::-webkit-outer-spin-button,
.styles_inputText__kBwo9 .styles_input__2TF77 select::-webkit-outer-spin-button,
.styles_inputTextArea__2_gZA .styles_input__2TF77 select::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.styles_inputCommon__13Dvu .styles_input__2TF77 input:-webkit-autofill, .styles_inputFileUpload__2b2zk .styles_input__2TF77 input:-webkit-autofill, .styles_inputSelect__3bEop .styles_input__2TF77 input:-webkit-autofill, .styles_inputText__kBwo9 .styles_input__2TF77 input:-webkit-autofill,
.styles_inputTextArea__2_gZA .styles_input__2TF77 input:-webkit-autofill,
.styles_inputCommon__13Dvu .styles_input__2TF77 textarea:-webkit-autofill,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 textarea:-webkit-autofill,
.styles_inputSelect__3bEop .styles_input__2TF77 textarea:-webkit-autofill,
.styles_inputText__kBwo9 .styles_input__2TF77 textarea:-webkit-autofill,
.styles_inputTextArea__2_gZA .styles_input__2TF77 textarea:-webkit-autofill,
.styles_inputCommon__13Dvu .styles_input__2TF77 select:-webkit-autofill,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 select:-webkit-autofill,
.styles_inputSelect__3bEop .styles_input__2TF77 select:-webkit-autofill,
.styles_inputText__kBwo9 .styles_input__2TF77 select:-webkit-autofill,
.styles_inputTextArea__2_gZA .styles_input__2TF77 select:-webkit-autofill {
  background-color: #fff !important;
}
.styles_inputCommon__13Dvu .styles_input__2TF77 input:disabled, .styles_inputFileUpload__2b2zk .styles_input__2TF77 input:disabled, .styles_inputSelect__3bEop .styles_input__2TF77 input:disabled, .styles_inputText__kBwo9 .styles_input__2TF77 input:disabled,
.styles_inputTextArea__2_gZA .styles_input__2TF77 input:disabled,
.styles_inputCommon__13Dvu .styles_input__2TF77 textarea:disabled,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 textarea:disabled,
.styles_inputSelect__3bEop .styles_input__2TF77 textarea:disabled,
.styles_inputText__kBwo9 .styles_input__2TF77 textarea:disabled,
.styles_inputTextArea__2_gZA .styles_input__2TF77 textarea:disabled,
.styles_inputCommon__13Dvu .styles_input__2TF77 select:disabled,
.styles_inputFileUpload__2b2zk .styles_input__2TF77 select:disabled,
.styles_inputSelect__3bEop .styles_input__2TF77 select:disabled,
.styles_inputText__kBwo9 .styles_input__2TF77 select:disabled,
.styles_inputTextArea__2_gZA .styles_input__2TF77 select:disabled {
  cursor: not-allowed !important;
}
.styles_inputCommon__13Dvu .styles_input__2TF77 textarea, .styles_inputFileUpload__2b2zk .styles_input__2TF77 textarea, .styles_inputSelect__3bEop .styles_input__2TF77 textarea, .styles_inputText__kBwo9 .styles_input__2TF77 textarea,
.styles_inputTextArea__2_gZA .styles_input__2TF77 textarea {
  resize: vertical;
  min-height: 4rem;
}
.styles_inputCommon__13Dvu.styles_success__6OkaY .styles_input__2TF77:before, .styles_success__6OkaY.styles_inputFileUpload__2b2zk .styles_input__2TF77:before, .styles_success__6OkaY.styles_inputSelect__3bEop .styles_input__2TF77:before, .styles_success__6OkaY.styles_inputText__kBwo9 .styles_input__2TF77:before,
.styles_success__6OkaY.styles_inputTextArea__2_gZA .styles_input__2TF77:before {
  content: "";
  position: absolute;
  right: 1px;
  top: 1px;
  height: calc(100% - 2px);
  background: #fff;
  width: 2.5rem;
  border-radius: 0 3px 3px 0;
}
.styles_inputCommon__13Dvu.styles_success__6OkaY .styles_input__2TF77:after, .styles_success__6OkaY.styles_inputFileUpload__2b2zk .styles_input__2TF77:after, .styles_success__6OkaY.styles_inputSelect__3bEop .styles_input__2TF77:after, .styles_success__6OkaY.styles_inputText__kBwo9 .styles_input__2TF77:after,
.styles_success__6OkaY.styles_inputTextArea__2_gZA .styles_input__2TF77:after {
  content: "";
  position: absolute;
  width: 0.4em;
  height: 1em;
  margin-top: -0.75rem;
  right: 0.75em;
  top: 50%;
  border: solid #1E9F76;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  z-index: 3;
}
.styles_inputCommon__13Dvu.styles_error__3fiTy input, .styles_error__3fiTy.styles_inputFileUpload__2b2zk input, .styles_error__3fiTy.styles_inputSelect__3bEop input, .styles_error__3fiTy.styles_inputText__kBwo9 input,
.styles_error__3fiTy.styles_inputTextArea__2_gZA input,
.styles_inputCommon__13Dvu.styles_error__3fiTy textarea,
.styles_error__3fiTy.styles_inputFileUpload__2b2zk textarea,
.styles_error__3fiTy.styles_inputSelect__3bEop textarea,
.styles_error__3fiTy.styles_inputText__kBwo9 textarea,
.styles_error__3fiTy.styles_inputTextArea__2_gZA textarea,
.styles_inputCommon__13Dvu.styles_error__3fiTy select,
.styles_error__3fiTy.styles_inputFileUpload__2b2zk select,
.styles_error__3fiTy.styles_inputSelect__3bEop select,
.styles_error__3fiTy.styles_inputText__kBwo9 select,
.styles_error__3fiTy.styles_inputTextArea__2_gZA select {
  border-color: #E2164A;
}
.styles_inputCommon__13Dvu.styles_error__3fiTy input:focus, .styles_error__3fiTy.styles_inputFileUpload__2b2zk input:focus, .styles_error__3fiTy.styles_inputSelect__3bEop input:focus, .styles_error__3fiTy.styles_inputText__kBwo9 input:focus,
.styles_error__3fiTy.styles_inputTextArea__2_gZA input:focus,
.styles_inputCommon__13Dvu.styles_error__3fiTy textarea:focus,
.styles_error__3fiTy.styles_inputFileUpload__2b2zk textarea:focus,
.styles_error__3fiTy.styles_inputSelect__3bEop textarea:focus,
.styles_error__3fiTy.styles_inputText__kBwo9 textarea:focus,
.styles_error__3fiTy.styles_inputTextArea__2_gZA textarea:focus,
.styles_inputCommon__13Dvu.styles_error__3fiTy select:focus,
.styles_error__3fiTy.styles_inputFileUpload__2b2zk select:focus,
.styles_error__3fiTy.styles_inputSelect__3bEop select:focus,
.styles_error__3fiTy.styles_inputText__kBwo9 select:focus,
.styles_error__3fiTy.styles_inputTextArea__2_gZA select:focus {
  border-color: #E2164A;
}
.styles_inputCommon__13Dvu.styles_error__3fiTy .styles_errorMessage__3gZ9N, .styles_error__3fiTy.styles_inputFileUpload__2b2zk .styles_errorMessage__3gZ9N, .styles_error__3fiTy.styles_inputSelect__3bEop .styles_errorMessage__3gZ9N, .styles_error__3fiTy.styles_inputText__kBwo9 .styles_errorMessage__3gZ9N,
.styles_error__3fiTy.styles_inputTextArea__2_gZA .styles_errorMessage__3gZ9N {
  color: #E2164A;
  font-size: 0.7rem;
  border-radius: 0 0 3px 3px;
  padding: calc(0.35rem/2) 0.5rem;
  line-height: 1.4em;
}

.styles_genericErrorMessage__fmajR {
  margin: 0.35rem 0;
  color: #E2164A;
  font-size: 0.7rem;
  border-radius: 3px;
  padding: calc(0.35rem/2) 0;
  line-height: 1.2em;
}

.styles_inputDollar__39rmv .styles_input__2TF77 {
  position: relative;
}
.styles_inputDollar__39rmv .styles_input__2TF77 input {
  padding-left: 1.25rem;
}
.styles_inputDollar__39rmv .styles_input__2TF77 .styles_dollar__u8S0u {
  position: absolute;
  color: #c3c3c3;
  line-height: 1;
  height: 1rem;
  margin-top: calc(-1rem/2.25);
  top: 50%;
  left: 0.5rem;
}

.styles_inputSelect__3bEop .styles_input__2TF77 {
  position: relative;
}
.styles_inputSelect__3bEop .styles_input__2TF77:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-color: #4D4D4D transparent transparent transparent;
  margin-top: -3px;
  position: absolute;
  top: 50%;
  right: 0.7rem;
  z-index: 2;
}
.styles_inputSelect__3bEop .styles_input__2TF77:before {
  content: "";
  width: 0.85rem;
  height: 0.85rem;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  margin: calc(-0.85rem/2) 0 0 0;
  background: white;
  border-radius: 100%;
  z-index: 1;
}
.styles_inputSelect__3bEop .styles_input__2TF77 select {
  padding-right: 2rem;
}

.styles_inputCheckCommon__33DD0, .styles_inputHuman__3GJbN, .styles_inputRadio__3SeUq, .styles_inputCheckbox__1vsXL, .styles_inputToggle__3w-_H {
  display: flex;
  align-items: center;
}
.styles_inputCheckCommon__33DD0 .styles_check__1CNzC, .styles_inputHuman__3GJbN .styles_check__1CNzC, .styles_inputRadio__3SeUq .styles_check__1CNzC, .styles_inputCheckbox__1vsXL .styles_check__1CNzC, .styles_inputToggle__3w-_H .styles_check__1CNzC,
.styles_inputCheckCommon__33DD0 .styles_radio__2LMCU,
.styles_inputHuman__3GJbN .styles_radio__2LMCU,
.styles_inputRadio__3SeUq .styles_radio__2LMCU,
.styles_inputCheckbox__1vsXL .styles_radio__2LMCU,
.styles_inputToggle__3w-_H .styles_radio__2LMCU {
  position: relative;
}
.styles_inputCheckCommon__33DD0 .styles_check__1CNzC input, .styles_inputHuman__3GJbN .styles_check__1CNzC input, .styles_inputRadio__3SeUq .styles_check__1CNzC input, .styles_inputCheckbox__1vsXL .styles_check__1CNzC input, .styles_inputToggle__3w-_H .styles_check__1CNzC input,
.styles_inputCheckCommon__33DD0 .styles_radio__2LMCU input,
.styles_inputHuman__3GJbN .styles_radio__2LMCU input,
.styles_inputRadio__3SeUq .styles_radio__2LMCU input,
.styles_inputCheckbox__1vsXL .styles_radio__2LMCU input,
.styles_inputToggle__3w-_H .styles_radio__2LMCU input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}
.styles_inputCheckCommon__33DD0 .styles_check__1CNzC input:checked ~ .styles_item__2xZ1-:after, .styles_inputHuman__3GJbN .styles_check__1CNzC input:checked ~ .styles_item__2xZ1-:after, .styles_inputRadio__3SeUq .styles_check__1CNzC input:checked ~ .styles_item__2xZ1-:after, .styles_inputCheckbox__1vsXL .styles_check__1CNzC input:checked ~ .styles_item__2xZ1-:after, .styles_inputToggle__3w-_H .styles_check__1CNzC input:checked ~ .styles_item__2xZ1-:after,
.styles_inputCheckCommon__33DD0 .styles_radio__2LMCU input:checked ~ .styles_item__2xZ1-:after,
.styles_inputHuman__3GJbN .styles_radio__2LMCU input:checked ~ .styles_item__2xZ1-:after,
.styles_inputRadio__3SeUq .styles_radio__2LMCU input:checked ~ .styles_item__2xZ1-:after,
.styles_inputCheckbox__1vsXL .styles_radio__2LMCU input:checked ~ .styles_item__2xZ1-:after,
.styles_inputToggle__3w-_H .styles_radio__2LMCU input:checked ~ .styles_item__2xZ1-:after {
  display: block;
}
.styles_inputCheckCommon__33DD0 .styles_check__1CNzC .styles_item__2xZ1-, .styles_inputHuman__3GJbN .styles_check__1CNzC .styles_item__2xZ1-, .styles_inputRadio__3SeUq .styles_check__1CNzC .styles_item__2xZ1-, .styles_inputCheckbox__1vsXL .styles_check__1CNzC .styles_item__2xZ1-, .styles_inputToggle__3w-_H .styles_check__1CNzC .styles_item__2xZ1-,
.styles_inputCheckCommon__33DD0 .styles_radio__2LMCU .styles_item__2xZ1-,
.styles_inputHuman__3GJbN .styles_radio__2LMCU .styles_item__2xZ1-,
.styles_inputRadio__3SeUq .styles_radio__2LMCU .styles_item__2xZ1-,
.styles_inputCheckbox__1vsXL .styles_radio__2LMCU .styles_item__2xZ1-,
.styles_inputToggle__3w-_H .styles_radio__2LMCU .styles_item__2xZ1- {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_inputCheckCommon__33DD0 .styles_check__1CNzC .styles_item__2xZ1-:after, .styles_inputHuman__3GJbN .styles_check__1CNzC .styles_item__2xZ1-:after, .styles_inputRadio__3SeUq .styles_check__1CNzC .styles_item__2xZ1-:after, .styles_inputCheckbox__1vsXL .styles_check__1CNzC .styles_item__2xZ1-:after, .styles_inputToggle__3w-_H .styles_check__1CNzC .styles_item__2xZ1-:after,
.styles_inputCheckCommon__33DD0 .styles_radio__2LMCU .styles_item__2xZ1-:after,
.styles_inputHuman__3GJbN .styles_radio__2LMCU .styles_item__2xZ1-:after,
.styles_inputRadio__3SeUq .styles_radio__2LMCU .styles_item__2xZ1-:after,
.styles_inputCheckbox__1vsXL .styles_radio__2LMCU .styles_item__2xZ1-:after,
.styles_inputToggle__3w-_H .styles_radio__2LMCU .styles_item__2xZ1-:after {
  cursor: pointer;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.styles_inputToggle__3w-_H {
  margin: 0.35rem 0;
}
.styles_inputToggle__3w-_H .styles_label__2pzO7 {
  position: relative;
  top: 2px;
}
.styles_inputToggle__3w-_H .styles_check__1CNzC input:checked ~ .styles_item__2xZ1- {
  background: #f9f0fc;
}
.styles_inputToggle__3w-_H .styles_check__1CNzC input:checked ~ .styles_item__2xZ1-:after {
  background: #B538DE;
  left: 1.3rem;
}
.styles_inputToggle__3w-_H .styles_check__1CNzC .styles_item__2xZ1- {
  position: relative;
  width: 2.6em;
  height: 1.6em;
  border-radius: 1.6rem;
  margin-right: 0.5rem;
  background: #eeeeee;
}
.styles_inputToggle__3w-_H .styles_check__1CNzC .styles_item__2xZ1-:after {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  content: "";
  position: absolute;
  width: 1.32rem;
  height: 1.32rem;
  top: 0.125rem;
  left: 0.125rem;
  border-radius: 100%;
  background: #c8c8c8;
}

.styles_inputCheckbox__1vsXL {
  margin: 0.35rem 0;
}
.styles_inputCheckbox__1vsXL .styles_label__2pzO7 {
  position: relative;
  top: 2px;
}
.styles_inputCheckbox__1vsXL .styles_check__1CNzC input:checked ~ .styles_item__2xZ1- {
  background: #eed1f7;
  border: 1px solid #B538DE;
}
.styles_inputCheckbox__1vsXL .styles_check__1CNzC input:checked ~ .styles_item__2xZ1-:after {
  opacity: 1;
}
.styles_inputCheckbox__1vsXL .styles_check__1CNzC .styles_item__2xZ1- {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  background: #eeeeee;
  border: 1px solid #eeeeee;
  box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.13);
  border-radius: 2px;
  margin-right: 0.5rem;
}
.styles_inputCheckbox__1vsXL .styles_check__1CNzC .styles_item__2xZ1-:after {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  content: "";
  position: relative;
  display: block;
  width: 0.4em;
  height: 0.8em;
  border: solid #B538DE;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  left: 0.5rem;
  top: 0.15rem;
  opacity: 0;
}

.styles_stretch__2T5ka {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
.styles_stretch__2T5ka > * {
  margin-left: 0.35rem;
}

.styles_inputRadio__3SeUq .styles_check__1CNzC input:checked ~ .styles_item__2xZ1- {
  background: #f9f0fc;
  border: 1px solid #B538DE;
  color: #B538DE;
}
.styles_inputRadio__3SeUq .styles_check__1CNzC input:disabled ~ .styles_item__2xZ1- {
  background: #eeeeee;
  border: 1px solid #eeeeee;
  color: #dbdbdb;
  cursor: not-allowed;
}
.styles_inputRadio__3SeUq .styles_check__1CNzC .styles_item__2xZ1- {
  font-weight: 500;
  position: relative;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  padding: 0 1rem;
  color: #cecece;
}

.styles_inputFileUpload__2b2zk .styles_fileUpload__19VZ3 {
  display: flex;
  align-items: center;
}
.styles_inputFileUpload__2b2zk .styles_fileUpload__19VZ3 .styles_imageContainer__2XuBQ {
  position: relative;
}
.styles_inputFileUpload__2b2zk .styles_fileUpload__19VZ3 .styles_image__105sy {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  width: 4rem;
  height: 4rem;
  background: #eeeeee;
  border: 1px solid #eeeeee;
}
.styles_inputFileUpload__2b2zk .styles_fileUpload__19VZ3 .styles_image__105sy svg path {
  fill: #c3c3c3;
}
.styles_inputFileUpload__2b2zk .styles_fileUpload__19VZ3 .styles_image__105sy:hover {
  background: #f3f3f3;
}
.styles_inputFileUpload__2b2zk .styles_fileUpload__19VZ3 .styles_image__105sy.styles_uploaded__3yQLY {
  background-size: cover;
  background-position: top;
}
.styles_inputFileUpload__2b2zk .styles_fileUpload__19VZ3 .styles_image__105sy.styles_uploaded__3yQLY:hover {
  border: 1px solid #26ca96;
}
.styles_inputFileUpload__2b2zk .styles_fileUpload__19VZ3 .styles_count__SFvMM {
  position: absolute;
  top: calc(-0.35rem/2);
  right: 0;
  display: inline-flex;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background: #B538DE;
  color: #fff;
}
.styles_inputFileUpload__2b2zk .styles_fileUpload__19VZ3 .styles_count__SFvMM .styles_label__2pzO7 {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
  line-height: 1;
  color: inherit;
  position: relative;
}

.styles_inputHuman__3GJbN .styles_check__1CNzC {
  width: 100%;
  margin: 0 0 0.35rem 0;
}
.styles_inputHuman__3GJbN .styles_check__1CNzC input:hover ~ .styles_item__2xZ1- {
  border: 1px solid #ac24d9;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_inputHuman__3GJbN .styles_check__1CNzC input:checked ~ .styles_item__2xZ1- {
  background: #f9f0fc;
  border: 1px solid #B538DE;
  color: #B538DE;
}
.styles_inputHuman__3GJbN .styles_check__1CNzC input:disabled ~ .styles_item__2xZ1- {
  background: #eeeeee;
  border: 1px solid #eeeeee;
  color: #dbdbdb;
  cursor: not-allowed !important;
}
.styles_inputHuman__3GJbN .styles_check__1CNzC input:disabled ~ .styles_item__2xZ1- .styles_photo__3UnPo {
  opacity: 0.5;
}
.styles_inputHuman__3GJbN .styles_check__1CNzC .styles_item__2xZ1- {
  position: relative;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  color: #9b9b9b;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1;
  display: flex;
  align-items: center;
  padding-right: 0.35rem;
}
.styles_inputHuman__3GJbN .styles_check__1CNzC .styles_item__2xZ1- > .styles_label__2pzO7 {
  padding: 0 0.5rem;
  flex: 1 1;
  position: relative;
  top: 2px;
}

.styles_inputTimeZonePicker__8hjxe {
  z-index: 2;
  width: 100%;
  background-color: #fff;
}
.styles_inputTimeZonePicker__8hjxe ul li button {
  background-color: #fff;
}

.styles_photo__3UnPo {
  width: 2.5em;
  height: 2.5em;
  margin: 0.35rem 0.5rem 0.35rem 0.35rem;
  padding: 0;
  background-size: cover !important;
  border-radius: 100%;
}
.styles_photo__3UnPo.styles_missing__2QP72 {
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_flexCell__Slm3H {
  display: flex;
  flex-flow: row nowrap;
}

.styles_mobile__2Iexe {
  border: 0 !important;
  padding: 0;
}
.styles_mobile__2Iexe > * {
  padding: 0 0.35rem 0 0 !important;
  margin: 0;
}

.styles_margin__21ebq {
  margin: 0.35rem;
  display: flex;
  align-self: stretch;
  align-items: center;
}

.styles_flexBottom__3zysI {
  height: 100%;
  display: flex;
  align-items: center;
}

.styles_padLabel__31n1p {
  padding: 0.35rem 0;
}

.styles_mobileEmployee__15g9O {
  background: #f8f8f8;
  margin: 1rem 0;
}
.styles_textCommon__39Xer, .styles_textBold__54t4g, .styles_textSecondary__yV4VO, .styles_textDefault__1Q51x {
  line-height: 1.4em;
}

.styles_textDefault__1Q51x {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__yV4VO {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__54t4g {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__1SFlS {
  font-weight: 700;
}

.styles_alignRight__3YLWy {
  text-align: right;
}

.styles_alignCenter__1HsFp {
  text-align: center;
}

.styles_tagLabel__1rBgc {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__q79Rv {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__34noc {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__2mrmY {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__14S4_,
.styles_textHeading__2u87O,
.styles_textSubheading__KC5RV {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__ln1Cl {
  color: #9b9b9b;
}

.styles_container__293Bc {
  position: relative;
}

.styles_padHeading__vuFXt {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__3SWna {
  padding-top: 1rem;
}

.styles_padY__2uP0n, .styles_subtitle__3fICJ {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__2Pl7t {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__1jCoT {
  margin-top: 1rem;
}

.styles_marginY__9VnGr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__2FGNK {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__3Tctf {
  margin: 0;
  padding: 0;
}

.styles_wrapper__Jn3ZB {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__Jn3ZB > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__3shIl {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__3shIl > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__2HnR5 {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__2HnR5 > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__2bKMf {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__2bKMf > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__1sd8D, .styles_subtitle__3fICJ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__1sd8D > *, .styles_subtitle__3fICJ > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__3XQCk {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__3XQCk > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__14S4_,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__2u87O,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__KC5RV,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__2Rl7b {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__DQ1hj {
    padding: 1rem;
  }
  .styles_hideMedium__2Mx68 {
    display: none !important;
  }
  .styles_hideLarge__3RL4D {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__DQ1hj {
    padding: 1rem;
  }
  .styles_subtitle__3fICJ {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__1KKpQ {
    display: none !important;
  }
  .styles_rowResize__3BbWo > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__3BbWo > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__DQ1hj {
    padding: 0.5rem;
  }
  .styles_padX__2Pl7t {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__1Q51x {
    font-size: 1.2rem;
  }
  .styles_textSecondary__yV4VO {
    font-size: 1.02rem;
  }
  .styles_textBold__54t4g {
    font-size: 1.2rem;
  }
  .styles_textHint__q79Rv {
    font-size: 0.84rem;
  }
  .styles_textLabel__34noc {
    font-size: 1.2rem;
  }
  .styles_navLabel__2mrmY {
    font-size: 0.84rem;
  }
  .styles_tagLabel__1rBgc {
    font-size: 0.85rem;
  }
}
input:disabled {
  cursor: not-allowed !important;
}

.styles_genericErrorMessage__VyOfe {
  margin: 0.35rem 0;
  color: #E2164A;
  font-size: 0.7rem;
  border-radius: 3px;
  padding: calc(0.35rem/2) 0;
  line-height: 1.2em;
}

.styles_inputCheckCommon__2EOT7, .styles_inputCheckbox__2K0KB, .styles_inputToggle__2rz6d {
  display: flex;
  align-items: center;
}
.styles_inputCheckCommon__2EOT7 .styles_check__i_CSb, .styles_inputCheckbox__2K0KB .styles_check__i_CSb, .styles_inputToggle__2rz6d .styles_check__i_CSb,
.styles_inputCheckCommon__2EOT7 .styles_radio__2z93p,
.styles_inputCheckbox__2K0KB .styles_radio__2z93p,
.styles_inputToggle__2rz6d .styles_radio__2z93p {
  position: relative;
}
.styles_inputCheckCommon__2EOT7 .styles_check__i_CSb input, .styles_inputCheckbox__2K0KB .styles_check__i_CSb input, .styles_inputToggle__2rz6d .styles_check__i_CSb input,
.styles_inputCheckCommon__2EOT7 .styles_radio__2z93p input,
.styles_inputCheckbox__2K0KB .styles_radio__2z93p input,
.styles_inputToggle__2rz6d .styles_radio__2z93p input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}
.styles_inputCheckCommon__2EOT7 .styles_check__i_CSb input:checked ~ .styles_item__2vZuq:after, .styles_inputCheckbox__2K0KB .styles_check__i_CSb input:checked ~ .styles_item__2vZuq:after, .styles_inputToggle__2rz6d .styles_check__i_CSb input:checked ~ .styles_item__2vZuq:after,
.styles_inputCheckCommon__2EOT7 .styles_radio__2z93p input:checked ~ .styles_item__2vZuq:after,
.styles_inputCheckbox__2K0KB .styles_radio__2z93p input:checked ~ .styles_item__2vZuq:after,
.styles_inputToggle__2rz6d .styles_radio__2z93p input:checked ~ .styles_item__2vZuq:after {
  display: block;
}
.styles_inputCheckCommon__2EOT7 .styles_check__i_CSb .styles_item__2vZuq, .styles_inputCheckbox__2K0KB .styles_check__i_CSb .styles_item__2vZuq, .styles_inputToggle__2rz6d .styles_check__i_CSb .styles_item__2vZuq,
.styles_inputCheckCommon__2EOT7 .styles_radio__2z93p .styles_item__2vZuq,
.styles_inputCheckbox__2K0KB .styles_radio__2z93p .styles_item__2vZuq,
.styles_inputToggle__2rz6d .styles_radio__2z93p .styles_item__2vZuq {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_inputCheckCommon__2EOT7 .styles_check__i_CSb .styles_item__2vZuq:after, .styles_inputCheckbox__2K0KB .styles_check__i_CSb .styles_item__2vZuq:after, .styles_inputToggle__2rz6d .styles_check__i_CSb .styles_item__2vZuq:after,
.styles_inputCheckCommon__2EOT7 .styles_radio__2z93p .styles_item__2vZuq:after,
.styles_inputCheckbox__2K0KB .styles_radio__2z93p .styles_item__2vZuq:after,
.styles_inputToggle__2rz6d .styles_radio__2z93p .styles_item__2vZuq:after {
  cursor: pointer;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.styles_inputToggle__2rz6d {
  margin: 0.35rem 0;
}
.styles_inputToggle__2rz6d .styles_label__3dRzx {
  position: relative;
  top: 2px;
}
.styles_inputToggle__2rz6d .styles_check__i_CSb input:checked ~ .styles_item__2vZuq {
  background: #f9f0fc;
}
.styles_inputToggle__2rz6d .styles_check__i_CSb input:checked ~ .styles_item__2vZuq:after {
  background: #B538DE;
  left: 1.1rem;
}
.styles_inputToggle__2rz6d .styles_check__i_CSb .styles_item__2vZuq {
  position: relative;
  width: 2.6em;
  height: 1.6em;
  border-radius: 1.6rem;
  margin-right: 0.5rem;
  background: #eeeeee;
}
.styles_inputToggle__2rz6d .styles_check__i_CSb .styles_item__2vZuq:after {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  content: "";
  position: absolute;
  width: 1.32rem;
  height: 1.32rem;
  top: 0.125rem;
  left: 0.125rem;
  border-radius: 100%;
  background: #c8c8c8;
}

.styles_inputCheckbox__2K0KB {
  margin: 0.35rem 0;
}
.styles_inputCheckbox__2K0KB .styles_label__3dRzx {
  position: relative;
  top: 2px;
}
.styles_inputCheckbox__2K0KB .styles_check__i_CSb input:checked ~ .styles_item__2vZuq {
  background: #eed1f7;
  border: 1px solid #B538DE;
}
.styles_inputCheckbox__2K0KB .styles_check__i_CSb input:checked ~ .styles_item__2vZuq:after {
  opacity: 1;
}
.styles_inputCheckbox__2K0KB .styles_check__i_CSb .styles_item__2vZuq {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  background: #eeeeee;
  border: 1px solid #eeeeee;
  box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.13);
  border-radius: 2px;
  margin-right: 0.5rem;
}
.styles_inputCheckbox__2K0KB .styles_check__i_CSb .styles_item__2vZuq:after {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  content: "";
  position: relative;
  display: block;
  width: 0.4em;
  height: 0.8em;
  border: solid #B538DE;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  left: 0.5rem;
  top: 0.15rem;
  opacity: 0;
}
.styles_textCommon__2t9EZ, .styles_textBold__38VmL, .styles_textSecondary__1IXsO, .styles_textDefault__1h9aK {
  line-height: 1.4em;
}

.styles_textDefault__1h9aK {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__1IXsO {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__38VmL {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__2Pi2m {
  font-weight: 700;
}

.styles_alignRight__1ExEq {
  text-align: right;
}

.styles_alignCenter__ts5md {
  text-align: center;
}

.styles_tagLabel__ZFdHj {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__1Iuhm {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__7JQiK, .styles_buttonDirectionsFill__1DmaF .styles_label__3deEh, .styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3 .styles_label__3deEh, .styles_buttonTinyToggle__3nEEY:hover .styles_label__3deEh, .styles_buttonTinyToggle__3nEEY .styles_label__3deEh, .styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3 .styles_label__3deEh, .styles_buttonTinyLink__2E40n:hover .styles_label__3deEh, .styles_buttonTinyLink__2E40n .styles_label__3deEh, .styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3 .styles_label__3deEh, .styles_buttonTinyBack__ElwhZ:hover .styles_label__3deEh, .styles_buttonTinyBack__ElwhZ .styles_label__3deEh, .styles_buttonTinySecondaryFill__2D0mW .styles_label__3deEh, .styles_buttonTinySecondary__3v5Hg .styles_label__3deEh, .styles_buttonTinyStopLog__zWoBo .styles_label__3deEh, .styles_buttonTinyBlueAlert__1tr9D .styles_label__3deEh, .styles_buttonTinyAlertFill__31luJ .styles_label__3deEh, .styles_buttonTinyAlert__i0A-s .styles_label__3deEh, .styles_buttonTinyPrimary__n8NX8 .styles_label__3deEh {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__1oDSd {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__2wsQ2,
.styles_textHeading__2Isev,
.styles_textSubheading__11gnV {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__ow0z3 {
  color: #9b9b9b;
}

.styles_container__1C31R {
  position: relative;
}

.styles_padHeading__2WbaY {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__YImLp {
  padding-top: 1rem;
}

.styles_padY__3Rgmf, .styles_subtitle__1Hd_2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__ICjti {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__hUvkA {
  margin-top: 1rem;
}

.styles_marginY__2Fxzk {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__2C6Rr {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__3PqEa {
  margin: 0;
  padding: 0;
}

.styles_wrapper__22iDk {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__22iDk > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__I3iwt {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__I3iwt > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__20Pxx {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__20Pxx > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__1kRAN {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__1kRAN > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__11Tkq, .styles_subtitle__1Hd_2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__11Tkq > *, .styles_subtitle__1Hd_2 > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__cjLU9 {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__cjLU9 > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__2wsQ2,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__2Isev,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__11gnV,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__37q5l {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__39POC {
    padding: 1rem;
  }
  .styles_hideMedium__3t5ZY {
    display: none !important;
  }
  .styles_hideLarge__3eKgz {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__39POC {
    padding: 1rem;
  }
  .styles_subtitle__1Hd_2 {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__3Caq9 {
    display: none !important;
  }
  .styles_rowResize__1YwFu > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__1YwFu > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__39POC {
    padding: 0.5rem;
  }
  .styles_padX__ICjti {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__1h9aK {
    font-size: 1.2rem;
  }
  .styles_textSecondary__1IXsO {
    font-size: 1.02rem;
  }
  .styles_textBold__38VmL {
    font-size: 1.2rem;
  }
  .styles_textHint__1Iuhm {
    font-size: 0.84rem;
  }
  .styles_textLabel__7JQiK, .styles_buttonDirectionsFill__1DmaF .styles_label__3deEh, .styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3 .styles_label__3deEh, .styles_buttonTinyToggle__3nEEY:hover .styles_label__3deEh, .styles_buttonTinyToggle__3nEEY .styles_label__3deEh, .styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3 .styles_label__3deEh, .styles_buttonTinyLink__2E40n:hover .styles_label__3deEh, .styles_buttonTinyLink__2E40n .styles_label__3deEh, .styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3 .styles_label__3deEh, .styles_buttonTinyBack__ElwhZ:hover .styles_label__3deEh, .styles_buttonTinyBack__ElwhZ .styles_label__3deEh, .styles_buttonTinySecondaryFill__2D0mW .styles_label__3deEh, .styles_buttonTinySecondary__3v5Hg .styles_label__3deEh, .styles_buttonTinyStopLog__zWoBo .styles_label__3deEh, .styles_buttonTinyBlueAlert__1tr9D .styles_label__3deEh, .styles_buttonTinyAlertFill__31luJ .styles_label__3deEh, .styles_buttonTinyAlert__i0A-s .styles_label__3deEh, .styles_buttonTinyPrimary__n8NX8 .styles_label__3deEh {
    font-size: 1.2rem;
  }
  .styles_navLabel__1oDSd {
    font-size: 0.84rem;
  }
  .styles_tagLabel__ZFdHj {
    font-size: 0.85rem;
  }
}
.styles_textTitle__2wsQ2 {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__30rIE {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__Ap_0U {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__ZKGkx {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__3ms_g {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__318Ff {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__33ove {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__38VmL {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__2g0Kv {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__36uJH {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__2tsnD {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__1YUqG {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__1PcDO {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__lw56f {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__A7QRt {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__9uR8B {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__2PrKn {
  border: 1px solid #E2164A;
}

button {
  display: block;
  font-family: inherit;
  font-size: inherit;
  background: none;
  border: inherit;
}
button:focus {
  outline: none;
}

.styles_fullWidth___oMks {
  width: 100%;
}

.styles_buttonPad__ibcHu {
  margin: 0.5rem 0;
}

@-webkit-keyframes styles_buttonPulse__3ijwl {
  50% {
    box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
  }
  100% {
    box-shadow: 0 0 0 0px rgba(232, 76, 61, 0);
  }
}
.styles_buttonDisabled__SfEj- {
  background: #d8d8d8;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  padding: 0 1rem;
  border: 0 solid #d8d8d8;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
@media (max-width: 640px) {
  .styles_buttonDisabled__SfEj- {
    padding: 0.5rem 1rem;
  }
}
.styles_buttonDisabled__SfEj-:hover {
  border: 0 solid #bfbfbf;
  background: #eaeaea;
}
.styles_buttonDisabled__SfEj-.styles_shadow__3qSdx {
  box-shadow: 0 0 13px 0 rgba(216, 216, 216, 0.4);
}
.styles_buttonDisabled__SfEj-.styles_pulse__3rktF {
  position: relative;
}
.styles_buttonDisabled__SfEj-.styles_pulse__3rktF:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 0 rgba(216, 216, 216, 0.4);
  -webkit-animation: styles_buttonPulse__3ijwl 2.25s infinite;
          animation: styles_buttonPulse__3ijwl 2.25s infinite;
}
.styles_buttonDisabled__SfEj-.styles_pulse__3rktF:hover:after {
  -webkit-animation: none;
  animation: none;
}
.styles_buttonDisabled__SfEj- .styles_label__3deEh {
  font-size: 1.3rem;
  font-family: "Assistant", sans-serif;
  color: #c1c1c1;
  padding: 0.35rem 0 0.5rem 0;
  margin: 0 0.5rem;
  position: relative;
  top: 0.1em;
  word-break: break-word;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.styles_buttonDisabled__SfEj- .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
}
.styles_buttonDisabled__SfEj- .styles_icon__3ZTUn svg path,
.styles_buttonDisabled__SfEj- .styles_icon__3ZTUn svg g {
  fill: #c1c1c1;
}
.styles_buttonDisabled__SfEj-.styles_disabled__3bPAN, .styles_buttonDisabled__SfEj-:disabled {
  background: #EEEEEE !important;
  border: 2px solid #EEEEEE !important;
  cursor: not-allowed !important;
}
.styles_buttonDisabled__SfEj-.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonDisabled__SfEj-:disabled .styles_label__3deEh {
  color: #9B9B9B !important;
}

.styles_buttonPrimary__1EQic {
  background: #1E9F76;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  padding: 0 1rem;
  border: 0 solid #1E9F76;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
@media (max-width: 640px) {
  .styles_buttonPrimary__1EQic {
    padding: 0.5rem 1rem;
  }
}
.styles_buttonPrimary__1EQic:hover {
  border: 0 solid #167456;
  background: #24bd8c;
}
.styles_buttonPrimary__1EQic.styles_shadow__3qSdx {
  box-shadow: 0 0 13px 0 rgba(30, 159, 118, 0.4);
}
.styles_buttonPrimary__1EQic.styles_pulse__3rktF {
  position: relative;
}
.styles_buttonPrimary__1EQic.styles_pulse__3rktF:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 0 rgba(30, 159, 118, 0.4);
  -webkit-animation: styles_buttonPulse__3ijwl 2.25s infinite;
          animation: styles_buttonPulse__3ijwl 2.25s infinite;
}
.styles_buttonPrimary__1EQic.styles_pulse__3rktF:hover:after {
  -webkit-animation: none;
  animation: none;
}
.styles_buttonPrimary__1EQic .styles_label__3deEh {
  font-size: 1.3rem;
  font-family: "Assistant", sans-serif;
  color: #fff;
  padding: 0.35rem 0 0.5rem 0;
  margin: 0 0.5rem;
  position: relative;
  top: 0.1em;
  word-break: break-word;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.styles_buttonPrimary__1EQic .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
}
.styles_buttonPrimary__1EQic .styles_icon__3ZTUn svg path,
.styles_buttonPrimary__1EQic .styles_icon__3ZTUn svg g {
  fill: #fff;
}
.styles_buttonPrimary__1EQic.styles_disabled__3bPAN, .styles_buttonPrimary__1EQic:disabled {
  background: #EEEEEE !important;
  border: 2px solid #EEEEEE !important;
  cursor: not-allowed !important;
}
.styles_buttonPrimary__1EQic.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonPrimary__1EQic:disabled .styles_label__3deEh {
  color: #9B9B9B !important;
}

.styles_buttonAlert__x6aq0 {
  background: #fff;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  padding: 0 1rem;
  border: 2px solid #EEEEEE;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
@media (max-width: 640px) {
  .styles_buttonAlert__x6aq0 {
    padding: 0.5rem 1rem;
  }
}
.styles_buttonAlert__x6aq0:hover {
  border: 2px solid #d5d5d5;
  background: white;
}
.styles_buttonAlert__x6aq0.styles_shadow__3qSdx {
  box-shadow: 0 0 13px 0 rgba(255, 255, 255, 0.4);
}
.styles_buttonAlert__x6aq0.styles_pulse__3rktF {
  position: relative;
}
.styles_buttonAlert__x6aq0.styles_pulse__3rktF:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  -webkit-animation: styles_buttonPulse__3ijwl 2.25s infinite;
          animation: styles_buttonPulse__3ijwl 2.25s infinite;
}
.styles_buttonAlert__x6aq0.styles_pulse__3rktF:hover:after {
  -webkit-animation: none;
  animation: none;
}
.styles_buttonAlert__x6aq0 .styles_label__3deEh {
  font-size: 1.3rem;
  font-family: "Assistant", sans-serif;
  color: #000;
  padding: 0.35rem 0 0.5rem 0;
  margin: 0 0.5rem;
  position: relative;
  top: 0.1em;
  word-break: break-word;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.styles_buttonAlert__x6aq0 .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
}
.styles_buttonAlert__x6aq0 .styles_icon__3ZTUn svg path,
.styles_buttonAlert__x6aq0 .styles_icon__3ZTUn svg g {
  fill: #E2164A;
}
.styles_buttonAlert__x6aq0.styles_disabled__3bPAN, .styles_buttonAlert__x6aq0:disabled {
  background: #EEEEEE !important;
  border: 2px solid #EEEEEE !important;
  cursor: not-allowed !important;
}
.styles_buttonAlert__x6aq0.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonAlert__x6aq0:disabled .styles_label__3deEh {
  color: #9B9B9B !important;
}

.styles_buttonBlueAlert__3zsPq {
  background: #fff;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  padding: 0 1rem;
  border: 2px solid #8d8d8d;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
@media (max-width: 640px) {
  .styles_buttonBlueAlert__3zsPq {
    padding: 0.5rem 1rem;
  }
}
.styles_buttonBlueAlert__3zsPq:hover {
  border: 2px solid #737373;
  background: white;
}
.styles_buttonBlueAlert__3zsPq.styles_shadow__3qSdx {
  box-shadow: 0 0 13px 0 rgba(255, 255, 255, 0.4);
}
.styles_buttonBlueAlert__3zsPq.styles_pulse__3rktF {
  position: relative;
}
.styles_buttonBlueAlert__3zsPq.styles_pulse__3rktF:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  -webkit-animation: styles_buttonPulse__3ijwl 2.25s infinite;
          animation: styles_buttonPulse__3ijwl 2.25s infinite;
}
.styles_buttonBlueAlert__3zsPq.styles_pulse__3rktF:hover:after {
  -webkit-animation: none;
  animation: none;
}
.styles_buttonBlueAlert__3zsPq .styles_label__3deEh {
  font-size: 1.3rem;
  font-family: "Assistant", sans-serif;
  color: #000;
  padding: 0.35rem 0 0.5rem 0;
  margin: 0 0.5rem;
  position: relative;
  top: 0.1em;
  word-break: break-word;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.styles_buttonBlueAlert__3zsPq .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
}
.styles_buttonBlueAlert__3zsPq .styles_icon__3ZTUn svg path,
.styles_buttonBlueAlert__3zsPq .styles_icon__3ZTUn svg g {
  fill: #106FEE;
}
.styles_buttonBlueAlert__3zsPq.styles_disabled__3bPAN, .styles_buttonBlueAlert__3zsPq:disabled {
  background: #EEEEEE !important;
  border: 2px solid #EEEEEE !important;
  cursor: not-allowed !important;
}
.styles_buttonBlueAlert__3zsPq.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonBlueAlert__3zsPq:disabled .styles_label__3deEh {
  color: #9B9B9B !important;
}

.styles_buttonStopLog__2bi1i, .styles_buttonAlertFill__Rhyp0 {
  background: #E2164A;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  padding: 0 1rem;
  border: 0 solid #E2164A;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
@media (max-width: 640px) {
  .styles_buttonStopLog__2bi1i, .styles_buttonAlertFill__Rhyp0 {
    padding: 0.5rem 1rem;
  }
}
.styles_buttonStopLog__2bi1i:hover, .styles_buttonAlertFill__Rhyp0:hover {
  border: 0 solid #b4113b;
  background: #eb3160;
}
.styles_buttonStopLog__2bi1i.styles_shadow__3qSdx, .styles_buttonAlertFill__Rhyp0.styles_shadow__3qSdx {
  box-shadow: 0 0 13px 0 rgba(226, 22, 74, 0.4);
}
.styles_buttonStopLog__2bi1i.styles_pulse__3rktF, .styles_buttonAlertFill__Rhyp0.styles_pulse__3rktF {
  position: relative;
}
.styles_buttonStopLog__2bi1i.styles_pulse__3rktF:after, .styles_buttonAlertFill__Rhyp0.styles_pulse__3rktF:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 0 rgba(226, 22, 74, 0.4);
  -webkit-animation: styles_buttonPulse__3ijwl 2.25s infinite;
          animation: styles_buttonPulse__3ijwl 2.25s infinite;
}
.styles_buttonStopLog__2bi1i.styles_pulse__3rktF:hover:after, .styles_buttonAlertFill__Rhyp0.styles_pulse__3rktF:hover:after {
  -webkit-animation: none;
  animation: none;
}
.styles_buttonStopLog__2bi1i .styles_label__3deEh, .styles_buttonAlertFill__Rhyp0 .styles_label__3deEh {
  font-size: 1.3rem;
  font-family: "Assistant", sans-serif;
  color: #fff;
  padding: 0.35rem 0 0.5rem 0;
  margin: 0 0.5rem;
  position: relative;
  top: 0.1em;
  word-break: break-word;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.styles_buttonStopLog__2bi1i .styles_icon__3ZTUn svg, .styles_buttonAlertFill__Rhyp0 .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
}
.styles_buttonStopLog__2bi1i .styles_icon__3ZTUn svg path,
.styles_buttonStopLog__2bi1i .styles_icon__3ZTUn svg g, .styles_buttonAlertFill__Rhyp0 .styles_icon__3ZTUn svg path,
.styles_buttonAlertFill__Rhyp0 .styles_icon__3ZTUn svg g {
  fill: #fff;
}
.styles_buttonStopLog__2bi1i.styles_disabled__3bPAN, .styles_buttonStopLog__2bi1i:disabled, .styles_buttonAlertFill__Rhyp0.styles_disabled__3bPAN, .styles_buttonAlertFill__Rhyp0:disabled {
  background: #EEEEEE !important;
  border: 2px solid #EEEEEE !important;
  cursor: not-allowed !important;
}
.styles_buttonStopLog__2bi1i.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonStopLog__2bi1i:disabled .styles_label__3deEh, .styles_buttonAlertFill__Rhyp0.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonAlertFill__Rhyp0:disabled .styles_label__3deEh {
  color: #9B9B9B !important;
}

.styles_buttonSecondary__2PfGz {
  background: #fff;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  padding: 0 1rem;
  border: 2px solid #106FEE;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
@media (max-width: 640px) {
  .styles_buttonSecondary__2PfGz {
    padding: 0.5rem 1rem;
  }
}
.styles_buttonSecondary__2PfGz:hover {
  border: 2px solid #0d59be;
  background: white;
}
.styles_buttonSecondary__2PfGz.styles_shadow__3qSdx {
  box-shadow: 0 0 13px 0 rgba(255, 255, 255, 0.4);
}
.styles_buttonSecondary__2PfGz.styles_pulse__3rktF {
  position: relative;
}
.styles_buttonSecondary__2PfGz.styles_pulse__3rktF:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  -webkit-animation: styles_buttonPulse__3ijwl 2.25s infinite;
          animation: styles_buttonPulse__3ijwl 2.25s infinite;
}
.styles_buttonSecondary__2PfGz.styles_pulse__3rktF:hover:after {
  -webkit-animation: none;
  animation: none;
}
.styles_buttonSecondary__2PfGz .styles_label__3deEh {
  font-size: 1.3rem;
  font-family: "Assistant", sans-serif;
  color: #106FEE;
  padding: 0.35rem 0 0.5rem 0;
  margin: 0 0.5rem;
  position: relative;
  top: 0.1em;
  word-break: break-word;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.styles_buttonSecondary__2PfGz .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
}
.styles_buttonSecondary__2PfGz .styles_icon__3ZTUn svg path,
.styles_buttonSecondary__2PfGz .styles_icon__3ZTUn svg g {
  fill: #106FEE;
}
.styles_buttonSecondary__2PfGz.styles_disabled__3bPAN, .styles_buttonSecondary__2PfGz:disabled {
  background: #EEEEEE !important;
  border: 2px solid #EEEEEE !important;
  cursor: not-allowed !important;
}
.styles_buttonSecondary__2PfGz.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonSecondary__2PfGz:disabled .styles_label__3deEh {
  color: #9B9B9B !important;
}

.styles_buttonSecondaryFill__3wflf {
  background: #106FEE;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  padding: 0 1rem;
  border: 0 solid #106FEE;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
@media (max-width: 640px) {
  .styles_buttonSecondaryFill__3wflf {
    padding: 0.5rem 1rem;
  }
}
.styles_buttonSecondaryFill__3wflf:hover {
  border: 0 solid #0d59be;
  background: #3183f1;
}
.styles_buttonSecondaryFill__3wflf.styles_shadow__3qSdx {
  box-shadow: 0 0 13px 0 rgba(16, 111, 238, 0.4);
}
.styles_buttonSecondaryFill__3wflf.styles_pulse__3rktF {
  position: relative;
}
.styles_buttonSecondaryFill__3wflf.styles_pulse__3rktF:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 0 rgba(16, 111, 238, 0.4);
  -webkit-animation: styles_buttonPulse__3ijwl 2.25s infinite;
          animation: styles_buttonPulse__3ijwl 2.25s infinite;
}
.styles_buttonSecondaryFill__3wflf.styles_pulse__3rktF:hover:after {
  -webkit-animation: none;
  animation: none;
}
.styles_buttonSecondaryFill__3wflf .styles_label__3deEh {
  font-size: 1.3rem;
  font-family: "Assistant", sans-serif;
  color: #fff;
  padding: 0.35rem 0 0.5rem 0;
  margin: 0 0.5rem;
  position: relative;
  top: 0.1em;
  word-break: break-word;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.styles_buttonSecondaryFill__3wflf .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
}
.styles_buttonSecondaryFill__3wflf .styles_icon__3ZTUn svg path,
.styles_buttonSecondaryFill__3wflf .styles_icon__3ZTUn svg g {
  fill: #fff;
}
.styles_buttonSecondaryFill__3wflf.styles_disabled__3bPAN, .styles_buttonSecondaryFill__3wflf:disabled {
  background: #EEEEEE !important;
  border: 2px solid #EEEEEE !important;
  cursor: not-allowed !important;
}
.styles_buttonSecondaryFill__3wflf.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonSecondaryFill__3wflf:disabled .styles_label__3deEh {
  color: #9B9B9B !important;
}

.styles_buttonNewTicket__1aiMx {
  background: #fff;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  padding: 0 1rem;
  border: 2px solid #EEEEEE;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
@media (max-width: 640px) {
  .styles_buttonNewTicket__1aiMx {
    padding: 0.5rem 1rem;
  }
}
.styles_buttonNewTicket__1aiMx:hover {
  border: 2px solid #d5d5d5;
  background: white;
}
.styles_buttonNewTicket__1aiMx.styles_shadow__3qSdx {
  box-shadow: 0 0 13px 0 rgba(255, 255, 255, 0.4);
}
.styles_buttonNewTicket__1aiMx.styles_pulse__3rktF {
  position: relative;
}
.styles_buttonNewTicket__1aiMx.styles_pulse__3rktF:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  -webkit-animation: styles_buttonPulse__3ijwl 2.25s infinite;
          animation: styles_buttonPulse__3ijwl 2.25s infinite;
}
.styles_buttonNewTicket__1aiMx.styles_pulse__3rktF:hover:after {
  -webkit-animation: none;
  animation: none;
}
.styles_buttonNewTicket__1aiMx .styles_label__3deEh {
  font-size: 1.3rem;
  font-family: "Assistant", sans-serif;
  color: #000;
  padding: 0.35rem 0 0.5rem 0;
  margin: 0 0.5rem;
  position: relative;
  top: 0.1em;
  word-break: break-word;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.styles_buttonNewTicket__1aiMx .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
}
.styles_buttonNewTicket__1aiMx .styles_icon__3ZTUn svg path,
.styles_buttonNewTicket__1aiMx .styles_icon__3ZTUn svg g {
  fill: #106FEE;
}
.styles_buttonNewTicket__1aiMx.styles_disabled__3bPAN, .styles_buttonNewTicket__1aiMx:disabled {
  background: #EEEEEE !important;
  border: 2px solid #EEEEEE !important;
  cursor: not-allowed !important;
}
.styles_buttonNewTicket__1aiMx.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonNewTicket__1aiMx:disabled .styles_label__3deEh {
  color: #9B9B9B !important;
}

.styles_buttonBack__2WVA9 {
  background: #106FEE;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  padding: 0 1rem;
  border: 0 solid #9b9b9b;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
@media (max-width: 640px) {
  .styles_buttonBack__2WVA9 {
    padding: 0.5rem 1rem;
  }
}
.styles_buttonBack__2WVA9:hover {
  border: 0 solid #828282;
  background: #3183f1;
}
.styles_buttonBack__2WVA9.styles_shadow__3qSdx {
  box-shadow: 0 0 13px 0 rgba(16, 111, 238, 0.4);
}
.styles_buttonBack__2WVA9.styles_pulse__3rktF {
  position: relative;
}
.styles_buttonBack__2WVA9.styles_pulse__3rktF:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 0 rgba(16, 111, 238, 0.4);
  -webkit-animation: styles_buttonPulse__3ijwl 2.25s infinite;
          animation: styles_buttonPulse__3ijwl 2.25s infinite;
}
.styles_buttonBack__2WVA9.styles_pulse__3rktF:hover:after {
  -webkit-animation: none;
  animation: none;
}
.styles_buttonBack__2WVA9 .styles_label__3deEh {
  font-size: 1.3rem;
  font-family: "Assistant", sans-serif;
  color: #9b9b9b;
  padding: 0.35rem 0 0.5rem 0;
  margin: 0 0.5rem;
  position: relative;
  top: 0.1em;
  word-break: break-word;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.styles_buttonBack__2WVA9 .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
}
.styles_buttonBack__2WVA9 .styles_icon__3ZTUn svg path,
.styles_buttonBack__2WVA9 .styles_icon__3ZTUn svg g {
  fill: #fff;
}
.styles_buttonBack__2WVA9.styles_disabled__3bPAN, .styles_buttonBack__2WVA9:disabled {
  background: #EEEEEE !important;
  border: 2px solid #EEEEEE !important;
  cursor: not-allowed !important;
}
.styles_buttonBack__2WVA9.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonBack__2WVA9:disabled .styles_label__3deEh {
  color: #9B9B9B !important;
}

.styles_buttonLink__3gs7n {
  background: #fff;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  padding: 0 1rem;
  border: 2px solid #9a9a9a;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
@media (max-width: 640px) {
  .styles_buttonLink__3gs7n {
    padding: 0.5rem 1rem;
  }
}
.styles_buttonLink__3gs7n:hover {
  border: 2px solid gray;
  background: white;
}
.styles_buttonLink__3gs7n.styles_shadow__3qSdx {
  box-shadow: 0 0 13px 0 rgba(255, 255, 255, 0.4);
}
.styles_buttonLink__3gs7n.styles_pulse__3rktF {
  position: relative;
}
.styles_buttonLink__3gs7n.styles_pulse__3rktF:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  -webkit-animation: styles_buttonPulse__3ijwl 2.25s infinite;
          animation: styles_buttonPulse__3ijwl 2.25s infinite;
}
.styles_buttonLink__3gs7n.styles_pulse__3rktF:hover:after {
  -webkit-animation: none;
  animation: none;
}
.styles_buttonLink__3gs7n .styles_label__3deEh {
  font-size: 1.3rem;
  font-family: "Assistant", sans-serif;
  color: #9a9a9a;
  padding: 0.35rem 0 0.5rem 0;
  margin: 0 0.5rem;
  position: relative;
  top: 0.1em;
  word-break: break-word;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.styles_buttonLink__3gs7n .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
}
.styles_buttonLink__3gs7n .styles_icon__3ZTUn svg path,
.styles_buttonLink__3gs7n .styles_icon__3ZTUn svg g {
  fill: #9a9a9a;
}
.styles_buttonLink__3gs7n.styles_disabled__3bPAN, .styles_buttonLink__3gs7n:disabled {
  background: #EEEEEE !important;
  border: 2px solid #EEEEEE !important;
  cursor: not-allowed !important;
}
.styles_buttonLink__3gs7n.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonLink__3gs7n:disabled .styles_label__3deEh {
  color: #9B9B9B !important;
}
.styles_buttonLink__3gs7n .styles_label__3deEh {
  text-decoration: underline;
  color: #106FEE;
}
.styles_buttonLink__3gs7n:hover {
  background: #fff;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  padding: 0 1rem;
  border: 2px solid #B538DE;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
@media (max-width: 640px) {
  .styles_buttonLink__3gs7n:hover {
    padding: 0.5rem 1rem;
  }
}
.styles_buttonLink__3gs7n:hover:hover {
  border: 2px solid #9b20c3;
  background: white;
}
.styles_buttonLink__3gs7n:hover.styles_shadow__3qSdx {
  box-shadow: 0 0 13px 0 rgba(255, 255, 255, 0.4);
}
.styles_buttonLink__3gs7n:hover.styles_pulse__3rktF {
  position: relative;
}
.styles_buttonLink__3gs7n:hover.styles_pulse__3rktF:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  -webkit-animation: styles_buttonPulse__3ijwl 2.25s infinite;
          animation: styles_buttonPulse__3ijwl 2.25s infinite;
}
.styles_buttonLink__3gs7n:hover.styles_pulse__3rktF:hover:after {
  -webkit-animation: none;
  animation: none;
}
.styles_buttonLink__3gs7n:hover .styles_label__3deEh {
  font-size: 1.3rem;
  font-family: "Assistant", sans-serif;
  color: #B538DE;
  padding: 0.35rem 0 0.5rem 0;
  margin: 0 0.5rem;
  position: relative;
  top: 0.1em;
  word-break: break-word;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.styles_buttonLink__3gs7n:hover .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
}
.styles_buttonLink__3gs7n:hover .styles_icon__3ZTUn svg path,
.styles_buttonLink__3gs7n:hover .styles_icon__3ZTUn svg g {
  fill: #B538DE;
}
.styles_buttonLink__3gs7n:hover.styles_disabled__3bPAN, .styles_buttonLink__3gs7n:hover:disabled {
  background: #EEEEEE !important;
  border: 2px solid #EEEEEE !important;
  cursor: not-allowed !important;
}
.styles_buttonLink__3gs7n:hover.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonLink__3gs7n:hover:disabled .styles_label__3deEh {
  color: #9B9B9B !important;
}
.styles_buttonLink__3gs7n.styles_toggleActive__2Htq3 {
  background: #B538DE;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  padding: 0 1rem;
  border: 2px solid #B538DE;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  padding: 0;
}
@media (max-width: 640px) {
  .styles_buttonLink__3gs7n.styles_toggleActive__2Htq3 {
    padding: 0.5rem 1rem;
  }
}
.styles_buttonLink__3gs7n.styles_toggleActive__2Htq3:hover {
  border: 2px solid #9b20c3;
  background: #c057e3;
}
.styles_buttonLink__3gs7n.styles_toggleActive__2Htq3.styles_shadow__3qSdx {
  box-shadow: 0 0 13px 0 rgba(181, 56, 222, 0.4);
}
.styles_buttonLink__3gs7n.styles_toggleActive__2Htq3.styles_pulse__3rktF {
  position: relative;
}
.styles_buttonLink__3gs7n.styles_toggleActive__2Htq3.styles_pulse__3rktF:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 0 rgba(181, 56, 222, 0.4);
  -webkit-animation: styles_buttonPulse__3ijwl 2.25s infinite;
          animation: styles_buttonPulse__3ijwl 2.25s infinite;
}
.styles_buttonLink__3gs7n.styles_toggleActive__2Htq3.styles_pulse__3rktF:hover:after {
  -webkit-animation: none;
  animation: none;
}
.styles_buttonLink__3gs7n.styles_toggleActive__2Htq3 .styles_label__3deEh {
  font-size: 1.3rem;
  font-family: "Assistant", sans-serif;
  color: #fff;
  padding: 0.35rem 0 0.5rem 0;
  margin: 0 0.5rem;
  position: relative;
  top: 0.1em;
  word-break: break-word;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.styles_buttonLink__3gs7n.styles_toggleActive__2Htq3 .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
}
.styles_buttonLink__3gs7n.styles_toggleActive__2Htq3 .styles_icon__3ZTUn svg path,
.styles_buttonLink__3gs7n.styles_toggleActive__2Htq3 .styles_icon__3ZTUn svg g {
  fill: #fff;
}
.styles_buttonLink__3gs7n.styles_toggleActive__2Htq3.styles_disabled__3bPAN, .styles_buttonLink__3gs7n.styles_toggleActive__2Htq3:disabled {
  background: #EEEEEE !important;
  border: 2px solid #EEEEEE !important;
  cursor: not-allowed !important;
}
.styles_buttonLink__3gs7n.styles_toggleActive__2Htq3.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonLink__3gs7n.styles_toggleActive__2Htq3:disabled .styles_label__3deEh {
  color: #9B9B9B !important;
}
.styles_buttonLink__3gs7n.styles_toggleActive__2Htq3 .styles_icon__3ZTUn {
  border: 2px solid #B538DE;
}
.styles_buttonLink__3gs7n.styles_toggleActive__2Htq3:hover .styles_icon__3ZTUn {
  border: 2px solid #c564e5;
  background: #c564e5;
}

.styles_buttonTinyPrimary__n8NX8 {
  cursor: pointer;
  z-index: 1;
  padding: 0;
  display: inline-flex;
}
.styles_buttonTinyPrimary__n8NX8 .styles_wrapper__22iDk {
  display: inline-flex;
  justify-content: left;
}
.styles_buttonTinyPrimary__n8NX8 .styles_label__3deEh {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  z-index: 3;
  position: relative;
  top: 0.1em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}
.styles_buttonTinyPrimary__n8NX8.styles_hideLabel__2wy59 {
  position: relative;
}
.styles_buttonTinyPrimary__n8NX8.styles_hideLabel__2wy59 .styles_label__3deEh {
  position: absolute;
  opacity: 0;
  left: 2.75rem;
  top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  line-height: 0;
  background: #fff;
  overflow: visible;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  border-radius: 4px;
}
.styles_buttonTinyPrimary__n8NX8.styles_hideLabel__2wy59 .styles_label__3deEh:after {
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
  left: -10px;
  border-style: solid;
  border-width: 6px 10px 6px 0;
  position: absolute;
  border-color: transparent #fff transparent transparent;
}
.styles_buttonTinyPrimary__n8NX8.styles_hideLabel__2wy59 .styles_label__3deEh:before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.1);
}
.styles_buttonTinyPrimary__n8NX8 .styles_icon__3ZTUn {
  position: relative;
  border: 0 solid #1E9F76;
  background: #1E9F76;
  margin: 2px;
  border-radius: 4px;
  height: 1.5rem;
  width: 1.5rem;
}
.styles_buttonTinyPrimary__n8NX8 .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 3;
  width: 1rem;
  height: 1rem;
}
.styles_buttonTinyPrimary__n8NX8 .styles_icon__3ZTUn svg * {
  fill: #fff;
}
.styles_buttonTinyPrimary__n8NX8:hover .styles_icon__3ZTUn {
  background: #24bd8c;
  border-color: #f2f2f2;
}
.styles_buttonTinyPrimary__n8NX8:hover .styles_label__3deEh {
  opacity: 1;
}
.styles_buttonTinyPrimary__n8NX8.styles_disabled__3bPAN, .styles_buttonTinyPrimary__n8NX8:disabled {
  background: #d8d8d8 !important;
  border: 2px solid #d8d8d8 !important;
  cursor: not-allowed !important;
}
.styles_buttonTinyPrimary__n8NX8.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonTinyPrimary__n8NX8:disabled .styles_label__3deEh {
  color: #c1c1c1 !important;
}
.styles_buttonTinyPrimary__n8NX8.styles_disabled__3bPAN svg *, .styles_buttonTinyPrimary__n8NX8:disabled svg * {
  fill: #c1c1c1;
}

.styles_buttonTinyAlert__i0A-s {
  cursor: pointer;
  z-index: 1;
  padding: 0;
  display: inline-flex;
}
.styles_buttonTinyAlert__i0A-s .styles_wrapper__22iDk {
  display: inline-flex;
  justify-content: left;
}
.styles_buttonTinyAlert__i0A-s .styles_label__3deEh {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  z-index: 3;
  position: relative;
  top: 0.1em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}
.styles_buttonTinyAlert__i0A-s.styles_hideLabel__2wy59 {
  position: relative;
}
.styles_buttonTinyAlert__i0A-s.styles_hideLabel__2wy59 .styles_label__3deEh {
  position: absolute;
  opacity: 0;
  left: 2.75rem;
  top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  line-height: 0;
  background: #fff;
  overflow: visible;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  border-radius: 4px;
}
.styles_buttonTinyAlert__i0A-s.styles_hideLabel__2wy59 .styles_label__3deEh:after {
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
  left: -10px;
  border-style: solid;
  border-width: 6px 10px 6px 0;
  position: absolute;
  border-color: transparent #fff transparent transparent;
}
.styles_buttonTinyAlert__i0A-s.styles_hideLabel__2wy59 .styles_label__3deEh:before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.1);
}
.styles_buttonTinyAlert__i0A-s .styles_icon__3ZTUn {
  position: relative;
  border: 2px solid #8d8d8d;
  background: #fff;
  margin: 2px;
  border-radius: 4px;
  height: 1.5rem;
  width: 1.5rem;
}
.styles_buttonTinyAlert__i0A-s .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 3;
  width: 1rem;
  height: 1rem;
}
.styles_buttonTinyAlert__i0A-s .styles_icon__3ZTUn svg * {
  fill: #E2164A;
}
.styles_buttonTinyAlert__i0A-s:hover .styles_icon__3ZTUn {
  background: white;
  border-color: #cb1442;
}
.styles_buttonTinyAlert__i0A-s:hover .styles_label__3deEh {
  opacity: 1;
}
.styles_buttonTinyAlert__i0A-s.styles_disabled__3bPAN, .styles_buttonTinyAlert__i0A-s:disabled {
  background: #d8d8d8 !important;
  border: 2px solid #d8d8d8 !important;
  cursor: not-allowed !important;
}
.styles_buttonTinyAlert__i0A-s.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonTinyAlert__i0A-s:disabled .styles_label__3deEh {
  color: #c1c1c1 !important;
}
.styles_buttonTinyAlert__i0A-s.styles_disabled__3bPAN svg *, .styles_buttonTinyAlert__i0A-s:disabled svg * {
  fill: #c1c1c1;
}

.styles_buttonTinyAlertFill__31luJ {
  cursor: pointer;
  z-index: 1;
  padding: 0;
  display: inline-flex;
}
.styles_buttonTinyAlertFill__31luJ .styles_wrapper__22iDk {
  display: inline-flex;
  justify-content: left;
}
.styles_buttonTinyAlertFill__31luJ .styles_label__3deEh {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  z-index: 3;
  position: relative;
  top: 0.1em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}
.styles_buttonTinyAlertFill__31luJ.styles_hideLabel__2wy59 {
  position: relative;
}
.styles_buttonTinyAlertFill__31luJ.styles_hideLabel__2wy59 .styles_label__3deEh {
  position: absolute;
  opacity: 0;
  left: 2.75rem;
  top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  line-height: 0;
  background: #fff;
  overflow: visible;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  border-radius: 4px;
}
.styles_buttonTinyAlertFill__31luJ.styles_hideLabel__2wy59 .styles_label__3deEh:after {
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
  left: -10px;
  border-style: solid;
  border-width: 6px 10px 6px 0;
  position: absolute;
  border-color: transparent #fff transparent transparent;
}
.styles_buttonTinyAlertFill__31luJ.styles_hideLabel__2wy59 .styles_label__3deEh:before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.1);
}
.styles_buttonTinyAlertFill__31luJ .styles_icon__3ZTUn {
  position: relative;
  border: 0 solid #E2164A;
  background: #E2164A;
  margin: 2px;
  border-radius: 4px;
  height: 1.5rem;
  width: 1.5rem;
}
.styles_buttonTinyAlertFill__31luJ .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 3;
  width: 1rem;
  height: 1rem;
}
.styles_buttonTinyAlertFill__31luJ .styles_icon__3ZTUn svg * {
  fill: #fff;
}
.styles_buttonTinyAlertFill__31luJ:hover .styles_icon__3ZTUn {
  background: #eb3160;
  border-color: #f2f2f2;
}
.styles_buttonTinyAlertFill__31luJ:hover .styles_label__3deEh {
  opacity: 1;
}
.styles_buttonTinyAlertFill__31luJ.styles_disabled__3bPAN, .styles_buttonTinyAlertFill__31luJ:disabled {
  background: #d8d8d8 !important;
  border: 2px solid #d8d8d8 !important;
  cursor: not-allowed !important;
}
.styles_buttonTinyAlertFill__31luJ.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonTinyAlertFill__31luJ:disabled .styles_label__3deEh {
  color: #c1c1c1 !important;
}
.styles_buttonTinyAlertFill__31luJ.styles_disabled__3bPAN svg *, .styles_buttonTinyAlertFill__31luJ:disabled svg * {
  fill: #c1c1c1;
}

.styles_buttonTinyBlueAlert__1tr9D {
  cursor: pointer;
  z-index: 1;
  padding: 0;
  display: inline-flex;
}
.styles_buttonTinyBlueAlert__1tr9D .styles_wrapper__22iDk {
  display: inline-flex;
  justify-content: left;
}
.styles_buttonTinyBlueAlert__1tr9D .styles_label__3deEh {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  z-index: 3;
  position: relative;
  top: 0.1em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}
.styles_buttonTinyBlueAlert__1tr9D.styles_hideLabel__2wy59 {
  position: relative;
}
.styles_buttonTinyBlueAlert__1tr9D.styles_hideLabel__2wy59 .styles_label__3deEh {
  position: absolute;
  opacity: 0;
  left: 2.75rem;
  top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  line-height: 0;
  background: #fff;
  overflow: visible;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  border-radius: 4px;
}
.styles_buttonTinyBlueAlert__1tr9D.styles_hideLabel__2wy59 .styles_label__3deEh:after {
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
  left: -10px;
  border-style: solid;
  border-width: 6px 10px 6px 0;
  position: absolute;
  border-color: transparent #fff transparent transparent;
}
.styles_buttonTinyBlueAlert__1tr9D.styles_hideLabel__2wy59 .styles_label__3deEh:before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.1);
}
.styles_buttonTinyBlueAlert__1tr9D .styles_icon__3ZTUn {
  position: relative;
  border: 2px solid #8d8d8d;
  background: #fff;
  margin: 2px;
  border-radius: 4px;
  height: 1.5rem;
  width: 1.5rem;
}
.styles_buttonTinyBlueAlert__1tr9D .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 3;
  width: 1rem;
  height: 1rem;
}
.styles_buttonTinyBlueAlert__1tr9D .styles_icon__3ZTUn svg * {
  fill: #106FEE;
}
.styles_buttonTinyBlueAlert__1tr9D:hover .styles_icon__3ZTUn {
  background: white;
  border-color: #0e64d6;
}
.styles_buttonTinyBlueAlert__1tr9D:hover .styles_label__3deEh {
  opacity: 1;
}
.styles_buttonTinyBlueAlert__1tr9D.styles_disabled__3bPAN, .styles_buttonTinyBlueAlert__1tr9D:disabled {
  background: #d8d8d8 !important;
  border: 2px solid #d8d8d8 !important;
  cursor: not-allowed !important;
}
.styles_buttonTinyBlueAlert__1tr9D.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonTinyBlueAlert__1tr9D:disabled .styles_label__3deEh {
  color: #c1c1c1 !important;
}
.styles_buttonTinyBlueAlert__1tr9D.styles_disabled__3bPAN svg *, .styles_buttonTinyBlueAlert__1tr9D:disabled svg * {
  fill: #c1c1c1;
}

.styles_buttonTinyStopLog__zWoBo {
  cursor: pointer;
  z-index: 1;
  padding: 0;
  display: inline-flex;
}
.styles_buttonTinyStopLog__zWoBo .styles_wrapper__22iDk {
  display: inline-flex;
  justify-content: left;
}
.styles_buttonTinyStopLog__zWoBo .styles_label__3deEh {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  z-index: 3;
  position: relative;
  top: 0.1em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}
.styles_buttonTinyStopLog__zWoBo.styles_hideLabel__2wy59 {
  position: relative;
}
.styles_buttonTinyStopLog__zWoBo.styles_hideLabel__2wy59 .styles_label__3deEh {
  position: absolute;
  opacity: 0;
  left: 2.75rem;
  top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  line-height: 0;
  background: #fff;
  overflow: visible;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  border-radius: 4px;
}
.styles_buttonTinyStopLog__zWoBo.styles_hideLabel__2wy59 .styles_label__3deEh:after {
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
  left: -10px;
  border-style: solid;
  border-width: 6px 10px 6px 0;
  position: absolute;
  border-color: transparent #fff transparent transparent;
}
.styles_buttonTinyStopLog__zWoBo.styles_hideLabel__2wy59 .styles_label__3deEh:before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.1);
}
.styles_buttonTinyStopLog__zWoBo .styles_icon__3ZTUn {
  position: relative;
  border: 0 solid #E2164A;
  background: #E2164A;
  margin: 2px;
  border-radius: 4px;
  height: 1.5rem;
  width: 1.5rem;
}
.styles_buttonTinyStopLog__zWoBo .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 3;
  width: 1rem;
  height: 1rem;
}
.styles_buttonTinyStopLog__zWoBo .styles_icon__3ZTUn svg * {
  fill: #fff;
}
.styles_buttonTinyStopLog__zWoBo:hover .styles_icon__3ZTUn {
  background: #eb3160;
  border-color: #f2f2f2;
}
.styles_buttonTinyStopLog__zWoBo:hover .styles_label__3deEh {
  opacity: 1;
}
.styles_buttonTinyStopLog__zWoBo.styles_disabled__3bPAN, .styles_buttonTinyStopLog__zWoBo:disabled {
  background: #d8d8d8 !important;
  border: 2px solid #d8d8d8 !important;
  cursor: not-allowed !important;
}
.styles_buttonTinyStopLog__zWoBo.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonTinyStopLog__zWoBo:disabled .styles_label__3deEh {
  color: #c1c1c1 !important;
}
.styles_buttonTinyStopLog__zWoBo.styles_disabled__3bPAN svg *, .styles_buttonTinyStopLog__zWoBo:disabled svg * {
  fill: #c1c1c1;
}

.styles_buttonTinySecondary__3v5Hg {
  cursor: pointer;
  z-index: 1;
  padding: 0;
  display: inline-flex;
}
.styles_buttonTinySecondary__3v5Hg .styles_wrapper__22iDk {
  display: inline-flex;
  justify-content: left;
}
.styles_buttonTinySecondary__3v5Hg .styles_label__3deEh {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  z-index: 3;
  position: relative;
  top: 0.1em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}
.styles_buttonTinySecondary__3v5Hg.styles_hideLabel__2wy59 {
  position: relative;
}
.styles_buttonTinySecondary__3v5Hg.styles_hideLabel__2wy59 .styles_label__3deEh {
  position: absolute;
  opacity: 0;
  left: 2.75rem;
  top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  line-height: 0;
  background: #fff;
  overflow: visible;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  border-radius: 4px;
}
.styles_buttonTinySecondary__3v5Hg.styles_hideLabel__2wy59 .styles_label__3deEh:after {
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
  left: -10px;
  border-style: solid;
  border-width: 6px 10px 6px 0;
  position: absolute;
  border-color: transparent #fff transparent transparent;
}
.styles_buttonTinySecondary__3v5Hg.styles_hideLabel__2wy59 .styles_label__3deEh:before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.1);
}
.styles_buttonTinySecondary__3v5Hg .styles_icon__3ZTUn {
  position: relative;
  border: 2px solid #106FEE;
  background: #fff;
  margin: 2px;
  border-radius: 4px;
  height: 1.5rem;
  width: 1.5rem;
}
.styles_buttonTinySecondary__3v5Hg .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 3;
  width: 1rem;
  height: 1rem;
}
.styles_buttonTinySecondary__3v5Hg .styles_icon__3ZTUn svg * {
  fill: #106FEE;
}
.styles_buttonTinySecondary__3v5Hg:hover .styles_icon__3ZTUn {
  background: white;
  border-color: #0e64d6;
}
.styles_buttonTinySecondary__3v5Hg:hover .styles_label__3deEh {
  opacity: 1;
}
.styles_buttonTinySecondary__3v5Hg.styles_disabled__3bPAN, .styles_buttonTinySecondary__3v5Hg:disabled {
  background: #d8d8d8 !important;
  border: 2px solid #d8d8d8 !important;
  cursor: not-allowed !important;
}
.styles_buttonTinySecondary__3v5Hg.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonTinySecondary__3v5Hg:disabled .styles_label__3deEh {
  color: #c1c1c1 !important;
}
.styles_buttonTinySecondary__3v5Hg.styles_disabled__3bPAN svg *, .styles_buttonTinySecondary__3v5Hg:disabled svg * {
  fill: #c1c1c1;
}

.styles_buttonTinySecondaryFill__2D0mW {
  cursor: pointer;
  z-index: 1;
  padding: 0;
  display: inline-flex;
}
.styles_buttonTinySecondaryFill__2D0mW .styles_wrapper__22iDk {
  display: inline-flex;
  justify-content: left;
}
.styles_buttonTinySecondaryFill__2D0mW .styles_label__3deEh {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  z-index: 3;
  position: relative;
  top: 0.1em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}
.styles_buttonTinySecondaryFill__2D0mW.styles_hideLabel__2wy59 {
  position: relative;
}
.styles_buttonTinySecondaryFill__2D0mW.styles_hideLabel__2wy59 .styles_label__3deEh {
  position: absolute;
  opacity: 0;
  left: 2.75rem;
  top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  line-height: 0;
  background: #fff;
  overflow: visible;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  border-radius: 4px;
}
.styles_buttonTinySecondaryFill__2D0mW.styles_hideLabel__2wy59 .styles_label__3deEh:after {
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
  left: -10px;
  border-style: solid;
  border-width: 6px 10px 6px 0;
  position: absolute;
  border-color: transparent #fff transparent transparent;
}
.styles_buttonTinySecondaryFill__2D0mW.styles_hideLabel__2wy59 .styles_label__3deEh:before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.1);
}
.styles_buttonTinySecondaryFill__2D0mW .styles_icon__3ZTUn {
  position: relative;
  border: 2px solid #106FEE;
  background: #106FEE;
  margin: 2px;
  border-radius: 4px;
  height: 1.5rem;
  width: 1.5rem;
}
.styles_buttonTinySecondaryFill__2D0mW .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 3;
  width: 1rem;
  height: 1rem;
}
.styles_buttonTinySecondaryFill__2D0mW .styles_icon__3ZTUn svg * {
  fill: #fff;
}
.styles_buttonTinySecondaryFill__2D0mW:hover .styles_icon__3ZTUn {
  background: #3183f1;
  border-color: #f2f2f2;
}
.styles_buttonTinySecondaryFill__2D0mW:hover .styles_label__3deEh {
  opacity: 1;
}
.styles_buttonTinySecondaryFill__2D0mW.styles_disabled__3bPAN, .styles_buttonTinySecondaryFill__2D0mW:disabled {
  background: #d8d8d8 !important;
  border: 2px solid #d8d8d8 !important;
  cursor: not-allowed !important;
}
.styles_buttonTinySecondaryFill__2D0mW.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonTinySecondaryFill__2D0mW:disabled .styles_label__3deEh {
  color: #c1c1c1 !important;
}
.styles_buttonTinySecondaryFill__2D0mW.styles_disabled__3bPAN svg *, .styles_buttonTinySecondaryFill__2D0mW:disabled svg * {
  fill: #c1c1c1;
}

.styles_buttonTinyBack__ElwhZ {
  cursor: pointer;
  z-index: 1;
  padding: 0;
  display: inline-flex;
}
.styles_buttonTinyBack__ElwhZ .styles_wrapper__22iDk {
  display: inline-flex;
  justify-content: left;
}
.styles_buttonTinyBack__ElwhZ .styles_label__3deEh {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  z-index: 3;
  position: relative;
  top: 0.1em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}
.styles_buttonTinyBack__ElwhZ.styles_hideLabel__2wy59 {
  position: relative;
}
.styles_buttonTinyBack__ElwhZ.styles_hideLabel__2wy59 .styles_label__3deEh {
  position: absolute;
  opacity: 0;
  left: 2.75rem;
  top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  line-height: 0;
  background: #fff;
  overflow: visible;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  border-radius: 4px;
}
.styles_buttonTinyBack__ElwhZ.styles_hideLabel__2wy59 .styles_label__3deEh:after {
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
  left: -10px;
  border-style: solid;
  border-width: 6px 10px 6px 0;
  position: absolute;
  border-color: transparent #fff transparent transparent;
}
.styles_buttonTinyBack__ElwhZ.styles_hideLabel__2wy59 .styles_label__3deEh:before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.1);
}
.styles_buttonTinyBack__ElwhZ .styles_icon__3ZTUn {
  position: relative;
  border: 2px solid #9b9b9b;
  background: #fff;
  margin: 2px;
  border-radius: 4px;
  height: 1.5rem;
  width: 1.5rem;
}
.styles_buttonTinyBack__ElwhZ .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 3;
  width: 1rem;
  height: 1rem;
}
.styles_buttonTinyBack__ElwhZ .styles_icon__3ZTUn svg * {
  fill: #9b9b9b;
}
.styles_buttonTinyBack__ElwhZ:hover .styles_icon__3ZTUn {
  background: white;
  border-color: #8e8e8e;
}
.styles_buttonTinyBack__ElwhZ:hover .styles_label__3deEh {
  opacity: 1;
}
.styles_buttonTinyBack__ElwhZ.styles_disabled__3bPAN, .styles_buttonTinyBack__ElwhZ:disabled {
  background: #d8d8d8 !important;
  border: 2px solid #d8d8d8 !important;
  cursor: not-allowed !important;
}
.styles_buttonTinyBack__ElwhZ.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonTinyBack__ElwhZ:disabled .styles_label__3deEh {
  color: #c1c1c1 !important;
}
.styles_buttonTinyBack__ElwhZ.styles_disabled__3bPAN svg *, .styles_buttonTinyBack__ElwhZ:disabled svg * {
  fill: #c1c1c1;
}
.styles_buttonTinyBack__ElwhZ .styles_label__3deEh {
  color: #9b9b9b;
}
.styles_buttonTinyBack__ElwhZ:hover {
  cursor: pointer;
  z-index: 1;
  padding: 0;
  display: inline-flex;
}
.styles_buttonTinyBack__ElwhZ:hover .styles_wrapper__22iDk {
  display: inline-flex;
  justify-content: left;
}
.styles_buttonTinyBack__ElwhZ:hover .styles_label__3deEh {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  z-index: 3;
  position: relative;
  top: 0.1em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}
.styles_buttonTinyBack__ElwhZ:hover.styles_hideLabel__2wy59 {
  position: relative;
}
.styles_buttonTinyBack__ElwhZ:hover.styles_hideLabel__2wy59 .styles_label__3deEh {
  position: absolute;
  opacity: 0;
  left: 2.75rem;
  top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  line-height: 0;
  background: #fff;
  overflow: visible;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  border-radius: 4px;
}
.styles_buttonTinyBack__ElwhZ:hover.styles_hideLabel__2wy59 .styles_label__3deEh:after {
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
  left: -10px;
  border-style: solid;
  border-width: 6px 10px 6px 0;
  position: absolute;
  border-color: transparent #fff transparent transparent;
}
.styles_buttonTinyBack__ElwhZ:hover.styles_hideLabel__2wy59 .styles_label__3deEh:before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.1);
}
.styles_buttonTinyBack__ElwhZ:hover .styles_icon__3ZTUn {
  position: relative;
  border: 2px solid #B538DE;
  background: #fff;
  margin: 2px;
  border-radius: 4px;
  height: 1.5rem;
  width: 1.5rem;
}
.styles_buttonTinyBack__ElwhZ:hover .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 3;
  width: 1rem;
  height: 1rem;
}
.styles_buttonTinyBack__ElwhZ:hover .styles_icon__3ZTUn svg * {
  fill: #B538DE;
}
.styles_buttonTinyBack__ElwhZ:hover:hover .styles_icon__3ZTUn {
  background: white;
  border-color: #ac24d9;
}
.styles_buttonTinyBack__ElwhZ:hover:hover .styles_label__3deEh {
  opacity: 1;
}
.styles_buttonTinyBack__ElwhZ:hover.styles_disabled__3bPAN, .styles_buttonTinyBack__ElwhZ:hover:disabled {
  background: #d8d8d8 !important;
  border: 2px solid #d8d8d8 !important;
  cursor: not-allowed !important;
}
.styles_buttonTinyBack__ElwhZ:hover.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonTinyBack__ElwhZ:hover:disabled .styles_label__3deEh {
  color: #c1c1c1 !important;
}
.styles_buttonTinyBack__ElwhZ:hover.styles_disabled__3bPAN svg *, .styles_buttonTinyBack__ElwhZ:hover:disabled svg * {
  fill: #c1c1c1;
}
.styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3 {
  cursor: pointer;
  z-index: 1;
  padding: 0;
  display: inline-flex;
  padding: 0;
}
.styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3 .styles_wrapper__22iDk {
  display: inline-flex;
  justify-content: left;
}
.styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3 .styles_label__3deEh {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  z-index: 3;
  position: relative;
  top: 0.1em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}
.styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3.styles_hideLabel__2wy59 {
  position: relative;
}
.styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3.styles_hideLabel__2wy59 .styles_label__3deEh {
  position: absolute;
  opacity: 0;
  left: 2.75rem;
  top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  line-height: 0;
  background: #fff;
  overflow: visible;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  border-radius: 4px;
}
.styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3.styles_hideLabel__2wy59 .styles_label__3deEh:after {
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
  left: -10px;
  border-style: solid;
  border-width: 6px 10px 6px 0;
  position: absolute;
  border-color: transparent #fff transparent transparent;
}
.styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3.styles_hideLabel__2wy59 .styles_label__3deEh:before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.1);
}
.styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3 .styles_icon__3ZTUn {
  position: relative;
  border: 2px solid #B538DE;
  background: #B538DE;
  margin: 2px;
  border-radius: 4px;
  height: 1.5rem;
  width: 1.5rem;
}
.styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3 .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 3;
  width: 1rem;
  height: 1rem;
}
.styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3 .styles_icon__3ZTUn svg * {
  fill: #fff;
}
.styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3:hover .styles_icon__3ZTUn {
  background: #c057e3;
  border-color: #f2f2f2;
}
.styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3:hover .styles_label__3deEh {
  opacity: 1;
}
.styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3.styles_disabled__3bPAN, .styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3:disabled {
  background: #d8d8d8 !important;
  border: 2px solid #d8d8d8 !important;
  cursor: not-allowed !important;
}
.styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3:disabled .styles_label__3deEh {
  color: #c1c1c1 !important;
}
.styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3.styles_disabled__3bPAN svg *, .styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3:disabled svg * {
  fill: #c1c1c1;
}
.styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3 .styles_icon__3ZTUn {
  border: 2px solid #B538DE;
}
.styles_buttonTinyBack__ElwhZ.styles_toggleActive__2Htq3:hover .styles_icon__3ZTUn {
  border: 2px solid #c564e5;
  background: #c564e5;
}

.styles_buttonTinyLink__2E40n {
  cursor: pointer;
  z-index: 1;
  padding: 0;
  display: inline-flex;
}
.styles_buttonTinyLink__2E40n .styles_wrapper__22iDk {
  display: inline-flex;
  justify-content: left;
}
.styles_buttonTinyLink__2E40n .styles_label__3deEh {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  z-index: 3;
  position: relative;
  top: 0.1em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}
.styles_buttonTinyLink__2E40n.styles_hideLabel__2wy59 {
  position: relative;
}
.styles_buttonTinyLink__2E40n.styles_hideLabel__2wy59 .styles_label__3deEh {
  position: absolute;
  opacity: 0;
  left: 2.75rem;
  top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  line-height: 0;
  background: #fff;
  overflow: visible;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  border-radius: 4px;
}
.styles_buttonTinyLink__2E40n.styles_hideLabel__2wy59 .styles_label__3deEh:after {
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
  left: -10px;
  border-style: solid;
  border-width: 6px 10px 6px 0;
  position: absolute;
  border-color: transparent #fff transparent transparent;
}
.styles_buttonTinyLink__2E40n.styles_hideLabel__2wy59 .styles_label__3deEh:before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.1);
}
.styles_buttonTinyLink__2E40n .styles_icon__3ZTUn {
  position: relative;
  border: 2px solid #9a9a9a;
  background: #fff;
  margin: 2px;
  border-radius: 4px;
  height: 1.5rem;
  width: 1.5rem;
}
.styles_buttonTinyLink__2E40n .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 3;
  width: 1rem;
  height: 1rem;
}
.styles_buttonTinyLink__2E40n .styles_icon__3ZTUn svg * {
  fill: #9a9a9a;
}
.styles_buttonTinyLink__2E40n:hover .styles_icon__3ZTUn {
  background: white;
  border-color: #8d8d8d;
}
.styles_buttonTinyLink__2E40n:hover .styles_label__3deEh {
  opacity: 1;
}
.styles_buttonTinyLink__2E40n.styles_disabled__3bPAN, .styles_buttonTinyLink__2E40n:disabled {
  background: #d8d8d8 !important;
  border: 2px solid #d8d8d8 !important;
  cursor: not-allowed !important;
}
.styles_buttonTinyLink__2E40n.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonTinyLink__2E40n:disabled .styles_label__3deEh {
  color: #c1c1c1 !important;
}
.styles_buttonTinyLink__2E40n.styles_disabled__3bPAN svg *, .styles_buttonTinyLink__2E40n:disabled svg * {
  fill: #c1c1c1;
}
.styles_buttonTinyLink__2E40n .styles_label__3deEh {
  text-decoration: underline;
  color: #106FEE;
}
.styles_buttonTinyLink__2E40n:hover {
  cursor: pointer;
  z-index: 1;
  padding: 0;
  display: inline-flex;
}
.styles_buttonTinyLink__2E40n:hover .styles_wrapper__22iDk {
  display: inline-flex;
  justify-content: left;
}
.styles_buttonTinyLink__2E40n:hover .styles_label__3deEh {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  z-index: 3;
  position: relative;
  top: 0.1em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}
.styles_buttonTinyLink__2E40n:hover.styles_hideLabel__2wy59 {
  position: relative;
}
.styles_buttonTinyLink__2E40n:hover.styles_hideLabel__2wy59 .styles_label__3deEh {
  position: absolute;
  opacity: 0;
  left: 2.75rem;
  top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  line-height: 0;
  background: #fff;
  overflow: visible;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  border-radius: 4px;
}
.styles_buttonTinyLink__2E40n:hover.styles_hideLabel__2wy59 .styles_label__3deEh:after {
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
  left: -10px;
  border-style: solid;
  border-width: 6px 10px 6px 0;
  position: absolute;
  border-color: transparent #fff transparent transparent;
}
.styles_buttonTinyLink__2E40n:hover.styles_hideLabel__2wy59 .styles_label__3deEh:before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.1);
}
.styles_buttonTinyLink__2E40n:hover .styles_icon__3ZTUn {
  position: relative;
  border: 2px solid #B538DE;
  background: #fff;
  margin: 2px;
  border-radius: 4px;
  height: 1.5rem;
  width: 1.5rem;
}
.styles_buttonTinyLink__2E40n:hover .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 3;
  width: 1rem;
  height: 1rem;
}
.styles_buttonTinyLink__2E40n:hover .styles_icon__3ZTUn svg * {
  fill: #B538DE;
}
.styles_buttonTinyLink__2E40n:hover:hover .styles_icon__3ZTUn {
  background: white;
  border-color: #ac24d9;
}
.styles_buttonTinyLink__2E40n:hover:hover .styles_label__3deEh {
  opacity: 1;
}
.styles_buttonTinyLink__2E40n:hover.styles_disabled__3bPAN, .styles_buttonTinyLink__2E40n:hover:disabled {
  background: #d8d8d8 !important;
  border: 2px solid #d8d8d8 !important;
  cursor: not-allowed !important;
}
.styles_buttonTinyLink__2E40n:hover.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonTinyLink__2E40n:hover:disabled .styles_label__3deEh {
  color: #c1c1c1 !important;
}
.styles_buttonTinyLink__2E40n:hover.styles_disabled__3bPAN svg *, .styles_buttonTinyLink__2E40n:hover:disabled svg * {
  fill: #c1c1c1;
}
.styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3 {
  cursor: pointer;
  z-index: 1;
  padding: 0;
  display: inline-flex;
  padding: 0;
}
.styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3 .styles_wrapper__22iDk {
  display: inline-flex;
  justify-content: left;
}
.styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3 .styles_label__3deEh {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  z-index: 3;
  position: relative;
  top: 0.1em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}
.styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3.styles_hideLabel__2wy59 {
  position: relative;
}
.styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3.styles_hideLabel__2wy59 .styles_label__3deEh {
  position: absolute;
  opacity: 0;
  left: 2.75rem;
  top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  line-height: 0;
  background: #fff;
  overflow: visible;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  border-radius: 4px;
}
.styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3.styles_hideLabel__2wy59 .styles_label__3deEh:after {
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
  left: -10px;
  border-style: solid;
  border-width: 6px 10px 6px 0;
  position: absolute;
  border-color: transparent #fff transparent transparent;
}
.styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3.styles_hideLabel__2wy59 .styles_label__3deEh:before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.1);
}
.styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3 .styles_icon__3ZTUn {
  position: relative;
  border: 2px solid #B538DE;
  background: #B538DE;
  margin: 2px;
  border-radius: 4px;
  height: 1.5rem;
  width: 1.5rem;
}
.styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3 .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 3;
  width: 1rem;
  height: 1rem;
}
.styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3 .styles_icon__3ZTUn svg * {
  fill: #fff;
}
.styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3:hover .styles_icon__3ZTUn {
  background: #c057e3;
  border-color: #f2f2f2;
}
.styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3:hover .styles_label__3deEh {
  opacity: 1;
}
.styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3.styles_disabled__3bPAN, .styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3:disabled {
  background: #d8d8d8 !important;
  border: 2px solid #d8d8d8 !important;
  cursor: not-allowed !important;
}
.styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3:disabled .styles_label__3deEh {
  color: #c1c1c1 !important;
}
.styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3.styles_disabled__3bPAN svg *, .styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3:disabled svg * {
  fill: #c1c1c1;
}
.styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3 .styles_icon__3ZTUn {
  border: 2px solid #B538DE;
}
.styles_buttonTinyLink__2E40n.styles_toggleActive__2Htq3:hover .styles_icon__3ZTUn {
  border: 2px solid #c564e5;
  background: #c564e5;
}

.styles_buttonTinyToggle__3nEEY {
  cursor: pointer;
  z-index: 1;
  padding: 0;
  display: inline-flex;
}
.styles_buttonTinyToggle__3nEEY .styles_wrapper__22iDk {
  display: inline-flex;
  justify-content: left;
}
.styles_buttonTinyToggle__3nEEY .styles_label__3deEh {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  z-index: 3;
  position: relative;
  top: 0.1em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}
.styles_buttonTinyToggle__3nEEY.styles_hideLabel__2wy59 {
  position: relative;
}
.styles_buttonTinyToggle__3nEEY.styles_hideLabel__2wy59 .styles_label__3deEh {
  position: absolute;
  opacity: 0;
  left: 2.75rem;
  top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  line-height: 0;
  background: #fff;
  overflow: visible;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  border-radius: 4px;
}
.styles_buttonTinyToggle__3nEEY.styles_hideLabel__2wy59 .styles_label__3deEh:after {
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
  left: -10px;
  border-style: solid;
  border-width: 6px 10px 6px 0;
  position: absolute;
  border-color: transparent #fff transparent transparent;
}
.styles_buttonTinyToggle__3nEEY.styles_hideLabel__2wy59 .styles_label__3deEh:before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.1);
}
.styles_buttonTinyToggle__3nEEY .styles_icon__3ZTUn {
  position: relative;
  border: 2px solid #9b9b9b;
  background: #fff;
  margin: 2px;
  border-radius: 4px;
  height: 1.5rem;
  width: 1.5rem;
}
.styles_buttonTinyToggle__3nEEY .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 3;
  width: 1rem;
  height: 1rem;
}
.styles_buttonTinyToggle__3nEEY .styles_icon__3ZTUn svg * {
  fill: #9b9b9b;
}
.styles_buttonTinyToggle__3nEEY:hover .styles_icon__3ZTUn {
  background: white;
  border-color: #8e8e8e;
}
.styles_buttonTinyToggle__3nEEY:hover .styles_label__3deEh {
  opacity: 1;
}
.styles_buttonTinyToggle__3nEEY.styles_disabled__3bPAN, .styles_buttonTinyToggle__3nEEY:disabled {
  background: #d8d8d8 !important;
  border: 2px solid #d8d8d8 !important;
  cursor: not-allowed !important;
}
.styles_buttonTinyToggle__3nEEY.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonTinyToggle__3nEEY:disabled .styles_label__3deEh {
  color: #c1c1c1 !important;
}
.styles_buttonTinyToggle__3nEEY.styles_disabled__3bPAN svg *, .styles_buttonTinyToggle__3nEEY:disabled svg * {
  fill: #c1c1c1;
}
.styles_buttonTinyToggle__3nEEY .styles_label__3deEh {
  color: #9b9b9b;
}
.styles_buttonTinyToggle__3nEEY:hover {
  cursor: pointer;
  z-index: 1;
  padding: 0;
  display: inline-flex;
}
.styles_buttonTinyToggle__3nEEY:hover .styles_wrapper__22iDk {
  display: inline-flex;
  justify-content: left;
}
.styles_buttonTinyToggle__3nEEY:hover .styles_label__3deEh {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  z-index: 3;
  position: relative;
  top: 0.1em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}
.styles_buttonTinyToggle__3nEEY:hover.styles_hideLabel__2wy59 {
  position: relative;
}
.styles_buttonTinyToggle__3nEEY:hover.styles_hideLabel__2wy59 .styles_label__3deEh {
  position: absolute;
  opacity: 0;
  left: 2.75rem;
  top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  line-height: 0;
  background: #fff;
  overflow: visible;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  border-radius: 4px;
}
.styles_buttonTinyToggle__3nEEY:hover.styles_hideLabel__2wy59 .styles_label__3deEh:after {
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
  left: -10px;
  border-style: solid;
  border-width: 6px 10px 6px 0;
  position: absolute;
  border-color: transparent #fff transparent transparent;
}
.styles_buttonTinyToggle__3nEEY:hover.styles_hideLabel__2wy59 .styles_label__3deEh:before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.1);
}
.styles_buttonTinyToggle__3nEEY:hover .styles_icon__3ZTUn {
  position: relative;
  border: 2px solid #B538DE;
  background: #fff;
  margin: 2px;
  border-radius: 4px;
  height: 1.5rem;
  width: 1.5rem;
}
.styles_buttonTinyToggle__3nEEY:hover .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 3;
  width: 1rem;
  height: 1rem;
}
.styles_buttonTinyToggle__3nEEY:hover .styles_icon__3ZTUn svg * {
  fill: #B538DE;
}
.styles_buttonTinyToggle__3nEEY:hover:hover .styles_icon__3ZTUn {
  background: white;
  border-color: #ac24d9;
}
.styles_buttonTinyToggle__3nEEY:hover:hover .styles_label__3deEh {
  opacity: 1;
}
.styles_buttonTinyToggle__3nEEY:hover.styles_disabled__3bPAN, .styles_buttonTinyToggle__3nEEY:hover:disabled {
  background: #d8d8d8 !important;
  border: 2px solid #d8d8d8 !important;
  cursor: not-allowed !important;
}
.styles_buttonTinyToggle__3nEEY:hover.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonTinyToggle__3nEEY:hover:disabled .styles_label__3deEh {
  color: #c1c1c1 !important;
}
.styles_buttonTinyToggle__3nEEY:hover.styles_disabled__3bPAN svg *, .styles_buttonTinyToggle__3nEEY:hover:disabled svg * {
  fill: #c1c1c1;
}
.styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3 {
  cursor: pointer;
  z-index: 1;
  padding: 0;
  display: inline-flex;
  padding: 0;
}
.styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3 .styles_wrapper__22iDk {
  display: inline-flex;
  justify-content: left;
}
.styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3 .styles_label__3deEh {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  z-index: 3;
  position: relative;
  top: 0.1em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}
.styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3.styles_hideLabel__2wy59 {
  position: relative;
}
.styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3.styles_hideLabel__2wy59 .styles_label__3deEh {
  position: absolute;
  opacity: 0;
  left: 2.75rem;
  top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  line-height: 0;
  background: #fff;
  overflow: visible;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  border-radius: 4px;
}
.styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3.styles_hideLabel__2wy59 .styles_label__3deEh:after {
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
  left: -10px;
  border-style: solid;
  border-width: 6px 10px 6px 0;
  position: absolute;
  border-color: transparent #fff transparent transparent;
}
.styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3.styles_hideLabel__2wy59 .styles_label__3deEh:before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.1);
}
.styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3 .styles_icon__3ZTUn {
  position: relative;
  border: 2px solid #B538DE;
  background: #B538DE;
  margin: 2px;
  border-radius: 4px;
  height: 1.5rem;
  width: 1.5rem;
}
.styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3 .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 3;
  width: 1rem;
  height: 1rem;
}
.styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3 .styles_icon__3ZTUn svg * {
  fill: #fff;
}
.styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3:hover .styles_icon__3ZTUn {
  background: #c057e3;
  border-color: #f2f2f2;
}
.styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3:hover .styles_label__3deEh {
  opacity: 1;
}
.styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3.styles_disabled__3bPAN, .styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3:disabled {
  background: #d8d8d8 !important;
  border: 2px solid #d8d8d8 !important;
  cursor: not-allowed !important;
}
.styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3:disabled .styles_label__3deEh {
  color: #c1c1c1 !important;
}
.styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3.styles_disabled__3bPAN svg *, .styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3:disabled svg * {
  fill: #c1c1c1;
}
.styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3 .styles_icon__3ZTUn {
  border: 2px solid #B538DE;
}
.styles_buttonTinyToggle__3nEEY.styles_toggleActive__2Htq3:hover .styles_icon__3ZTUn {
  border: 2px solid #c564e5;
  background: #c564e5;
}

.styles_thin__1nkH8 {
  line-height: 1rem;
  padding: 0.5rem 1rem;
}
.styles_thin__1nkH8 .styles_label__3deEh {
  font-size: 1rem;
}
@media (max-width: 640px) {
  .styles_thin__1nkH8 .styles_label__3deEh {
    font-size: 1rem;
  }
}

.styles_buttonDirectionsFill__1DmaF {
  cursor: pointer;
  z-index: 1;
  padding: 0;
  display: inline-flex;
}
.styles_buttonDirectionsFill__1DmaF .styles_wrapper__22iDk {
  display: inline-flex;
  justify-content: left;
}
.styles_buttonDirectionsFill__1DmaF .styles_label__3deEh {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
  z-index: 3;
  position: relative;
  top: 0.1em;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}
.styles_buttonDirectionsFill__1DmaF.styles_hideLabel__2wy59 {
  position: relative;
}
.styles_buttonDirectionsFill__1DmaF.styles_hideLabel__2wy59 .styles_label__3deEh {
  position: absolute;
  opacity: 0;
  left: 2.75rem;
  top: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.5rem;
  line-height: 0;
  background: #fff;
  overflow: visible;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  border-radius: 4px;
}
.styles_buttonDirectionsFill__1DmaF.styles_hideLabel__2wy59 .styles_label__3deEh:after {
  content: "";
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -5px;
  left: -10px;
  border-style: solid;
  border-width: 6px 10px 6px 0;
  position: absolute;
  border-color: transparent #fff transparent transparent;
}
.styles_buttonDirectionsFill__1DmaF.styles_hideLabel__2wy59 .styles_label__3deEh:before {
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.1);
}
.styles_buttonDirectionsFill__1DmaF .styles_icon__3ZTUn {
  position: relative;
  border: 2px solid #106FEE;
  background: #106FEE;
  margin: 2px;
  border-radius: 4px;
  height: 1.5rem;
  width: 1.5rem;
}
.styles_buttonDirectionsFill__1DmaF .styles_icon__3ZTUn svg {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 3;
  width: 1rem;
  height: 1rem;
}
.styles_buttonDirectionsFill__1DmaF .styles_icon__3ZTUn svg * {
  fill: #fff;
}
.styles_buttonDirectionsFill__1DmaF:hover .styles_icon__3ZTUn {
  background: #3183f1;
  border-color: #f2f2f2;
}
.styles_buttonDirectionsFill__1DmaF:hover .styles_label__3deEh {
  opacity: 1;
}
.styles_buttonDirectionsFill__1DmaF.styles_disabled__3bPAN, .styles_buttonDirectionsFill__1DmaF:disabled {
  background: #d8d8d8 !important;
  border: 2px solid #d8d8d8 !important;
  cursor: not-allowed !important;
}
.styles_buttonDirectionsFill__1DmaF.styles_disabled__3bPAN .styles_label__3deEh, .styles_buttonDirectionsFill__1DmaF:disabled .styles_label__3deEh {
  color: #c1c1c1 !important;
}
.styles_buttonDirectionsFill__1DmaF.styles_disabled__3bPAN svg *, .styles_buttonDirectionsFill__1DmaF:disabled svg * {
  fill: #c1c1c1;
}
.styles_textCommon__2eiSN, .styles_textBold__1DyjG, .styles_textSecondary__3j4BD, .styles_textDefault__1eE9j {
  line-height: 1.4em;
}

.styles_textDefault__1eE9j {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__3j4BD {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__1DyjG {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__2uSPo {
  font-weight: 700;
}

.styles_alignRight__3f79j {
  text-align: right;
}

.styles_alignCenter__2Jhzs {
  text-align: center;
}

.styles_tagLabel__1o72p {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__3eIZ0 {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__htdZ0 {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__3-cX2 {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__1SGb-,
.styles_textHeading__3P5UQ,
.styles_textSubheading__1gEvQ {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__1tHAm {
  color: #9b9b9b;
}

.styles_container__1ue48 {
  position: relative;
}

.styles_padHeading__24pTY {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__FabM7 {
  padding-top: 1rem;
}

.styles_padY__3B8bD, .styles_subtitle__1L0-I {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__mBa7f {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__3BN8N {
  margin-top: 1rem;
}

.styles_marginY__1KeK0 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__11C8D {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__2JOhp {
  margin: 0;
  padding: 0;
}

.styles_wrapper__2_Ecd {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__2_Ecd > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__2CwVX {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__2CwVX > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__2bmj7 {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__2bmj7 > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__2DGVr {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__2DGVr > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__3NPh9, .styles_subtitle__1L0-I {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__3NPh9 > *, .styles_subtitle__1L0-I > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__krDnN {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__krDnN > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__1SGb-,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__3P5UQ,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__1gEvQ,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__NNmfi {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__1zY1x, .styles_container__1ue48 {
    padding: 1rem;
  }
  .styles_hideMedium__3aXZn {
    display: none !important;
  }
  .styles_hideLarge__3j1IV {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__1zY1x, .styles_container__1ue48 {
    padding: 1rem;
  }
  .styles_subtitle__1L0-I {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__Di3IF {
    display: none !important;
  }
  .styles_rowResize__2W0Gk > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__2W0Gk > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__1zY1x, .styles_container__1ue48 {
    padding: 0.5rem;
  }
  .styles_padX__mBa7f {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__1eE9j {
    font-size: 1.2rem;
  }
  .styles_textSecondary__3j4BD {
    font-size: 1.02rem;
  }
  .styles_textBold__1DyjG {
    font-size: 1.2rem;
  }
  .styles_textHint__3eIZ0 {
    font-size: 0.84rem;
  }
  .styles_textLabel__htdZ0 {
    font-size: 1.2rem;
  }
  .styles_navLabel__3-cX2 {
    font-size: 0.84rem;
  }
  .styles_tagLabel__1o72p {
    font-size: 0.85rem;
  }
}
.styles_textTitle__1SGb- {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__1eXTa {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__2QQHz {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__3zBXl {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__15nbg {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__1GHPX {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__3iwuW {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__1DyjG {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__247IM {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__2fFM- {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__33nLR {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__1aYXz {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__37pqW {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__1ZPqN {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__3lK8J {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__2j3V6 {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__v2wqQ {
  border: 1px solid #E2164A;
}

.styles_container__1ue48 {
  text-align: center;
  width: 520px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 0;
  margin: auto;
  overflow: scroll;
}

.styles_inputArea__uzcoI {
  background: transparent;
  border-radius: 4px;
  width: 93%;
  margin-bottom: 11rem;
}

.styles_largeLogo__21lju {
  margin-bottom: 1rem;
}
.styles_largeLogo__21lju svg {
  width: 40vw;
  max-width: 200px;
  height: auto;
}

.styles_backgroundColorContainer__4z2sa {
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(1%, #e5b300), to(#ffdf00));
  background-image: -webkit-linear-gradient(bottom, #e5b300 1%, #ffdf00 100%);
  background-image: linear-gradient(0deg, #e5b300 1%, #ffdf00 100%);
  width: 100%;
  height: 40vh;
  position: fixed;
  bottom: 0;
  left: 0;
}
.styles_backgroundColorContainer__4z2sa:after {
  content: "";
  top: -59vw;
  left: 5vw;
  width: 90vw;
  height: 90vw;
  position: absolute;
  background: #fff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.styles_backButtonTopLeft__6Obox {
  position: absolute;
  top: 20px;
  left: 10px;
}

.styles_verifyCodeFormWrapper__3VuEO {
  display: flex;
}
.styles_verifyCodeFormWrapper__3VuEO .styles_backButton__pdj_u {
  flex-grow: 0;
  height: 2.75rem;
  margin-top: auto;
  margin-right: 0.5rem;
}
.styles_verifyCodeFormWrapper__3VuEO .styles_codeErrorBackButton__f6XwY {
  margin-bottom: 1.35rem;
}
.styles_verifyCodeFormWrapper__3VuEO .styles_verifyCodeFormInputWrapper__MnApU {
  flex-grow: 1;
  margin-bottom: 10px;
}

.styles_phoneNumberErrorMessage__GuA9I {
  justify-content: flex-start;
  display: flex;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #E2164A;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_getCodeButton__1gOWO {
  height: 60px;
  color: #1E9F76;
}

.styles_phoneNumberInput__1xJ-L {
  margin-bottom: 16px;
}

.styles_phoneNumberInputLabel__1jbx- {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 1rem;
}

.styles_enterCodeInstructions__FDePF {
  width: 60%;
  font-size: 1rem;
}

.styles_enterCodeInstructionsContainer__26MTT {
  display: flex;
  justify-content: center;
}

.styles_enterCodeContainer__2Maqm {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.styles_fiveDigitCodeLabel__3ld5O {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.styles_loginFormContainer__232h0 {
  margin-bottom: 3.5rem;
}
.styles_textTitle__1lsm4 {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__3Bynj {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__1H1H9 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__12g2j {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__2Xg5B {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__YDFgO {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__3f_qY {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__dhyZi {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__2w3oc {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__2K0BF {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__tI8ba {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__OwJNv {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__11ZWM {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__u1aFx {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__3ND9d {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__1C0vK {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__3MrVH {
  border: 1px solid #E2164A;
}

.styles_button__1oyiC {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.15rem;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  padding: 0 1rem;
  cursor: pointer;
  white-space: nowrap;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_button__1oyiC svg {
  height: 1rem;
  width: 1rem;
}
.styles_button__1oyiC svg path {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_button__1oyiC:not(:disabled):hover {
  box-shadow: 0 0 9px 0 rgba(181, 56, 222, 0.24);
}
.styles_button__1oyiC.styles_fullWidth__3_l_4 {
  width: 100%;
}
.styles_button__1oyiC:disabled {
  cursor: not-allowed;
  color: #ADADAD;
}
.styles_button__1oyiC:disabled svg * {
  fill: #ADADAD;
}
.styles_button__1oyiC:disabled[background],
.styles_button__1oyiC:disabled [background] {
  border-color: #EEEEEE;
  background-color: #EEEEEE;
}
.styles_button__1oyiC:disabled:not(:disabled):hover {
  color: #c7c7c7;
}
.styles_button__1oyiC:disabled:not(:disabled):hover svg * {
  fill: #c7c7c7;
}
.styles_button__1oyiC:disabled:not(:disabled):hover[background],
.styles_button__1oyiC:disabled:not(:disabled):hover [background] {
  border-color: white;
  background-color: white;
}
[data-whatintent=keyboard] .styles_button__1oyiC:disabled:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_button__1oyiC:disabled:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_button__1oyiC:disabled:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_button__1oyiC.styles_large__2pU2L {
  height: 3.3rem;
}
.styles_button__1oyiC.styles_large__2pU2L svg {
  width: 1.5rem;
  height: 1.5rem;
}
.styles_button__1oyiC.styles_large__2pU2L .styles_buttonIcon__xXRuK {
  padding-right: 0.5rem;
}
.styles_button__1oyiC.styles_large__2pU2L .styles_buttonLabel__3Oxy- {
  padding-top: 0;
  font-family: "Assistant", sans-serif;
  color: inherit;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_buttonIcon__xXRuK {
  display: flex;
  align-items: center;
  padding-right: 0.35rem;
}
.styles_buttonIcon__xXRuK.styles_right__ZGBhk {
  padding-right: 0;
  padding-left: 0.35rem;
}

.styles_buttonLabel__3Oxy- {
  padding-top: calc(1rem/5);
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: inherit;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_smallButton__3Y6FX {
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
}
.styles_smallButton__3Y6FX,
.styles_smallButton__3Y6FX [background] {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_smallButton__3Y6FX:disabled {
  cursor: not-allowed;
  color: #ADADAD;
}
.styles_smallButton__3Y6FX:disabled svg * {
  fill: #ADADAD;
}
.styles_smallButton__3Y6FX:disabled[background],
.styles_smallButton__3Y6FX:disabled [background] {
  border-color: #EEEEEE;
  background-color: #EEEEEE;
}
.styles_smallButton__3Y6FX:disabled:not(:disabled):hover {
  color: #c7c7c7;
}
.styles_smallButton__3Y6FX:disabled:not(:disabled):hover svg * {
  fill: #c7c7c7;
}
.styles_smallButton__3Y6FX:disabled:not(:disabled):hover[background],
.styles_smallButton__3Y6FX:disabled:not(:disabled):hover [background] {
  border-color: white;
  background-color: white;
}
[data-whatintent=keyboard] .styles_smallButton__3Y6FX:disabled:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_smallButton__3Y6FX:disabled:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_smallButton__3Y6FX:disabled:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_smallButtonIcon__2bhqB {
  width: 2.2rem;
  height: 2.2rem;
  border: 2px;
  border-style: solid;
  border-radius: 4px;
  border-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
.styles_smallButtonIcon__2bhqB svg {
  height: 100%;
  width: 100%;
  padding: 0.35rem;
}
.styles_smallButtonIcon__2bhqB svg path {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.styles_smallButtonLabel__36cwS {
  padding-left: 0.5rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: inherit;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_smallTextOnlyButtonLabel__3_lWs {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: inherit;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_default__2dRyC {
  color: #4A4A4A;
}
.styles_default__2dRyC svg * {
  fill: #4A4A4A;
}
.styles_default__2dRyC[background],
.styles_default__2dRyC [background] {
  border-color: #D8D8D8;
  background-color: #FFFFFF;
}
.styles_default__2dRyC:not(:disabled):hover {
  color: #646464;
}
.styles_default__2dRyC:not(:disabled):hover svg * {
  fill: #646464;
}
.styles_default__2dRyC:not(:disabled):hover[background],
.styles_default__2dRyC:not(:disabled):hover [background] {
  border-color: #f2f2f2;
  background-color: white;
}
[data-whatintent=keyboard] .styles_default__2dRyC:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_default__2dRyC:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_default__2dRyC:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_primary__70zYE {
  color: #FFFFFF;
}
.styles_primary__70zYE svg * {
  fill: #FFFFFF;
}
.styles_primary__70zYE[background],
.styles_primary__70zYE [background] {
  border-color: #1E9F76;
  background-color: #1E9F76;
}
.styles_primary__70zYE:not(:disabled):hover {
  color: white;
}
.styles_primary__70zYE:not(:disabled):hover svg * {
  fill: white;
}
.styles_primary__70zYE:not(:disabled):hover[background],
.styles_primary__70zYE:not(:disabled):hover [background] {
  border-color: #26ca96;
  background-color: #26ca96;
}
[data-whatintent=keyboard] .styles_primary__70zYE:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_primary__70zYE:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_primary__70zYE:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_neutral__UvFJa {
  color: #4A4A4A;
}
.styles_neutral__UvFJa svg * {
  fill: #106FEE;
}
.styles_neutral__UvFJa[background],
.styles_neutral__UvFJa [background] {
  border-color: #D8D8D8;
  background-color: #FFFFFF;
}
.styles_neutral__UvFJa:not(:disabled):hover {
  color: #646464;
}
.styles_neutral__UvFJa:not(:disabled):hover svg * {
  fill: #3f8cf2;
}
.styles_neutral__UvFJa:not(:disabled):hover[background],
.styles_neutral__UvFJa:not(:disabled):hover [background] {
  border-color: #f2f2f2;
  background-color: white;
}
[data-whatintent=keyboard] .styles_neutral__UvFJa:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_neutral__UvFJa:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_neutral__UvFJa:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_destructive__3i17o {
  color: #FFFFFF;
}
.styles_destructive__3i17o svg * {
  fill: #FFFFFF;
}
.styles_destructive__3i17o[background],
.styles_destructive__3i17o [background] {
  border-color: #E2164A;
  background-color: #E2164A;
}
.styles_destructive__3i17o:not(:disabled):hover {
  color: white;
}
.styles_destructive__3i17o:not(:disabled):hover svg * {
  fill: white;
}
.styles_destructive__3i17o:not(:disabled):hover[background],
.styles_destructive__3i17o:not(:disabled):hover [background] {
  border-color: #ec3f6b;
  background-color: #ec3f6b;
}
[data-whatintent=keyboard] .styles_destructive__3i17o:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_destructive__3i17o:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_destructive__3i17o:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_negative__3ZC6J {
  color: #4A4A4A;
}
.styles_negative__3ZC6J svg * {
  fill: #E2164A;
}
.styles_negative__3ZC6J[background],
.styles_negative__3ZC6J [background] {
  border-color: #D8D8D8;
  background-color: #FFFFFF;
}
.styles_negative__3ZC6J:not(:disabled):hover {
  color: #646464;
}
.styles_negative__3ZC6J:not(:disabled):hover svg * {
  fill: #ec3f6b;
}
.styles_negative__3ZC6J:not(:disabled):hover[background],
.styles_negative__3ZC6J:not(:disabled):hover [background] {
  border-color: #f2f2f2;
  background-color: white;
}
[data-whatintent=keyboard] .styles_negative__3ZC6J:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_negative__3ZC6J:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_negative__3ZC6J:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_secondary__DnaxG {
  color: #4A4A4A;
}
.styles_secondary__DnaxG svg * {
  fill: #106FEE;
}
.styles_secondary__DnaxG[background],
.styles_secondary__DnaxG [background] {
  border-color: #D8D8D8;
  background-color: #FFFFFF;
}
.styles_secondary__DnaxG:not(:disabled):hover {
  color: #646464;
}
.styles_secondary__DnaxG:not(:disabled):hover svg * {
  fill: #3f8cf2;
}
.styles_secondary__DnaxG:not(:disabled):hover[background],
.styles_secondary__DnaxG:not(:disabled):hover [background] {
  border-color: #f2f2f2;
  background-color: white;
}
[data-whatintent=keyboard] .styles_secondary__DnaxG:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_secondary__DnaxG:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_secondary__DnaxG:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_attachmentButton__n2o8u {
  color: #106FEE;
  padding: 0;
}
.styles_attachmentButton__n2o8u svg * {
  fill: #106FEE;
}
.styles_attachmentButton__n2o8u[background],
.styles_attachmentButton__n2o8u [background] {
  border-color: #FFFFFF;
  background-color: #FFFFFF;
}
.styles_attachmentButton__n2o8u:not(:disabled):hover {
  color: #3f8cf2;
}
.styles_attachmentButton__n2o8u:not(:disabled):hover svg * {
  fill: #3f8cf2;
}
.styles_attachmentButton__n2o8u:not(:disabled):hover[background],
.styles_attachmentButton__n2o8u:not(:disabled):hover [background] {
  border-color: white;
  background-color: white;
}
[data-whatintent=keyboard] .styles_attachmentButton__n2o8u:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_attachmentButton__n2o8u:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_attachmentButton__n2o8u:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_selected__3olmz {
  color: #4A4A4A;
}
.styles_selected__3olmz svg * {
  fill: #B538DE;
}
.styles_selected__3olmz[background],
.styles_selected__3olmz [background] {
  border-color: #9B9B9B;
  background-color: #FFFFFF;
}
.styles_selected__3olmz:not(:disabled):hover {
  color: #646464;
}
.styles_selected__3olmz:not(:disabled):hover svg * {
  fill: #c564e5;
}
.styles_selected__3olmz:not(:disabled):hover[background],
.styles_selected__3olmz:not(:disabled):hover [background] {
  border-color: #b5b5b5;
  background-color: white;
}
[data-whatintent=keyboard] .styles_selected__3olmz:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_selected__3olmz:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_selected__3olmz:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_minimal__3-O89 {
  cursor: pointer;
  color: #4A4A4A;
}
.styles_minimal__3-O89 svg * {
  fill: #106FEE;
}
.styles_minimal__3-O89[background],
.styles_minimal__3-O89 [background] {
  border-color: #9B9B9B;
  background-color: #FFFFFF;
}
.styles_minimal__3-O89:not(:disabled):hover {
  color: #646464;
}
.styles_minimal__3-O89:not(:disabled):hover svg * {
  fill: #3f8cf2;
}
.styles_minimal__3-O89:not(:disabled):hover[background],
.styles_minimal__3-O89:not(:disabled):hover [background] {
  border-color: #b5b5b5;
  background-color: white;
}
[data-whatintent=keyboard] .styles_minimal__3-O89:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_minimal__3-O89:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_minimal__3-O89:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_backButtonTransparentContainer__3LG6D {
  border: 0;
  min-width: 60px;
}

.styles_backButtonTransparentButtonElement__3GEt5 {
  color: #4A4A4A;
  border: 0;
}
.styles_backButtonTransparentButtonElement__3GEt5 svg * {
  fill: #106FEE;
}
.styles_backButtonTransparentButtonElement__3GEt5[background],
.styles_backButtonTransparentButtonElement__3GEt5 [background] {
  border-color: #9B9B9B;
  background-color: #106FEE;
}
.styles_backButtonTransparentButtonElement__3GEt5:not(:disabled):hover {
  color: #646464;
}
.styles_backButtonTransparentButtonElement__3GEt5:not(:disabled):hover svg * {
  fill: #3f8cf2;
}
.styles_backButtonTransparentButtonElement__3GEt5:not(:disabled):hover[background],
.styles_backButtonTransparentButtonElement__3GEt5:not(:disabled):hover [background] {
  border-color: #b5b5b5;
  background-color: #3f8cf2;
}
[data-whatintent=keyboard] .styles_backButtonTransparentButtonElement__3GEt5:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_backButtonTransparentButtonElement__3GEt5:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_backButtonTransparentButtonElement__3GEt5:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_backButtonTransparentLabelText__1PnAv {
  color: #106FEE;
  font-size: 1rem;
  font-weight: 400;
  padding-left: 0;
}

.styles_backButtonTransparentLeftArrow__4uHEN {
  padding-right: 0;
}

.styles_textOnlyButtonElement__3SzUx {
  border: 0;
  color: #106FEE;
  font-size: 1rem;
}
.styles_textOnlyButtonElement__3SzUx svg * {
  fill: #106FEE;
}
.styles_textOnlyButtonElement__3SzUx[background],
.styles_textOnlyButtonElement__3SzUx [background] {
  border-color: #9B9B9B;
  background-color: #106FEE;
}
.styles_textOnlyButtonElement__3SzUx:not(:disabled):hover {
  color: #3f8cf2;
}
.styles_textOnlyButtonElement__3SzUx:not(:disabled):hover svg * {
  fill: #3f8cf2;
}
.styles_textOnlyButtonElement__3SzUx:not(:disabled):hover[background],
.styles_textOnlyButtonElement__3SzUx:not(:disabled):hover [background] {
  border-color: #b5b5b5;
  background-color: #3f8cf2;
}
[data-whatintent=keyboard] .styles_textOnlyButtonElement__3SzUx:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_textOnlyButtonElement__3SzUx:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_textOnlyButtonElement__3SzUx:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_textTitle__nC-P0 {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__kYimZ {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__1VSSF {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__2blgI {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__4rQMP {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__1Shuj {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__3LUTI {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__1xp3u {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__rjp1s {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__2Q3S8 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__3AHXA {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__MGOVO {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__S0aA_ {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__64jwR {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__1p4hT {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__1Wxi1 {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__vE2gB {
  border: 1px solid #E2164A;
}

.styles_button__1Vk-Q:not(:disabled):hover {
  box-shadow: 0 0 9px 0 rgba(181, 56, 222, 0.24);
}
.styles_button__1Vk-Q:disabled {
  cursor: not-allowed;
  color: #9B9B9B;
}
.styles_button__1Vk-Q:disabled svg * {
  fill: #9B9B9B;
}
.styles_button__1Vk-Q:disabled[background],
.styles_button__1Vk-Q:disabled [background] {
  border-color: #EEEEEE;
  background-color: #EEEEEE;
}
.styles_button__1Vk-Q:disabled:not(:disabled):hover {
  color: #b5b5b5;
}
.styles_button__1Vk-Q:disabled:not(:disabled):hover svg * {
  fill: #b5b5b5;
}
.styles_button__1Vk-Q:disabled:not(:disabled):hover[background],
.styles_button__1Vk-Q:disabled:not(:disabled):hover [background] {
  border-color: white;
  background-color: white;
}
[data-whatintent=keyboard] .styles_button__1Vk-Q:disabled:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_button__1Vk-Q:disabled:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_button__1Vk-Q:disabled:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_outerCircleParent__2KYMe {
  background: #FFFFFF;
  border-color: #9B9B9B;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  border: 1.6px;
  color: #4A4A4A;
}
.styles_outerCircleParent__2KYMe svg * {
  fill: #B538DE;
}
.styles_outerCircleParent__2KYMe[background],
.styles_outerCircleParent__2KYMe [background] {
  border-color: #9B9B9B;
  background-color: #FFFFFF;
}
.styles_outerCircleParent__2KYMe:not(:disabled):hover {
  color: #646464;
}
.styles_outerCircleParent__2KYMe:not(:disabled):hover svg * {
  fill: #c564e5;
}
.styles_outerCircleParent__2KYMe:not(:disabled):hover[background],
.styles_outerCircleParent__2KYMe:not(:disabled):hover [background] {
  border-color: #b5b5b5;
  background-color: white;
}
[data-whatintent=keyboard] .styles_outerCircleParent__2KYMe:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_outerCircleParent__2KYMe:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_outerCircleParent__2KYMe:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_iconStyle__1mJoN {
  position: absolute;
  top: 18px;
  right: 20px;
}
.styles_iconStyle__1mJoN svg * {
  fill: #000000;
}

.styles_relativeParent__DSFit {
  position: relative;
}

.styles_textStyle__Z8Myi {
  position: absolute;
  top: 68px;
  right: 1px;
}
.styles_textCommon__3eVIb, .styles_textBold__11G-O, .styles_textSecondary__18Lxp, .styles_textDefault__Wl7xk {
  line-height: 1.4em;
}

.styles_textDefault__Wl7xk {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__18Lxp {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__11G-O {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__1tuyn {
  font-weight: 700;
}

.styles_alignRight__2LSXy {
  text-align: right;
}

.styles_alignCenter__1F3vP {
  text-align: center;
}

.styles_tagLabel__2K7f3 {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__YD01B {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__zKoME {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__3QbEu {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__3NT_A,
.styles_textHeading__3wNK_,
.styles_textSubheading__1KCuF {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__6xjFN {
  color: #9b9b9b;
}

.styles_container__34bO9 {
  position: relative;
}

.styles_padHeading__1MEYT {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__1IAcg {
  padding-top: 1rem;
}

.styles_padY__1ZW9m, .styles_subtitle__3Wl16 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__2KS6b {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__k3Svp {
  margin-top: 1rem;
}

.styles_marginY__2Zyj4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__2AWHo {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__1FiFL {
  margin: 0;
  padding: 0;
}

.styles_wrapper__R9Ij7 {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__R9Ij7 > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__2zy8E {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__2zy8E > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__1Vcoy {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__1Vcoy > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__1D9e0 {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__1D9e0 > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__zJo6L, .styles_subtitle__3Wl16 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__zJo6L > *, .styles_subtitle__3Wl16 > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__KQGoT {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__KQGoT > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__3NT_A,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__3wNK_,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__1KCuF,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__3l3Wk {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__jGOzx {
    padding: 1rem;
  }
  .styles_hideMedium__2fHpr {
    display: none !important;
  }
  .styles_hideLarge__33cG_ {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__jGOzx {
    padding: 1rem;
  }
  .styles_subtitle__3Wl16 {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__2xMp1 {
    display: none !important;
  }
  .styles_rowResize__3a6UN > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__3a6UN > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__jGOzx {
    padding: 0.5rem;
  }
  .styles_padX__2KS6b {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__Wl7xk {
    font-size: 1.2rem;
  }
  .styles_textSecondary__18Lxp {
    font-size: 1.02rem;
  }
  .styles_textBold__11G-O {
    font-size: 1.2rem;
  }
  .styles_textHint__YD01B {
    font-size: 0.84rem;
  }
  .styles_textLabel__zKoME {
    font-size: 1.2rem;
  }
  .styles_navLabel__3QbEu {
    font-size: 0.84rem;
  }
  .styles_tagLabel__2K7f3 {
    font-size: 0.85rem;
  }
}
.styles_badgeWrap__34PJc {
  display: flex;
  align-items: center;
}

.styles_badgeLabel__vvNSp {
  margin-left: 0.5rem;
  color: #4D4D4D;
  font-size: 0.85rem;
  line-height: 1em;
}

.styles_badgeCount__1GAFM {
  display: inline-flex;
  flex-shrink: 0;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background: #B538DE;
  color: #fff;
  border: 0 solid #fff;
}
.styles_badgeCount__1GAFM .styles_countLabel__1aBQs {
  font-size: 0.7rem;
  font-weight: 500;
  font-family: "Assistant", sans-serif;
  line-height: 1;
  color: inherit;
  position: relative;
}

.styles_badgeAlert__f7TSo {
  display: inline-flex;
  flex-shrink: 0;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background: #E2164A;
  color: #fff;
  border: 0 solid #fff;
}
.styles_badgeAlert__f7TSo .styles_countLabel__1aBQs {
  font-size: 0.7rem;
  font-weight: 500;
  font-family: "Assistant", sans-serif;
  line-height: 1;
  color: inherit;
  position: relative;
}

.styles_badgeNeutral__2s-uw {
  display: inline-flex;
  flex-shrink: 0;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background: #9b9b9b;
  color: #fff;
  border: 0 solid #fff;
}
.styles_badgeNeutral__2s-uw .styles_countLabel__1aBQs {
  font-size: 0.7rem;
  font-weight: 500;
  font-family: "Assistant", sans-serif;
  line-height: 1;
  color: inherit;
  position: relative;
}

.styles_badgeOnline__3SNmH {
  display: inline-flex;
  flex-shrink: 0;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background: #1E9F76;
  color: #fff;
  border: 0 solid #fff;
}
.styles_badgeOnline__3SNmH .styles_countLabel__1aBQs {
  font-size: 0.7rem;
  font-weight: 500;
  font-family: "Assistant", sans-serif;
  line-height: 1;
  color: inherit;
  position: relative;
}

.styles_badgeIdle__uTEBY {
  display: inline-flex;
  flex-shrink: 0;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background: #F6CC46;
  color: #fff;
  border: 0 solid #fff;
}
.styles_badgeIdle__uTEBY .styles_countLabel__1aBQs {
  font-size: 0.7rem;
  font-weight: 500;
  font-family: "Assistant", sans-serif;
  line-height: 1;
  color: inherit;
  position: relative;
}

.styles_badgeDisconnected__3C2T3 {
  display: inline-flex;
  flex-shrink: 0;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background: #4D4D4D;
  color: #fff;
  border: 0 solid #fff;
}
.styles_badgeDisconnected__3C2T3 .styles_countLabel__1aBQs {
  font-size: 0.7rem;
  font-weight: 500;
  font-family: "Assistant", sans-serif;
  line-height: 1;
  color: inherit;
  position: relative;
}
.styles_textCommon__1Oezj, .styles_textBold__36DML, .styles_textSecondary__m8HQ8, .styles_textDefault__3srVO, .styles_emptyStateInactive__1Wa-- .styles_emptySmallText__1Tc3n, .styles_emptyStateActive__2flUp .styles_emptySmallText__1Tc3n {
  line-height: 1.4em;
}

.styles_textDefault__3srVO, .styles_emptyStateInactive__1Wa-- .styles_emptySmallText__1Tc3n, .styles_emptyStateActive__2flUp .styles_emptySmallText__1Tc3n {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__m8HQ8 {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__36DML {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__Zegxc {
  font-weight: 700;
}

.styles_alignRight__ZU3_x {
  text-align: right;
}

.styles_alignCenter__Ga75U {
  text-align: center;
}

.styles_tagLabel__utXC3 {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__NDxZO {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__3-2Un {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__3XhRm {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__PYI-8,
.styles_textHeading__1cUYH,
.styles_textSubheading__2NJ4_,
.styles_emptyStateInactive__1Wa-- .styles_emptyBigText__3thss,
.styles_emptyStateActive__2flUp .styles_emptyBigText__3thss {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__hadAO {
  color: #9b9b9b;
}

.styles_container__3amDT {
  position: relative;
}

.styles_padHeading__Ow93X {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__38ow8 {
  padding-top: 1rem;
}

.styles_padY__36SHy, .styles_subtitle__2RzQ- {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__2_lIO {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__1vyuP {
  margin-top: 1rem;
}

.styles_marginY__33BAh {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__2iWiZ {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__128BN {
  margin: 0;
  padding: 0;
}

.styles_wrapper__1CTzX {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__1CTzX > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__u9fDO {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__u9fDO > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__1gONc {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__1gONc > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__ycaU_ {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__ycaU_ > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__nJqQU, .styles_subtitle__2RzQ- {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__nJqQU > *, .styles_subtitle__2RzQ- > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__H5Bp6 {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__H5Bp6 > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__PYI-8,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__1cUYH,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__2NJ4_, .styles_emptyStateInactive__1Wa-- .styles_emptyBigText__3thss, .styles_emptyStateActive__2flUp .styles_emptyBigText__3thss,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__33IIL {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__2jcif {
    padding: 1rem;
  }
  .styles_hideMedium__1W5y5 {
    display: none !important;
  }
  .styles_hideLarge__1lUME {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__2jcif {
    padding: 1rem;
  }
  .styles_subtitle__2RzQ- {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__2AJmy {
    display: none !important;
  }
  .styles_rowResize__KUxsK > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__KUxsK > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__2jcif {
    padding: 0.5rem;
  }
  .styles_padX__2_lIO {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__3srVO, .styles_emptyStateInactive__1Wa-- .styles_emptySmallText__1Tc3n, .styles_emptyStateActive__2flUp .styles_emptySmallText__1Tc3n {
    font-size: 1.2rem;
  }
  .styles_textSecondary__m8HQ8 {
    font-size: 1.02rem;
  }
  .styles_textBold__36DML {
    font-size: 1.2rem;
  }
  .styles_textHint__NDxZO {
    font-size: 0.84rem;
  }
  .styles_textLabel__3-2Un {
    font-size: 1.2rem;
  }
  .styles_navLabel__3XhRm {
    font-size: 0.84rem;
  }
  .styles_tagLabel__utXC3 {
    font-size: 0.85rem;
  }
}
.styles_emptyStateActive__2flUp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  background: rgba(16, 111, 238, 0.1);
}
.styles_emptyStateActive__2flUp .styles_emptyIcon__1SKX- {
  margin: 0 auto 0.5rem auto;
}
.styles_emptyStateActive__2flUp .styles_emptyIcon__1SKX- svg {
  width: 2.5rem;
  height: 2.5rem;
}
.styles_emptyStateActive__2flUp .styles_emptyIcon__1SKX- svg * {
  fill: #106FEE;
}
.styles_emptyStateActive__2flUp .styles_emptyBigText__3thss {
  font-weight: 700;
  color: #106FEE;
  line-height: 1;
  margin-bottom: 0.5rem;
  text-align: center;
}
.styles_emptyStateActive__2flUp .styles_emptySmallText__1Tc3n {
  color: #106FEE;
  text-align: center;
  line-height: 1.2em;
}
.styles_emptyStateActive__2flUp .styles_emptyButton__3vWy4 {
  margin: 0.5rem auto;
}

.styles_emptyStateInactive__1Wa-- {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  background: rgba(155, 155, 155, 0.1);
}
.styles_emptyStateInactive__1Wa-- .styles_emptyIcon__1SKX- {
  margin: 0 auto 0.5rem auto;
}
.styles_emptyStateInactive__1Wa-- .styles_emptyIcon__1SKX- svg {
  width: 2.5rem;
  height: 2.5rem;
}
.styles_emptyStateInactive__1Wa-- .styles_emptyIcon__1SKX- svg * {
  fill: #9b9b9b;
}
.styles_emptyStateInactive__1Wa-- .styles_emptyBigText__3thss {
  font-weight: 700;
  color: #9b9b9b;
  line-height: 1;
  margin-bottom: 0.5rem;
  text-align: center;
}
.styles_emptyStateInactive__1Wa-- .styles_emptySmallText__1Tc3n {
  color: #9b9b9b;
  text-align: center;
  line-height: 1.2em;
}
.styles_emptyStateInactive__1Wa-- .styles_emptyButton__3vWy4 {
  margin: 0.5rem auto;
}
.styles_textTitle__3aju- {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__1t0Fa {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__2R_kn {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__3jL0R {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__38xM9 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__1BwwR {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__fYxZR {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__3tWcl {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__1tmwr {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__3pPYL {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__2BAlP {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__3qSMM {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__3AvAN {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__1jLbJ {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__2RsdD {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__1oFyS {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__1oO_g {
  border: 1px solid #E2164A;
}

.styles_intercomButton__v9xD4 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.styles_intercomButton__v9xD4 svg {
  width: 1.3rem;
  height: 1.3rem;
}
.styles_intercomButton__v9xD4 svg * {
  fill: #9B9B9B;
}
.styles_intercomButton__v9xD4.styles_open__3v5pW svg * {
  fill: #B538DE;
}

.styles_intercomIcon__3u4US {
  position: absolute;
  top: 0;
  right: 0.5rem;
  background-color: #000000;
  border-radius: 100%;
  height: 1rem;
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  font-family: "Assistant", sans-serif;
  color: #FFFFFF;
  font-size: 0.85rem;
  font-weight: 900;
}
.styles_intercomIcon__3u4US.styles_notificationCount__22M5q {
  background-color: #E2164A;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #FFFFFF;
  font-size: 0.85rem;
  font-weight: 400;
}
.styles_intercomIcon__3u4US.styles_mobile__1cebo {
  top: 0.5rem;
}
.styles_textCommon__3EE2-, .styles_textBold__3g5nX, .styles_textSecondary__1EYLQ, .styles_textDefault__d_psB {
  line-height: 1.4em;
}

.styles_textDefault__d_psB {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__1EYLQ {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__3g5nX {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__1ohak {
  font-weight: 700;
}

.styles_alignRight__1R4UJ {
  text-align: right;
}

.styles_alignCenter__39pUV {
  text-align: center;
}

.styles_tagLabel__4PCTL {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__1DYR1 {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__3CmSI {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__1ws-a {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__zFO65,
.styles_textHeading__26IuI,
.styles_textSubheading__3Jgpk {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__1NVKh {
  color: #9b9b9b;
}

.styles_container__3OS7j {
  position: relative;
}

.styles_padHeading__y7loJ {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__sA2sN {
  padding-top: 1rem;
}

.styles_padY__3cJol, .styles_subtitle__2vJLv {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__8k3Tf {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__14nod {
  margin-top: 1rem;
}

.styles_marginY__XFPpq {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__1o-Ge {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__2GRg8 {
  margin: 0;
  padding: 0;
}

.styles_wrapper__1m_fw {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__1m_fw > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__3YSpB {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__3YSpB > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__jERWc {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__jERWc > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__3j1Cg {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__3j1Cg > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__18vZK, .styles_subtitle__2vJLv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__18vZK > *, .styles_subtitle__2vJLv > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__2E3xi {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__2E3xi > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__zFO65,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__26IuI,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__3Jgpk,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__uyREo {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__12lVc {
    padding: 1rem;
  }
  .styles_hideMedium__hjRtu {
    display: none !important;
  }
  .styles_hideLarge__1dmb6 {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__12lVc {
    padding: 1rem;
  }
  .styles_subtitle__2vJLv {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__2R7Pk {
    display: none !important;
  }
  .styles_rowResize__1wBJG > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__1wBJG > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__12lVc {
    padding: 0.5rem;
  }
  .styles_padX__8k3Tf {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__d_psB {
    font-size: 1.2rem;
  }
  .styles_textSecondary__1EYLQ {
    font-size: 1.02rem;
  }
  .styles_textBold__3g5nX {
    font-size: 1.2rem;
  }
  .styles_textHint__1DYR1 {
    font-size: 0.84rem;
  }
  .styles_textLabel__3CmSI {
    font-size: 1.2rem;
  }
  .styles_navLabel__1ws-a {
    font-size: 0.84rem;
  }
  .styles_tagLabel__4PCTL {
    font-size: 0.85rem;
  }
}
.styles_pullToRefreshWrapper__36kyI .ptr--text {
  color: #9b9b9b;
  font-size: 0.85rem;
  font-weight: 300;
}
.styles_textTitle__3F1V_ {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__3GDzo {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__2ohhZ {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__10NcK {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__3Vtg8 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__9BVO0 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__nS7Z9 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__ndlqc {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__VPy7G {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__3lUet {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__XwVY_ {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__3iZGb {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__1zZ9y {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__EB9oF {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__3nBQl {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__2MEmH {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__3tHmD {
  border: 1px solid #E2164A;
}

.styles_contextSwitcherContainer__3NFA_ {
  cursor: pointer;
  display: inline-flex;
  padding: 4px 2px;
  background-color: #EEEEEE;
  border-radius: 0.35rem;
  width: 100%;
  justify-content: center;
}

.styles_unselectedItem__2LMpt {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.15rem;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  padding: 0 1rem;
  cursor: pointer;
  white-space: nowrap;
  margin: 0 2px;
  color: #4A4A4A;
  border-color: #EEEEEE;
  background-color: #EEEEEE;
  box-shadow: 0 0 0 0;
  width: 100%;
}
.styles_unselectedItem__2LMpt svg * {
  fill: #4A4A4A;
}
.styles_unselectedItem__2LMpt:hover {
  background-color: #E0E0E0;
  border-color: #E0E0E0;
}
.styles_unselectedItem__2LMpt.styles_selectedItem__3P5kv {
  color: #000000;
  border-color: #FFFFFF;
  background-color: #FFFFFF;
}
.styles_unselectedItem__2LMpt.styles_selectedItem__3P5kv svg * {
  fill: #000000;
}
.styles_unselectedItem__2LMpt.styles_selectedItem__3P5kv:hover svg * {
  fill: #000000;
}

.styles_operatorStyle__2vYqb {
  flex-direction: column;
  height: 3.5rem;
  padding: 1rem 0;
  padding-bottom: 0.5rem;
}

.styles_buttonIcon__1-zdu {
  display: flex;
  align-items: center;
  padding-right: 0.35rem;
}

.styles_buttonIconOperatorStyle__38aUO {
  display: flex;
  align-items: center;
}

.styles_buttonLabel__2bmcN {
  padding-top: calc(1rem/5);
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: inherit;
  font-size: 0.9rem;
  font-weight: 400;
}
.styles_textTitle__2LJmw {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__2lhOy {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__fjImD {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__2ZSK6 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__RaUj0 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__1fP4R {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__3BGE5 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__1Uu2O {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__38MYF {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__oILXG {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__3sj42 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__4xBtX {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__2r_lA {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__1sysV {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__2hTrE {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__1Am9- {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__1yfrq {
  border: 1px solid #E2164A;
}

.styles_topNavBar__4eINU {
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 0.5rem;
  padding: 1rem;
  align-items: center;
}

.styles_settingsLink__1-4kH {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 0.5rem;
}
.styles_settingsLink__1-4kH svg {
  width: 1.7rem;
  height: 1.7rem;
}
.styles_settingsLink__1-4kH svg * {
  fill: #9B9B9B;
}

.styles_jobsIconContainer__xvmdC {
  position: relative;
  display: flex;
  align-items: center;
}

.styles_pendingAssignmentsBadge__k-aQ5 {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

[name=intercom-notifications-frame] {
  display: none !important;
}
.styles_textTitle__W86Gs {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__z0ybC {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__16Ogo {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__37c-h {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__27GzI {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__1PY7I {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__b5NKo {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__bI3Uq {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__3b3Ep {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__3eXuj {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__2pREx {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__2mqpL {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__3acQf {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__2YBwX {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__sSjxX {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__BGoPS {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__3kclw {
  border: 1px solid #E2164A;
}

.styles_tag__1ETG2 {
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: calc(0.35rem / 2);
  align-items: center;
  padding: 0.1rem 0.75rem;
  border-radius: 24px;
  line-height: 1rem;
}

.styles_defaultTag__2yEoi {
  color: #4A4A4A;
  background-color: #EEEEEE;
  border: 1px solid none;
}
.styles_defaultTag__2yEoi svg * {
  fill: #4A4A4A;
}

.styles_tagText__1CABh {
  padding-top: calc(0.35rem/2 + 1rem/10);
  padding-bottom: calc(0.35rem/2);
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: inherit;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_tagIcon__2Qo4N {
  display: flex;
  align-items: center;
}
.styles_tagIcon__2Qo4N svg {
  height: 1rem;
  width: 1rem;
}

.styles_count__2RhG8 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 100%;
  padding-top: 0.16em;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #FFFFFF;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_countAlert__hFvd1 {
  background-color: #E2164A;
  color: #FFFFFF;
}
.styles_countAlert__hFvd1 svg * {
  fill: #FFFFFF;
}

.styles_pill__3asOd {
  display: grid;
}

.styles_pillCount__3-41V {
  grid-row: 1;
  grid-column: 1;
  z-index: 1;
}

.styles_pillTag__10Cs8 {
  grid-row: 1;
  grid-column: 1;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
}

.styles_pillSpacer__W2lyX {
  width: 0.75rem;
}
.styles_textTitle__tH3zl {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__3FzOF {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__3xejx {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__2amD8 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__3-0Sz {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__LD0_H {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__1t6VA {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__2XaXD {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__29TTh {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__6ynEI {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__OopwE {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__2n4iL {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__1aAfG {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__1osqL {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__3m01S {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__1v21Z {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__3qTvG {
  border: 1px solid #E2164A;
}

.styles_logging__Sk6CI {
  color: #000000;
  background-color: #F6CC46;
  border: 1px solid none;
}
.styles_logging__Sk6CI svg * {
  fill: #000000;
}

.styles_paused__2E-dv {
  color: #000000;
  background-color: #F6CC46;
  border: 1px solid none;
}
.styles_paused__2E-dv svg * {
  fill: #000000;
}

.styles_done__1htzo {
  color: #4A4A4A;
  background-color: #EEEEEE;
  border: 1px solid none;
}
.styles_done__1htzo svg * {
  fill: #1E9F76;
}

.styles_new__2zYfM {
  color: #FFFFFF;
  background-color: #E2164A;
  border: 1px solid none;
}
.styles_new__2zYfM svg * {
  fill: #FFFFFF;
}

.styles_next__jJ0NE {
  color: #FFFFFF;
  background-color: #1E9F76;
  border: 1px solid none;
}
.styles_next__jJ0NE svg * {
  fill: #FFFFFF;
}
.styles_textTitle__19itK {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__3Ck5Q {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__3NLTQ {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__3Ee0Z {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__2hvAF {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__3MCB0 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__3MLnB {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__2sklu {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__2Srj4 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__2Hhp5 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__1i-Mj {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__1JoSc {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__3kZO0 {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__3UnFY {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__kvBYq {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__1Vkr2 {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__R2lzL {
  border: 1px solid #E2164A;
}

.styles_jobsHeader__2omoE {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  padding: 1rem 0;
}
.styles_jobsHeader__2omoE .styles_jobsHeaderInfo__J_6ts {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr;
  padding-right: 1rem;
}
.styles_jobsHeader__2omoE .styles_jobsHeaderInfo__J_6ts .styles_assignmentTags__3HNcU {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.styles_jobsHeader__2omoE .styles_scheduleInfo__2fgKh {
  display: flex;
  align-items: center;
}
.styles_jobsHeader__2omoE .styles_scheduleInfo__2fgKh > * {
  margin-right: 0.35rem;
}
.styles_jobsHeader__2omoE .styles_clientName__axq5d {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_timeTag__2ESM4 {
  color: #4A4A4A;
  background-color: #FFFFFF;
  border: 1px solid #9B9B9B;
}
.styles_timeTag__2ESM4 svg * {
  fill: #FFFFFF;
}

.styles_dateDisplay__2SN4o {
  display: inline-flex;
  flex-direction: column;
  border-right: 1px solid #EEEEEE;
  padding: 0.35rem 1rem;
  text-align: center;
  text-transform: uppercase;
}
.styles_dateDisplay__2SN4o .styles_dateMonth__2L30g {
  letter-spacing: calc(0.35rem/2);
  margin-right: calc(-0.35rem/2);
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.7rem;
  font-weight: 600;
}
.styles_dateDisplay__2SN4o .styles_dateNumber__2Mr2K {
  line-height: 1rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1.3rem;
  font-weight: 400;
}
.styles_dateDisplay__2SN4o .styles_dateWeekday__rVf4a {
  letter-spacing: calc(0.35rem/2);
  margin-right: calc(-0.35rem/2);
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.7rem;
  font-weight: 600;
}

.styles_autoProject__3Cq0p {
  margin-left: 1rem;
}
.styles_textCommon__3J41W, .styles_textBold__3FzQH, .styles_textSecondary__gh_zo, .styles_textDefault__2Lm0c {
  line-height: 1.4em;
}

.styles_textDefault__2Lm0c {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__gh_zo {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__3FzQH {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__3cfx- {
  font-weight: 700;
}

.styles_alignRight__UfL1_ {
  text-align: right;
}

.styles_alignCenter__sIzK2 {
  text-align: center;
}

.styles_tagLabel__3QONt {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__arfR8 {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__2KPbV {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__2YpDf {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__3_ysR,
.styles_textHeading__3g_U3,
.styles_textSubheading__3I6o7 {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__2aDzS {
  color: #9b9b9b;
}

.styles_container__1KCy6 {
  position: relative;
}

.styles_padHeading__1PJv5 {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__1f7Ej {
  padding-top: 1rem;
}

.styles_padY__sVG9B, .styles_subtitle__2bwQP {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__XlawC {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__2nMQC {
  margin-top: 1rem;
}

.styles_marginY__2MKPo {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__3RMBe {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__N-czm {
  margin: 0;
  padding: 0;
}

.styles_wrapper__nU3Ku {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__nU3Ku > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__2iAml {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__2iAml > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__2P2B4 {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__2P2B4 > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__322jS {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__322jS > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__3ZVvF, .styles_subtitle__2bwQP {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__3ZVvF > *, .styles_subtitle__2bwQP > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__21ljQ {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__21ljQ > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__3_ysR,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__3g_U3,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__3I6o7,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__mbvuO {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__1wOuR {
    padding: 1rem;
  }
  .styles_hideMedium__1J5GB {
    display: none !important;
  }
  .styles_hideLarge__-2_ta {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__1wOuR {
    padding: 1rem;
  }
  .styles_subtitle__2bwQP {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__LWKBv {
    display: none !important;
  }
  .styles_rowResize__tOW7R > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__tOW7R > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__1wOuR {
    padding: 0.5rem;
  }
  .styles_padX__XlawC {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__2Lm0c {
    font-size: 1.2rem;
  }
  .styles_textSecondary__gh_zo {
    font-size: 1.02rem;
  }
  .styles_textBold__3FzQH {
    font-size: 1.2rem;
  }
  .styles_textHint__arfR8 {
    font-size: 0.84rem;
  }
  .styles_textLabel__2KPbV {
    font-size: 1.2rem;
  }
  .styles_navLabel__2YpDf {
    font-size: 0.84rem;
  }
  .styles_tagLabel__3QONt {
    font-size: 0.85rem;
  }
}
.styles_assignmentTags__2ePlj {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.styles_textCommon__322uW, .styles_textBold__3ob4K, .styles_textSecondary__2Id04, .styles_textDefault__3HDSN {
  line-height: 1.4em;
}

.styles_textDefault__3HDSN {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__2Id04 {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__3ob4K {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__1niWB {
  font-weight: 700;
}

.styles_alignRight__2f7tm {
  text-align: right;
}

.styles_alignCenter__2WMVT {
  text-align: center;
}

.styles_tagLabel__KC3yi {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__27dSg {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__2Tm5w {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__2Akna {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__2JyJp,
.styles_textHeading__3ZiXe,
.styles_textSubheading__2AEvd {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__NTEPp {
  color: #9b9b9b;
}

.styles_container__37NGG {
  position: relative;
}

.styles_padHeading__qggRa {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__1OE81 {
  padding-top: 1rem;
}

.styles_padY__3nKk1, .styles_subtitle__2j_ff {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__3uJsv {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__23ete {
  margin-top: 1rem;
}

.styles_marginY__2ZfqU {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__1e6dK {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__1jnJr {
  margin: 0;
  padding: 0;
}

.styles_wrapper__2-Poo {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__2-Poo > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__j_lWJ {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__j_lWJ > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__vpyQQ {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__vpyQQ > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__6BooW {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__6BooW > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__2SAjX, .styles_subtitle__2j_ff {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__2SAjX > *, .styles_subtitle__2j_ff > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__39Xcj {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__39Xcj > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__2JyJp,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__3ZiXe,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__2AEvd,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__3836Q {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__NgQ8A {
    padding: 1rem;
  }
  .styles_hideMedium__1cmTp {
    display: none !important;
  }
  .styles_hideLarge__2mmMx {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__NgQ8A {
    padding: 1rem;
  }
  .styles_subtitle__2j_ff {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__2EwRP {
    display: none !important;
  }
  .styles_rowResize__LIKHQ > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__LIKHQ > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__NgQ8A {
    padding: 0.5rem;
  }
  .styles_padX__3uJsv {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__3HDSN {
    font-size: 1.2rem;
  }
  .styles_textSecondary__2Id04 {
    font-size: 1.02rem;
  }
  .styles_textBold__3ob4K {
    font-size: 1.2rem;
  }
  .styles_textHint__27dSg {
    font-size: 0.84rem;
  }
  .styles_textLabel__2Tm5w {
    font-size: 1.2rem;
  }
  .styles_navLabel__2Akna {
    font-size: 0.84rem;
  }
  .styles_tagLabel__KC3yi {
    font-size: 0.85rem;
  }
}
.styles_assignmentCard__2ioak {
  margin: 0.5rem 0;
  background-color: #fff;
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  position: relative;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_assignmentCard__2ioak.styles_logging__1pdNq {
  background-color: #fdf4d8;
  border: 2px solid #F6CC46;
}
.styles_assignmentCard__2ioak.styles_next__eIANt {
  background-color: #F3F7F6;
  border: 2px solid #1E9F76;
}
.styles_assignmentCard__2ioak.styles_scheduleGroupShadow__3c_6S {
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.06), 3px 3px 5px 0 #e6e6e6;
  margin-bottom: 1rem;
}
.styles_assignmentCard__2ioak.styles_scheduleGroupShadow__3c_6S::after {
  border-radius: 6px;
  box-shadow: 0 0 5px 3px #d9d9d9;
  content: "";
  height: 100%;
  left: 0.25rem;
  position: absolute;
  top: 0.4rem;
  width: 100%;
  z-index: -1;
}

.styles_assignmentCardInfo__3plBt {
  padding: 0.5rem;
  width: 100%;
}

.styles_requestIndicator__2nKRr {
  background-color: #E2164A;
  border-radius: 6px 0 0 6px;
  width: 0.5rem;
}
.styles_textCommon__2-HUu, .styles_textBold__1wWC6, .styles_textSecondary__1x1xm, .styles_textDefault__xM36G {
  line-height: 1.4em;
}

.styles_textDefault__xM36G {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__1x1xm {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__1wWC6 {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__17AJF {
  font-weight: 700;
}

.styles_alignRight__2f18i {
  text-align: right;
}

.styles_alignCenter__1f_tt {
  text-align: center;
}

.styles_tagLabel__ATTlB {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__2_XDY {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__2ANK6 {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__1lS8E {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__1iQqt,
.styles_textHeading__2OLMX,
.styles_textSubheading__1Wk7x {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__1EXLE {
  color: #9b9b9b;
}

.styles_container__3e6TF {
  position: relative;
}

.styles_padHeading__1FVus {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__2gq2i {
  padding-top: 1rem;
}

.styles_padY__3T5fV, .styles_subtitle__7PxJ_ {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__3NHMJ {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__2YLng {
  margin-top: 1rem;
}

.styles_marginY__iJw0o {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__2ODap {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__2Swb3 {
  margin: 0;
  padding: 0;
}

.styles_wrapper__Twgx7 {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__Twgx7 > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__2p4F_ {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__2p4F_ > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__xegYJ {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__xegYJ > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__3KjkY {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__3KjkY > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__39W59, .styles_subtitle__7PxJ_ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__39W59 > *, .styles_subtitle__7PxJ_ > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__nmuOk {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__nmuOk > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__1iQqt,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__2OLMX,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__1Wk7x,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__11Uzz {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__2Hsh7 {
    padding: 1rem;
  }
  .styles_hideMedium__2oF0i {
    display: none !important;
  }
  .styles_hideLarge__1OmEz {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__2Hsh7 {
    padding: 1rem;
  }
  .styles_subtitle__7PxJ_ {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__1Vi26 {
    display: none !important;
  }
  .styles_rowResize__3URXU > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__3URXU > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__2Hsh7 {
    padding: 0.5rem;
  }
  .styles_padX__3NHMJ {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__xM36G {
    font-size: 1.2rem;
  }
  .styles_textSecondary__1x1xm {
    font-size: 1.02rem;
  }
  .styles_textBold__1wWC6 {
    font-size: 1.2rem;
  }
  .styles_textHint__2_XDY {
    font-size: 0.84rem;
  }
  .styles_textLabel__2ANK6 {
    font-size: 1.2rem;
  }
  .styles_navLabel__1lS8E {
    font-size: 0.84rem;
  }
  .styles_tagLabel__ATTlB {
    font-size: 0.85rem;
  }
}
.styles_modal__3RctS {
  position: relative;
  box-shadow: 0 0.35rem 1rem 0 rgba(0, 0, 0, 0.5);
  padding: 1rem;
  background: #fff;
  outline: none;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  max-width: 720px;
  width: 80vw;
}

.styles_close__Z_3ET {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.styles_header__2NKsJ {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.styles_overlay__1eOv6 {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1500;
  justify-content: center;
  align-items: center;
  background: rgba(77, 77, 77, 0.45);
}

.styles_modalContent__1wl6F {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin-top: 1rem;
}
.styles_textCommon__1Yrk3, .styles_textBold__1yjbH, .styles_base__3ik2g .styles_label__AoNHr, .styles_textSecondary__2gaVk, .styles_textDefault__2yIlr {
  line-height: 1.4em;
}

.styles_textDefault__2yIlr {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__2gaVk {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__1yjbH, .styles_base__3ik2g .styles_label__AoNHr {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__21Jyd {
  font-weight: 700;
}

.styles_alignRight__1weQs {
  text-align: right;
}

.styles_alignCenter__3v62O {
  text-align: center;
}

.styles_tagLabel__NdXzW {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__2CksG {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__EhguJ {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__32NGG {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__TVhD1,
.styles_textHeading__2l5LL,
.styles_textSubheading__3bU2J {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__16f9Z {
  color: #9b9b9b;
}

.styles_container__Uae-t {
  position: relative;
}

.styles_padHeading__3PTmz {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__MhclU {
  padding-top: 1rem;
}

.styles_padY__2cUQX, .styles_subtitle__3gcOI {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__2uXYj {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__24IKx {
  margin-top: 1rem;
}

.styles_marginY__AXojk {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__2PgHk {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__22TqA {
  margin: 0;
  padding: 0;
}

.styles_wrapper__3BRp- {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__3BRp- > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__1ntPB {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__1ntPB > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__3-1Hb {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__3-1Hb > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__1tdqb {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__1tdqb > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__36OT7, .styles_subtitle__3gcOI {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__36OT7 > *, .styles_subtitle__3gcOI > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__3lzIt {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__3lzIt > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__TVhD1,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__2l5LL,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__3bU2J,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__2cWqN {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__nv5ix {
    padding: 1rem;
  }
  .styles_hideMedium__M-HDG {
    display: none !important;
  }
  .styles_hideLarge__3OKoT {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__nv5ix {
    padding: 1rem;
  }
  .styles_subtitle__3gcOI {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__2PXEH {
    display: none !important;
  }
  .styles_rowResize__1tUiS > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__1tUiS > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__nv5ix {
    padding: 0.5rem;
  }
  .styles_padX__2uXYj {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__2yIlr {
    font-size: 1.2rem;
  }
  .styles_textSecondary__2gaVk {
    font-size: 1.02rem;
  }
  .styles_textBold__1yjbH, .styles_base__3ik2g .styles_label__AoNHr {
    font-size: 1.2rem;
  }
  .styles_textHint__2CksG {
    font-size: 0.84rem;
  }
  .styles_textLabel__EhguJ {
    font-size: 1.2rem;
  }
  .styles_navLabel__32NGG {
    font-size: 0.84rem;
  }
  .styles_tagLabel__NdXzW {
    font-size: 0.85rem;
  }
}
.styles_base__3ik2g {
  background: #fff;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  margin: 0.35rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
}
.styles_base__3ik2g .styles_leftIcon__hPvFp {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  align-self: stretch;
}
.styles_base__3ik2g .styles_leftIcon__hPvFp svg {
  width: 1.5rem;
}
.styles_base__3ik2g .styles_leftIcon__hPvFp svg * {
  fill: #F6CC46;
}
.styles_base__3ik2g.styles_count__jaDsF .styles_leftIcon__hPvFp {
  background: rgba(226, 22, 74, 0.2);
}
.styles_base__3ik2g.styles_setting__1pvCi {
  box-shadow: 0 2px 14px 5px rgba(0, 0, 0, 0.06);
  margin: 0.5rem 0;
  padding: 0.5rem 0;
}
.styles_base__3ik2g .styles_label__AoNHr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex: 2 1;
  min-width: 0;
  overflow: hidden;
  position: relative;
  top: 2px;
}
.styles_base__3ik2g .styles_label__AoNHr > * {
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.styles_base__3ik2g .styles_arrow__GLazT {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  margin-left: auto;
  align-self: stretch;
}
.styles_base__3ik2g .styles_arrow__GLazT svg {
  width: 0.5rem;
  height: auto;
}
.styles_base__3ik2g .styles_arrow__GLazT svg * {
  fill: #1E9F76;
}
.styles_textCommon__3JsoG, .styles_textBold__3u8Xh, .styles_textSecondary__2tAUL, .styles_textDefault___nt9b {
  line-height: 1.4em;
}

.styles_textDefault___nt9b {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__2tAUL {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__3u8Xh {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__ASgEQ {
  font-weight: 700;
}

.styles_alignRight__NbVDy {
  text-align: right;
}

.styles_alignCenter__3oPyM {
  text-align: center;
}

.styles_tagLabel__CfApa {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__2O6dF {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__wcvfi {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__3kSDg {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__12Uns,
.styles_textHeading__1Gnct,
.styles_textSubheading__HYBRU {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__28tEY {
  color: #9b9b9b;
}

.styles_container__3kfgj {
  position: relative;
}

.styles_padHeading__sAhMJ {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__tuneL {
  padding-top: 1rem;
}

.styles_padY__3yG_z, .styles_subtitle__358IB {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__35RyD {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__iWOvi {
  margin-top: 1rem;
}

.styles_marginY__3jpDQ {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__1nwYz {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__W_TxZ {
  margin: 0;
  padding: 0;
}

.styles_wrapper__36nii {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__36nii > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__24rVf {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__24rVf > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__3q_7y {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__3q_7y > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__1BjxG {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__1BjxG > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__3na-l, .styles_subtitle__358IB {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__3na-l > *, .styles_subtitle__358IB > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__23aX5 {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__23aX5 > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__12Uns,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__1Gnct,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__HYBRU,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__2fR9S {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__9enos {
    padding: 1rem;
  }
  .styles_hideMedium__1ri0o {
    display: none !important;
  }
  .styles_hideLarge__3yxLZ {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__9enos {
    padding: 1rem;
  }
  .styles_subtitle__358IB {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__yetLT {
    display: none !important;
  }
  .styles_rowResize__2H3BS > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__2H3BS > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__9enos {
    padding: 0.5rem;
  }
  .styles_padX__35RyD {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault___nt9b {
    font-size: 1.2rem;
  }
  .styles_textSecondary__2tAUL {
    font-size: 1.02rem;
  }
  .styles_textBold__3u8Xh {
    font-size: 1.2rem;
  }
  .styles_textHint__2O6dF {
    font-size: 0.84rem;
  }
  .styles_textLabel__wcvfi {
    font-size: 1.2rem;
  }
  .styles_navLabel__3kSDg {
    font-size: 0.84rem;
  }
  .styles_tagLabel__CfApa {
    font-size: 0.85rem;
  }
}
.styles_selfLogButton__hdJFF {
  margin-top: 1rem;
  display: flex;
  padding: 0;
  align-items: center;
}

.styles_selfLogIcon__ijt93 {
  border: 2px solid #eeeeee;
  padding: 0.5rem;
  display: inline-flex;
  border-radius: 100%;
}
.styles_selfLogIcon__ijt93 svg * {
  fill: #000;
}

.styles_selfLogText__XmPWn {
  margin-left: 1rem;
  color: #4D4D4D;
}
.styles_textCommon__27Ny0, .styles_textBold__1Ga9v, .styles_textSecondary__1uvyU, .styles_textDefault__JXIcp {
  line-height: 1.4em;
}

.styles_textDefault__JXIcp {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__1uvyU {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__1Ga9v {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__prSuz {
  font-weight: 700;
}

.styles_alignRight__3k20m {
  text-align: right;
}

.styles_alignCenter__2lez_ {
  text-align: center;
}

.styles_tagLabel__3pC-6 {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__2IjgN {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__YLxB3 {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__1W8T9 {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__oeP-_,
.styles_textHeading__3g5EJ,
.styles_textSubheading__2M9q6 {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__3KdsP {
  color: #9b9b9b;
}

.styles_container__eJJiG {
  position: relative;
}

.styles_padHeading__1-Gr4 {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__1XYwL {
  padding-top: 1rem;
}

.styles_padY__Ggi0l, .styles_subtitle__302mr {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__1vhK4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__1Nt_8 {
  margin-top: 1rem;
}

.styles_marginY__XlIHL {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__2DFdk {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__1FSym {
  margin: 0;
  padding: 0;
}

.styles_wrapper__1sAj3 {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__1sAj3 > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__1qc1P {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__1qc1P > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__2uYuW {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__2uYuW > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__2YV5o {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__2YV5o > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__3tJLw, .styles_subtitle__302mr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__3tJLw > *, .styles_subtitle__302mr > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__ru7pT {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__ru7pT > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__oeP-_,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__3g5EJ,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__2M9q6,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__fODw9 {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__tVkKz {
    padding: 1rem;
  }
  .styles_hideMedium__3zeRz {
    display: none !important;
  }
  .styles_hideLarge__1aiz1 {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__tVkKz {
    padding: 1rem;
  }
  .styles_subtitle__302mr {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__1zssj {
    display: none !important;
  }
  .styles_rowResize__1c2wj > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__1c2wj > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__tVkKz {
    padding: 0.5rem;
  }
  .styles_padX__1vhK4 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__JXIcp {
    font-size: 1.2rem;
  }
  .styles_textSecondary__1uvyU {
    font-size: 1.02rem;
  }
  .styles_textBold__1Ga9v {
    font-size: 1.2rem;
  }
  .styles_textHint__2IjgN {
    font-size: 0.84rem;
  }
  .styles_textLabel__YLxB3 {
    font-size: 1.2rem;
  }
  .styles_navLabel__1W8T9 {
    font-size: 0.84rem;
  }
  .styles_tagLabel__3pC-6 {
    font-size: 0.85rem;
  }
}
.styles_headingContainer__2lYSj {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.styles_dayOfWeek__3xv2p {
  display: flex;
}

.styles_date__2b-oa {
  display: flex;
  margin-left: auto;
}
.styles_textCommon__1PIFd, .styles_textBold__IO0BA, .styles_textSecondary__3tG2c, .styles_textDefault__1oQLi {
  line-height: 1.4em;
}

.styles_textDefault__1oQLi {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__3tG2c {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__IO0BA {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__2V5-x {
  font-weight: 700;
}

.styles_alignRight__2OPEU {
  text-align: right;
}

.styles_alignCenter__19ito {
  text-align: center;
}

.styles_tagLabel__3Cfb9 {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__3uZMM {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__uw8z2 {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__1X4_a {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__2jIdc,
.styles_textHeading__16oa0,
.styles_textSubheading__32JWN {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__unlDZ {
  color: #9b9b9b;
}

.styles_container__1eMLb {
  position: relative;
}

.styles_padHeading__MWhdk {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__3fLbW {
  padding-top: 1rem;
}

.styles_padY__2XhVU, .styles_subtitle__8n62R {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__G2IeN {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__1nJ8f {
  margin-top: 1rem;
}

.styles_marginY__1tNnO {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__17gns {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__1JCV5 {
  margin: 0;
  padding: 0;
}

.styles_wrapper__1VftE {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__1VftE > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__3bHmo {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__3bHmo > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__wPiOp {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__wPiOp > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__3sVhi {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__3sVhi > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__2eJzO, .styles_subtitle__8n62R {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__2eJzO > *, .styles_subtitle__8n62R > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__cqByh {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__cqByh > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__2jIdc,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__16oa0,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__32JWN,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__2b19s {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__2_v_N {
    padding: 1rem;
  }
  .styles_hideMedium__vwobm {
    display: none !important;
  }
  .styles_hideLarge__2b2Sk {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__2_v_N {
    padding: 1rem;
  }
  .styles_subtitle__8n62R {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__3sdZP {
    display: none !important;
  }
  .styles_rowResize__1RJ3s > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__1RJ3s > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__2_v_N {
    padding: 0.5rem;
  }
  .styles_padX__G2IeN {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__1oQLi {
    font-size: 1.2rem;
  }
  .styles_textSecondary__3tG2c {
    font-size: 1.02rem;
  }
  .styles_textBold__IO0BA {
    font-size: 1.2rem;
  }
  .styles_textHint__3uZMM {
    font-size: 0.84rem;
  }
  .styles_textLabel__uw8z2 {
    font-size: 1.2rem;
  }
  .styles_navLabel__1X4_a {
    font-size: 0.84rem;
  }
  .styles_tagLabel__3Cfb9 {
    font-size: 0.85rem;
  }
}
.styles_textTitle__2jIdc {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__31kiY {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__2v3xp {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__M-Q2N {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__3Cty5 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__1M-FN {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__3qbzh {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__IO0BA {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__Dhy1L {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__2wD_4 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__2YiMk {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__3wcTu {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__2Pay3 {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__3PIbk {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__1SUBF {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__142cj {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__3dudn {
  border: 1px solid #E2164A;
}

.styles_spinner__3sq3N {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.styles_spinner__3sq3N:after {
  content: "";
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: calc(2rem/10) solid #F6CC46;
  border-color: #F6CC46 transparent;
  -webkit-animation: styles_loadingSpinnerAnimation__31Iyh 1.2s linear infinite;
          animation: styles_loadingSpinnerAnimation__31Iyh 1.2s linear infinite;
}
@-webkit-keyframes styles_loadingSpinnerAnimation__31Iyh {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes styles_loadingSpinnerAnimation__31Iyh {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.styles_pendingHeader__11-Pt {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.styles_jobsDetailSection__1-4WE {
  padding: 0.35rem 0.5rem;
}
.styles_jobsDetailSection__1-4WE .styles_newTicketButton__3i80J {
  color: #4A4A4A;
}
.styles_jobsDetailSection__1-4WE .styles_newTicketButton__3i80J svg * {
  fill: #106FEE;
}
.styles_jobsDetailSection__1-4WE .styles_newTicketButton__3i80J[background],
.styles_jobsDetailSection__1-4WE .styles_newTicketButton__3i80J [background] {
  border-color: #D8D8D8;
  background-color: #FFFFFF;
}
.styles_jobsDetailSection__1-4WE .styles_newTicketButton__3i80J:not(:disabled):hover {
  color: #646464;
}
.styles_jobsDetailSection__1-4WE .styles_newTicketButton__3i80J:not(:disabled):hover svg * {
  fill: #3f8cf2;
}
.styles_jobsDetailSection__1-4WE .styles_newTicketButton__3i80J:not(:disabled):hover[background],
.styles_jobsDetailSection__1-4WE .styles_newTicketButton__3i80J:not(:disabled):hover [background] {
  border-color: #f2f2f2;
  background-color: white;
}
[data-whatintent=keyboard] .styles_jobsDetailSection__1-4WE .styles_newTicketButton__3i80J:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_jobsDetailSection__1-4WE .styles_newTicketButton__3i80J:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_jobsDetailSection__1-4WE .styles_newTicketButton__3i80J:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_jobsDetailSection__1-4WE .styles_activityIconContainer__18FWE {
  display: flex;
  align-items: center;
  position: relative;
}
.styles_jobsDetailSection__1-4WE .styles_activityIconContainer__18FWE .styles_notificationsCount__3-j-Q {
  position: absolute;
  height: 21px;
  width: 21px;
  top: -20%;
  right: -50%;
  background-color: #E2164A;
}

.styles_scheduleHeader__2QUxE {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
  padding-bottom: 0.35rem;
}

.styles_scheduleInfo__1ekXY {
  padding-bottom: 1rem;
}

.styles_scheduleAccordion__2osxy {
  align-items: center;
  background-color: #EEEEEE;
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
     -moz-column-gap: 0.5rem;
          column-gap: 0.5rem;
  display: grid;
  grid-template-columns: -webkit-max-content 1fr -webkit-max-content;
  grid-template-columns: max-content 1fr max-content;
  margin-bottom: 0.35rem;
  padding: 1rem;
}
.styles_scheduleAccordion__2osxy .styles_closed__228Bi {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.styles_scheduleAccordion__2osxy .styles_open__30zru {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.styles_scheduleAccordion__2osxy .styles_scheduleAccordionToggle__3rw6y {
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 2px solid #cecece;
  height: 2rem;
  padding: 0.4rem;
  width: 2rem;
}
.styles_scheduleAccordion__2osxy .styles_scheduleAccordionToggle__3rw6y * {
  fill: #9B9B9B;
}
.styles_scheduleAccordion__2osxy .styles_scheduleAccordionTime__1PjT8 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1rem;
  font-weight: 900;
}

.styles_jobsContainer__2xDn8 {
  padding: 0 1rem;
}
.styles_textTitle__3gY4j {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__2gd91 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__2N8Fj {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__3Vbz_ {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__2v_fq {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__2ELqy {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__jq68F {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__PhoLP {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__2MDWT {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__3EPIU {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__355yc {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__3bjfB {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__3k7vz {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__1tfw4 {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__tiFaF {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__2tUdM {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__2fODj {
  border: 1px solid #E2164A;
}

.styles_attachmentsModalControls__U1Jh9 {
  display: flex;
  justify-content: space-between;
}

.styles_pdfDownload__3T5Gq {
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  grid-gap: 1rem;
  margin: 1rem 0;
  color: #106FEE;
  text-decoration: underline;
}
.styles_pdfDownload__3T5Gq svg * {
  fill: #000000;
}
.styles_pdfDownload__3T5Gq svg {
  width: 2rem;
  height: 2rem;
}

.styles_img__3CGnX {
  object-fit: contain;
  max-height: 70vh;
  max-width: 80vw;
}

.styles_attachmentItem__1MQ56 {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.35rem;
  align-items: flex-start;
  color: #106FEE;
  text-decoration: underline;
}
.styles_attachmentItem__1MQ56 svg * {
  fill: #106FEE;
}
.styles_attachmentItem__1MQ56 svg {
  width: 1rem;
  height: 1rem;
}
.styles_attachmentItemIcon__rqgo7 {
  -webkit-transform: translateY(30%);
          transform: translateY(30%);
}
.styles_textTitle__1kFBi {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__2YrxV {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__27jZq {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__b-EdV {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__1fzGn {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__1jE3z {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__leT7D {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__3etd7 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__2VLw0 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__2TNxs {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__1X9W8 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__22C3c {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__1ctHD {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__2ewm9 {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__gRTd5 {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__EWvzu {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__Uzz3i {
  border: 1px solid #E2164A;
}

.styles_detailsRowLabel__3wu0m {
  background-color: #FFFFFF;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  display: flex;
  align-items: center;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #9B9B9B;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_detailsRowValue__17H0- {
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  background-color: #FFFFFF;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 1rem;
  font-weight: 300;
}

.styles_detailsTable__Npw6w {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1px 0;
  background-color: #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
  border-top: 1px solid #EEEEEE;
  grid-template-columns: 140px auto;
}

.styles_projectSiteIndicator__1RVTk {
  display: flex;
  align-items: center;
  grid-gap: 0.35rem;
  gap: 0.35rem;
  position: relative;
  width: 100%;
  min-width: 120px;
}
.styles_projectSiteIndicator__1RVTk svg {
  width: 1.5rem;
  height: 1.5rem;
}

.styles_siteAddressText__26_PT .styles_siteDetailText__1KsQe {
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  right: 0;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_siteAddressText__26_PT {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_siteDirectionsButton__2-452 {
  margin-top: 0.5rem;
  color: #000000;
}
.styles_siteDirectionsButton__2-452 svg * {
  fill: #FFFFFF;
}
.styles_siteDirectionsButton__2-452[background],
.styles_siteDirectionsButton__2-452 [background] {
  border-color: transparent;
  background-color: #106FEE;
}
.styles_siteDirectionsButton__2-452:not(:disabled):hover {
  color: #1a1a1a;
}
.styles_siteDirectionsButton__2-452:not(:disabled):hover svg * {
  fill: white;
}
.styles_siteDirectionsButton__2-452:not(:disabled):hover[background],
.styles_siteDirectionsButton__2-452:not(:disabled):hover [background] {
  border-color: rgba(26, 26, 26, 0);
  background-color: #3f8cf2;
}
[data-whatintent=keyboard] .styles_siteDirectionsButton__2-452:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_siteDirectionsButton__2-452:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_siteDirectionsButton__2-452:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_siteNotes__7AR2O {
  margin-top: 0.5rem;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
.styles_textTitle__2faIM {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__3ft7A {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__PbE-d {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__2IiAt {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__39dSb {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__2vjKE {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__2i6uA {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__2dzTB {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__xTsiy {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__1kHOw {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__3XjpX {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__3TOKB {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__2ffu_ {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__2KxTT {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__X7G3V {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__1DLUB {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__3xprX {
  border: 1px solid #E2164A;
}

.styles_checkboxContainer__2uUOa {
  display: flex;
  align-items: center;
}
.styles_checkboxContainer__2uUOa .styles_checkboxLabel__2tv_o {
  margin-left: 0.5rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_checkboxBlockContainer__2FkPF {
  align-items: center;
  background: #EEEEEE;
  border-radius: 4px;
  border: 2px solid #D8D8D8;
  display: inline-flex;
  padding: 0.35rem 0.5rem 0.35rem 0.35rem;
}
.styles_checkboxBlockContainer__2FkPF .styles_checkboxLabel__2tv_o {
  margin-left: 0.5rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}
.styles_checkboxBlockContainer__2FkPF.styles_selected__3mVEO {
  background-color: #B538DE;
  border: 2px solid #B538DE;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}
.styles_checkboxBlockContainer__2FkPF.styles_selected__3mVEO .styles_checkboxLabel__2tv_o {
  margin-left: 0.5rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #FFFFFF;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_checkboxControl__3zhZq {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 4px;
  border: 2px solid #D8D8D8;
  cursor: pointer;
  background: #FFFFFF;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_checkboxControl__3zhZq svg * {
  fill: #B538DE;
}
.styles_checkboxControl__3zhZq svg {
  opacity: 0;
  height: 1rem;
  width: 1rem;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_checkboxControl__3zhZq.styles_selected__3mVEO {
  border: 2px solid #B538DE;
  background-color: #f0d5f8;
  box-shadow: none;
}
.styles_checkboxControl__3zhZq.styles_selected__3mVEO svg {
  opacity: 1;
}
.styles_checkboxControl__3zhZq.styles_partiallySelected__1nf17 svg {
  width: 10px;
  height: 2px;
}
.styles_checkboxControl__3zhZq.styles_disabled__KpjD2 {
  background-color: #EEEEEE;
  border: 2px solid #D8D8D8;
  cursor: auto;
}
.styles_checkboxControl__3zhZq.styles_disabled__KpjD2 svg * {
  fill: #D8D8D8;
}
.styles_textTitle__2Fve- {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__18wG0 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__vkJoR {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__2MzAW {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__2wVdP {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__3Q2Pw {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__2O6AN {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__9wWJJ {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__2bKfX {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__2gf-i {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__2PzTy {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__1fxWN {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__1emE8 {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__PtD9M {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__18WI4 {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__HtmOj {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__1ptc4 {
  border: 1px solid #E2164A;
}

.styles_textTitle__2Fve- {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__18wG0 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__vkJoR {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__2MzAW {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__2wVdP {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__3Q2Pw {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__2O6AN {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__9wWJJ {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__2bKfX {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__2gf-i {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__2PzTy {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__1fxWN {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__1emE8 {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__PtD9M {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__18WI4 {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__HtmOj {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__1ptc4 {
  border: 1px solid #E2164A;
}

.styles_dropdownIndicator__1J3Zh {
  margin: 0.5rem;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.styles_dropdownIndicator__1J3Zh svg * {
  fill: #9B9B9B;
}
.styles_dropdownIndicator__1J3Zh svg {
  height: 0.8rem;
  width: 0.8rem;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.styles_dropdownIndicator__1J3Zh.styles_open__LFa3L {
  background-color: transparent;
}
.styles_dropdownIndicator__1J3Zh.styles_open__LFa3L svg * {
  fill: #000000;
}
.styles_dropdownIndicator__1J3Zh.styles_open__LFa3L svg {
  height: 0.8rem;
  width: 0.8rem;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.styles_valueList__GV59L {
  margin-top: 0.35rem;
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;
}
.styles_valueList__GV59L .styles_selectedValue__enec2 {
  display: flex;
  padding: 0 0.35rem 0;
  border-color: #B538DE;
  border-radius: 1rem;
  background: #EEEEEE;
  margin: 0 0.35rem 0.35rem 0;
  line-height: normal;
  align-content: center;
  align-items: center;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 400;
}
.styles_valueList__GV59L .styles_selectedValue__enec2 .styles_clearButton__4I1Q3 {
  border: 0;
  margin-left: 0.35rem;
  display: inline-flex;
  padding: 0;
}
.styles_valueList__GV59L .styles_selectedValue__enec2 .styles_clearButton__4I1Q3 svg {
  width: 0.75rem;
  height: 0.75rem;
}
.styles_valueList__GV59L .styles_selectedValue__enec2 .styles_clearButton__4I1Q3 svg * {
  fill: #9B9B9B;
}
.react-select__control {
  border: 1px solid #EEEEEE !important;
  box-shadow: none !important;
  outline: none !important;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}
.react-select__control--is-focused {
  border: 1px solid #B538DE !important;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5) !important;
}
.react-select__input input {
  line-height: normal;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}
.react-select__option--is-selected {
  color: #4A4A4A !important;
}

.react-select__control--menu-is-open {
  border-radius: 4px 4px 0 0 !important;
}
.react-select__menu {
  margin: 0 !important;
  border: 1px solid #B538DE !important;
  border-top: 0 !important;
  border-radius: 0 0 4px 4px !important;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5) !important;
  min-width: 140px;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}
.react-select__menu-list {
  padding: 0 !important;
  min-width: 140px;
}
.styles_textTitle__2RS1E {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__2NB7U {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__1bnT6 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__2YYvg {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__I5Jna {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__1SRFe {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__2I8pq {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__16S4a {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__1yWfh {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__xBJ56 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__mREh4 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__1XS4y {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__3WsXk {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__3hqov {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__3i6aj {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__3xq4f {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__3TTqT {
  border: 1px solid #E2164A;
}

.styles_formField__2j7vV {
  display: flex;
  flex-direction: column;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}
.styles_formField__2j7vV.styles_embedded__B3Vpd {
  height: 100%;
}

.styles_formFieldLabelsContainer__FKR0C {
  display: flex;
  justify-content: space-between;
}

.styles_formFieldLabel__1Fm8l {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.styles_formFieldRequired__KILhN {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #E2164A;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_formFieldHint__1nEO6 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #767676;
  font-size: 0.9rem;
  font-weight: 300;
  margin-top: 4px;
}

.styles_formFieldError__1LvWy {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #E2164A;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_formFieldDescription__2zrZk {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
  margin-bottom: 10px;
}

.styles_formFieldLabelTooltip__2zIkK {
  display: inline-flex;
}
.styles_formFieldLabelTooltip__2zIkK .styles_hoverIconContainer__3eEln {
  margin-bottom: 4px;
}
.styles_textTitle__3tETZ {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__1dQyd {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__2bvb7 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__398zf {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__GaXHK {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__mS0CX {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__3pAPB {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__oxKdd {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__27_C1 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__1hCYb {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__2ASk1 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__1MuCT {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__24Qzm {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__1uzEy {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__1TDsl {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__3_W0e {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__857th {
  border: 1px solid #E2164A;
}

.styles_radioItemsGroup__28SOH {
  display: grid;
  grid-gap: 12px;
  padding-top: 9px;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}
.styles_radioItemsGroup__28SOH.styles_horizontal__2UkaG {
  grid-auto-flow: column;
}

label > span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

label > span + div {
  padding-left: 1.5rem;
  margin-left: 7px;
}

label > input[type=radio] {
  display: none;
}

label > input[type=radio] + *::before {
  display: inline-block;
  cursor: pointer;
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 7px;
  border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #D8D8D8;
  background-position: center;
  background-repeat: space;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

label > input[type=radio]:disabled + *::before {
  background-color: #EEEEEE;
}

label > input[type=radio]:checked + *::before {
  background-image: -webkit-radial-gradient(#B538DE 35%, rgba(181, 56, 222, 0.1) 40%);
  background-image: radial-gradient(#B538DE 35%, rgba(181, 56, 222, 0.1) 40%);
  border: 2px solid #B538DE;
}

label > input[type=radio]:checked:disabled + *::before {
  background-image: -webkit-radial-gradient(#D8D8D8 35%, #EEEEEE 40%);
  background-image: radial-gradient(#D8D8D8 35%, #EEEEEE 40%);
  border: 2px solid #D8D8D8;
}
.styles_textTitle__3-XTv {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__q4izG {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__1cmAu {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__3x6KY {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__2RaKg {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__2-Rd2 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__30azM {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__Se61M {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__2o8VV {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__2qWnj {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__JRULq {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__2t6fa {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__2Hlu7 {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__2DZx7 {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__3-0M9 {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__3b_yt {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__20HRL {
  border: 1px solid #E2164A;
}

.styles_textArea__1s-SN {
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  padding: 0.5rem;
  height: 7.5rem;
  width: 100%;
  resize: none;
  line-height: normal;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_textArea__1s-SN.styles_embedded__3P_O1 {
  height: 100%;
  border: 0;
  background-color: transparent;
}

.styles_textArea__1s-SN::-webkit-input-placeholder {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #9B9B9B;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textArea__1s-SN::-moz-placeholder {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #9B9B9B;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textArea__1s-SN:-ms-input-placeholder {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #9B9B9B;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textArea__1s-SN::-ms-input-placeholder {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #9B9B9B;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textArea__1s-SN::placeholder {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #9B9B9B;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textArea__1s-SN:focus {
  outline: none;
  border: 1px solid #B538DE;
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}
.styles_textTitle__1c85Z {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__138uq {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__3Uij- {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__362J6 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__1qFM_ {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__3-vHN {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__1DUaN {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__uO2YR {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__2ww_N {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__5C-Hy {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__1phsj {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__3xco1 {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__3LjrD {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__2Qq6E {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__3OqEL {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__vJeI7 {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__v_Wz7 {
  border: 1px solid #E2164A;
}

.styles_textInput__1Q7CL {
  position: relative;
}
.styles_textInput__1Q7CL.styles_embedded__24RPF {
  height: 100%;
}

.styles_textInputField__3rcGP {
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  padding: 0.5rem;
  height: 2.5rem;
  line-height: normal;
  width: 100%;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_textInputField__3rcGP.styles_currency__2Lvnr {
  padding-left: 1rem;
}
.styles_textInputField__3rcGP.styles_search__QX6sV {
  padding-left: 2rem;
  border-color: #D8D8D8;
}
.styles_textInputField__3rcGP.styles_embedded__24RPF {
  background-color: transparent;
  height: 100%;
  border: 0;
}
.styles_textInputField__3rcGP.styles_disabled__14hL4 {
  background: #EEEEEE;
}

.styles_textInputField__3rcGP::-webkit-input-placeholder {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #9B9B9B;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textInputField__3rcGP::-moz-placeholder {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #9B9B9B;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textInputField__3rcGP:-ms-input-placeholder {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #9B9B9B;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textInputField__3rcGP::-ms-input-placeholder {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #9B9B9B;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textInputField__3rcGP::placeholder {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #9B9B9B;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textInputField__3rcGP:focus {
  outline: none;
  border: 1px solid #B538DE;
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_inputDecorator__2IxCv {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #9B9B9B;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_minutes__3707z {
  left: 1.8em;
}

.styles_currency__2Lvnr {
  left: 0.5rem;
}

.styles_measurement__3TRzv {
  right: 2rem;
}

.styles_searchIcon__2_Gsl {
  left: 0.5rem;
  display: flex;
  align-items: center;
}
.styles_searchIcon__2_Gsl svg * {
  fill: #9B9B9B;
}
.styles_searchIcon__2_Gsl svg {
  width: 1.25rem;
  height: 1.25rem;
}
.styles_textTitle__3-AxK {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__2IGS8 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__29Ajr {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__2zv4m {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__38Hxy {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__1AcSi {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__3qBOQ {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__IoC6K {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__bOrVZ {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__2zKEY {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__3kUmN {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__3aO3T {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__PcZMI {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__QbGCF {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__2pW3r {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__v1Vi- {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__1eoSy {
  border: 1px solid #E2164A;
}

.styles_toggleContainer__1cxBQ {
  display: flex;
  align-items: center;
}

.styles_toggleControl__BMMsC {
  position: relative;
  height: 1.25rem;
  width: 2.5rem;
  padding: 2px;
  border-radius: 1rem;
  box-sizing: content-box;
  background-color: #EEEEEE;
  cursor: pointer;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_toggleControl__BMMsC.styles_slim__ulfGP {
  padding: 2px 0;
  width: 1.75rem;
  height: 0.25rem;
}
.styles_toggleControl__BMMsC.styles_selected__2bCHd {
  background-color: #f0d5f8;
}

.styles_toggleIndicator__v0M-z {
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 100%;
  left: 2px;
  background-color: #9B9B9B;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_toggleIndicator__v0M-z.styles_slim__ulfGP {
  width: 1rem;
  height: 1rem;
  top: -0.25rem;
}
.styles_toggleIndicator__v0M-z.styles_slimSelected__7BA-8 {
  left: 100%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  background-color: #B538DE;
}
.styles_toggleIndicator__v0M-z.styles_selected__2bCHd {
  left: 100%;
  -webkit-transform: translateX(calc(-100% - 2px));
          transform: translateX(calc(-100% - 2px));
  background-color: #B538DE;
}

.styles_toggleLabel__1fJ7Q {
  margin-left: 0.5rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}
.styles_textTitle__2V6jy {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__aPz54 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__1Gfnk {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__iXSwF {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__2qLSv {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__3sNkN {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__1v8iT {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__3hVIH {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__1PX-O {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__36Pci {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__FWhg5 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__3RHRS {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__3JAfF {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__2f4Tg {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__2M2Ab {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__26dIn {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__1pDZ9 {
  border: 1px solid #E2164A;
}

.styles_textTitle__2V6jy {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__aPz54 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__1Gfnk {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__iXSwF {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__2qLSv {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__3sNkN {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__1v8iT {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__3hVIH {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__1PX-O {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__36Pci {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__FWhg5 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__3RHRS {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__3JAfF {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__2f4Tg {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__2M2Ab {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__26dIn {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__1pDZ9 {
  border: 1px solid #E2164A;
}
.tag-input__control {
  border: 1px solid #EEEEEE !important;
  box-shadow: none !important;
  outline: none !important;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}
.tag-input__control--is-focused {
  border: 1px solid #B538DE !important;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5) !important;
}
.tag-input__input input {
  line-height: normal;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}
.tag-input__option--is-selected {
  color: #4A4A4A !important;
}

.tag-input__control--menu-is-open {
  border-radius: 0 0 4px 4px !important;
}
.tag-input__menu {
  margin: 0 !important;
  border: 1px solid #B538DE !important;
  border-bottom: 0 !important;
  border-radius: 4px 4px 0 0 !important;
  box-shadow: none !important;
  min-width: 140px;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}
.tag-input__menu-list {
  padding: 0 !important;
  min-width: 140px;
}

.tag-input__value-container {
  grid-gap: 0.25rem;
  gap: 0.25rem;
  padding: 4px 8px !important;
}

.styles_createLabel__3Dmxg {
  display: flex;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  align-items: center;
}
.styles_createLabel__3Dmxg svg {
  width: 1rem;
  height: 1rem;
}
.styles_createLabel__3Dmxg svg * {
  fill: #9B9B9B;
}

.styles_tagChip__P7LRy {
  color: #4A4A4A;
  background-color: rgba(181, 56, 222, 0.1);
  border: 1px solid #B538DE;
}
.styles_tagChip__P7LRy svg * {
  fill: #B538DE;
}
.styles_tagChip__P7LRy .styles_tagChipText___kgMR {
  position: relative;
}
.styles_tagChip__P7LRy .styles_editIcon__3AUGX {
  opacity: 0;
  position: absolute;
  right: -9px;
  top: -3px;
  display: flex;
  background-color: #F8EBFC;
  padding: 4px;
  border-radius: 50%;
}
.styles_tagChip__P7LRy .styles_editIcon__3AUGX svg {
  width: 12px;
  height: 12px;
}
.styles_tagChip__P7LRy .styles_editIcon__3AUGX svg * {
  fill: #4A4A4A;
}
.styles_tagChip__P7LRy .styles_editable__Nrl5z:hover {
  text-decoration: underline;
}
.styles_tagChip__P7LRy .styles_editable__Nrl5z:hover .styles_editIcon__3AUGX {
  opacity: 1;
}
.styles_textTitle__26OvH {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__3NmCw {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__qco7D {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__dDUqW {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__pZER9 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__30jjC {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__3_8EP {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__2TvVa {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__2cCSl {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__3v2eU {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__26Sr1 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__2K16r {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__1eRN6 {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__2iOyc {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__QLUyN {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__31evq {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__3ueBb {
  border: 1px solid #E2164A;
}

.styles_checkboxGroupContainer__19SJB {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  margin-top: 0.5rem;
}
.styles_textTitle__1GSq2 {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__FuIoM {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__2t0pV {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__NO7us {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__18izx {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__2Bc-R {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__Campv {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__FOMm8 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__1-PDw {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__Qv31F {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__v_pJY {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__3b7e5 {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__3m_64 {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__mhX4p {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__3x1ww {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__3drOW {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__2AYVm {
  border: 1px solid #E2164A;
}

.styles_notesSection__1ouj1 {
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 0.5rem 1rem;
  background-color: #FFFFFF;
  margin-top: -1px;
}

.styles_jobInfoSection__2_Hgj {
  margin-bottom: 1rem;
}
.styles_jobInfoSectionLabel__3H-Gi {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.85rem;
  font-weight: 600;
}
.styles_textTitle__3aTxD {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__1WJ0w {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__3djpD {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__2HEYx {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__35a0o {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__1C-7u {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__AIKbg {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__azJQY {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__2T5G1 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__1bANS {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__Qbzpn {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__Rb6wo {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__1vXgt {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__j-YjF {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__1Ep61 {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__2hqLN {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__3chW_ {
  border: 1px solid #E2164A;
}

.styles_ticketThumbnail__3OXfb {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  border-radius: 4px;
  background-size: cover;
  background-color: #9B9B9B;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #EEEEEE;
}
.styles_ticketThumbnail__3OXfb svg {
  width: 2rem;
  height: 2rem;
}
.styles_ticketThumbnail__3OXfb svg * {
  fill: #FFFFFF;
}

.styles_ticketMagnifierContainer__PGrK3 {
  position: relative;
  width: 500px;
  height: 500px;
  max-width: 100%;
  overflow: auto;
}
@media only screen and (max-width: 768px) {
  .styles_ticketMagnifierContainer__PGrK3 {
    width: 75vw;
    height: 90vw;
  }
}
.styles_ticketMagnifierContainer__PGrK3 .styles_ticketMagnifierGlass__3aSy2 {
  position: absolute;
  cursor: none;
  width: 150px;
  height: 150px;
  border-radius: 4px;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  image-orientation: none;
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}
@media only screen and (max-width: 768px) {
  .styles_ticketMagnifierContainer__PGrK3 .styles_ticketMagnifierGlass__3aSy2 {
    width: 30vw;
    height: 30vw;
  }
}
.styles_ticketMagnifierContainer__PGrK3 .styles_ticketMagnifierImage__3wcVT {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  image-orientation: none;
  width: 100%;
  height: 100%;
}
.styles_ticketMagnifierContainer__PGrK3 .styles_pdfDownload__MZn5_ {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #106FEE;
  font-size: 1.3rem;
  font-weight: 300;
}
.styles_ticketMagnifierContainer__PGrK3 .styles_pdfDownload__MZn5_ svg * {
  fill: #000000;
}
.styles_ticketMagnifierContainer__PGrK3 .styles_pdfDownload__MZn5_ svg {
  width: 2rem;
  height: 2rem;
}
.styles_ticketMagnifierContainer__PGrK3 .styles_pdfDownload__MZn5_ svg {
  margin-bottom: 1rem;
}

.styles_ticketZoomerContainer__1bdsm {
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
  overflow: hidden;
  background-color: #000000;
  border-radius: 9px;
  position: relative;
}
.styles_ticketZoomerContainer__1bdsm.styles_darkBackground__3AKpG {
  background-color: #000000;
}
@media only screen and (min-width: 1010px) {
  .styles_ticketZoomerContainer__1bdsm {
    overflow: auto;
    background-color: transparent;
    border-radius: 0;
  }
}
.styles_ticketZoomerContainer__1bdsm .styles_ticketZoomerImage__1ywwE {
  object-fit: contain;
  image-orientation: none;
  max-width: 100%;
  width: 100vw;
  height: 100vw;
}
.styles_ticketZoomerContainer__1bdsm .styles_pdfDownload__MZn5_ {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  padding: 1rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #106FEE;
  font-size: 1.3rem;
  font-weight: 300;
}
.styles_ticketZoomerContainer__1bdsm .styles_pdfDownload__MZn5_ svg * {
  fill: #000000;
}
.styles_ticketZoomerContainer__1bdsm .styles_pdfDownload__MZn5_ svg {
  width: 2rem;
  height: 2rem;
}
.styles_ticketZoomerContainer__1bdsm .styles_pdfDownload__MZn5_ svg {
  margin-bottom: 1rem;
}
.styles_textTitle__2SByK {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__2dMo4 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__2t3ce {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__23a6n {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__3X23r {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__3gYYM {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__3UqrU {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__8EUh1 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__1dQZX {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__x89d2 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__WPKXI {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__1e-Qy {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__26PGM {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__1-PKq {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__21cuV {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__2HIcO {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__3uAqq {
  border: 1px solid #E2164A;
}

.styles_activityStream__19wH5 {
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
}

.styles_activityStreamItemWrapper__3d4Pw {
  align-self: flex-end;
}
.styles_activityStreamItemWrapper__3d4Pw[data-fromother=true] {
  align-self: flex-start;
}

.styles_activityStreamItem__3dHK2 {
  display: flex;
  margin-bottom: 1rem;
  flex-direction: row;
}
[data-fromother=true] .styles_activityStreamItem__3dHK2 {
  flex-direction: row-reverse;
}

.styles_activityStreamItem__3dHK2 .styles_activityStreamItemContents__1X1pP {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
[data-fromother=true] .styles_activityStreamItem__3dHK2 .styles_activityStreamItemContents__1X1pP {
  align-items: flex-start;
}

.styles_activityStreamItem__3dHK2 .styles_activityStreamItemDate__1vo6I {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}
.styles_activityStreamItem__3dHK2 .styles_activityStreamItemProfile__2Z1Y_ {
  margin-left: 1rem;
  margin-right: 0;
}
[data-fromother=true] .styles_activityStreamItem__3dHK2 .styles_activityStreamItemProfile__2Z1Y_ {
  margin-right: 1rem;
  margin-left: 0;
}

.styles_activityStreamItem__3dHK2 .styles_activityStreamItemPhoto__1DmcL {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 100%;
  object-fit: cover;
  object-position: 50% 0;
}
.styles_activityStreamItem__3dHK2 .styles_activityStreamItemInitials__32rcw {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 100%;
  background-color: #9B9B9B;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #FFFFFF;
  font-size: 0.85rem;
  font-weight: 400;
}
.styles_textTitle__3cSf0 {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__3X53l {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__10eKb {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__3MvR8 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__3Y6L8 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__1xBJ0 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__3RIdT {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__2Tusi {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__2Hkjr {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__Qymmp {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__2tio4 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__cHoMR {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__3ZOTR {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__27g0m {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__xF2fB {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__22GDI {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__3lZcy {
  border: 1px solid #E2164A;
}

.styles_speechBubble__jOty0 {
  position: relative;
  display: inline-flex;
  padding: 0.5rem;
  border-radius: 9px;
  background-color: #e6f0fd;
  border: 1px solid #6fa8f5;
  word-break: break-word;
}
[data-fromother=true] .styles_speechBubble__jOty0 {
  background-color: white;
  border: 1px solid #cecece;
}

.styles_speechBubble__jOty0:after, .styles_speechBubble__jOty0:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}
.styles_speechBubble__jOty0:before {
  top: 9px;
  left: 100%;
  border-left: 0;
  border-right: 9px solid transparent;
  border-top: 9px solid #6fa8f5;
}
[data-fromother=true] .styles_speechBubble__jOty0:before {
  left: -9px;
  border-left: 9px solid transparent;
  border-right: 0;
  border-top: 9px solid #cecece;
}

.styles_speechBubble__jOty0:after {
  top: 10px;
  left: 100%;
  border-top: 7px solid #e6f0fd;
  border-left: 0;
  border-right: 7px solid transparent;
}
[data-fromother=true] .styles_speechBubble__jOty0:after {
  left: -7px;
  border-top: 7px solid white;
  border-left: 7px solid transparent;
  border-right: 0;
}

.styles_ticketStreamItemContents__2CDUU {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.35rem;
  justify-items: flex-end;
}
[data-fromother=true] .styles_ticketStreamItemContents__2CDUU {
  justify-items: flex-start;
}

.styles_ticketContainer__CTvZm {
  display: flex;
  align-items: center;
}
[data-fromother=true] .styles_ticketContainer__CTvZm {
  flex-direction: row-reverse;
}

.styles_ticketThumbnailContainer__3-H0U {
  margin-left: 0.5rem;
}
[data-fromother=true] .styles_ticketThumbnailContainer__3-H0U {
  margin-left: 0;
  margin-right: 0.5rem;
}

.styles_signOffButton__1fM4M {
  color: #000000;
}
.styles_signOffButton__1fM4M svg * {
  fill: #B538DE;
}
.styles_signOffButton__1fM4M[background],
.styles_signOffButton__1fM4M [background] {
  border-color: #D8D8D8;
  background-color: #FFFFFF;
}
.styles_signOffButton__1fM4M:not(:disabled):hover {
  color: #1a1a1a;
}
.styles_signOffButton__1fM4M:not(:disabled):hover svg * {
  fill: #c564e5;
}
.styles_signOffButton__1fM4M:not(:disabled):hover[background],
.styles_signOffButton__1fM4M:not(:disabled):hover [background] {
  border-color: #f2f2f2;
  background-color: white;
}
[data-whatintent=keyboard] .styles_signOffButton__1fM4M:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_signOffButton__1fM4M:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_signOffButton__1fM4M:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_signedTag__12ROJ {
  color: #000000;
  background-color: #f6e7fb;
  border: 1px solid #B538DE;
}
.styles_signedTag__12ROJ svg * {
  fill: #000000;
}
.styles_textCommon__1goHW, .styles_textBold__3bW_b, .styles_textSecondary__1YaN6, .styles_textDefault__ZReHb {
  line-height: 1.4em;
}

.styles_textDefault__ZReHb {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__1YaN6 {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__3bW_b {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__2ancw {
  font-weight: 700;
}

.styles_alignRight___UHJX {
  text-align: right;
}

.styles_alignCenter__2tKS1 {
  text-align: center;
}

.styles_tagLabel__1oyb2 {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__212WP {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__1M4c-, .styles_image__YhQ_d .styles_count__1ntYa .styles_label__3K9jj, .styles_imageContainer__VpO_0 .styles_count__1ntYa .styles_label__3K9jj {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__Imf0P {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__3_GQf,
.styles_textHeading__39FX8,
.styles_textSubheading__10we7 {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__3PCGM {
  color: #9b9b9b;
}

.styles_container__1VMkh {
  position: relative;
}

.styles_padHeading__3tuAk {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__FO2nj {
  padding-top: 1rem;
}

.styles_padY__wr9m8, .styles_subtitle__3Fg1k {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__1oNXZ {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__1KJ8_ {
  margin-top: 1rem;
}

.styles_marginY__1aXGC {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__x5SB0 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__2vkym {
  margin: 0;
  padding: 0;
}

.styles_wrapper__1pVGm {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__1pVGm > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__1i1UC {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__1i1UC > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__2T7L7 {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__2T7L7 > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__oQev6 {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__oQev6 > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__2WWWv, .styles_subtitle__3Fg1k {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__2WWWv > *, .styles_subtitle__3Fg1k > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__2Z_AB {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__2Z_AB > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__3_GQf,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__39FX8,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__10we7,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__2AdrZ {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__1GDpZ {
    padding: 1rem;
  }
  .styles_hideMedium__2PpRg {
    display: none !important;
  }
  .styles_hideLarge__150a7 {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__1GDpZ {
    padding: 1rem;
  }
  .styles_subtitle__3Fg1k {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__c_Jes {
    display: none !important;
  }
  .styles_rowResize__33PaK > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__33PaK > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__1GDpZ {
    padding: 0.5rem;
  }
  .styles_padX__1oNXZ {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__ZReHb {
    font-size: 1.2rem;
  }
  .styles_textSecondary__1YaN6 {
    font-size: 1.02rem;
  }
  .styles_textBold__3bW_b {
    font-size: 1.2rem;
  }
  .styles_textHint__212WP {
    font-size: 0.84rem;
  }
  .styles_textLabel__1M4c-, .styles_image__YhQ_d .styles_count__1ntYa .styles_label__3K9jj, .styles_imageContainer__VpO_0 .styles_count__1ntYa .styles_label__3K9jj {
    font-size: 1.2rem;
  }
  .styles_navLabel__Imf0P {
    font-size: 0.84rem;
  }
  .styles_tagLabel__1oyb2 {
    font-size: 0.85rem;
  }
}
.styles_imageContainer__VpO_0 {
  position: relative;
}
.styles_imageContainer__VpO_0 .styles_count__1ntYa {
  position: absolute;
  top: calc(-0.35rem/2);
  right: 0;
  display: inline-flex;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background: #B538DE;
  color: #fff;
}
.styles_imageContainer__VpO_0 .styles_count__1ntYa .styles_label__3K9jj {
  color: inherit;
  position: relative;
}

.styles_image__YhQ_d .styles_count__1ntYa {
  position: absolute;
  top: calc(-0.35rem/2);
  right: 0;
  display: inline-flex;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background: #B538DE;
  color: #fff;
}
.styles_image__YhQ_d .styles_count__1ntYa .styles_label__3K9jj {
  color: inherit;
  position: relative;
}

.styles_image__YhQ_d {
  position: relative;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  width: 4rem;
  height: 4rem;
  background: #eeeeee;
}
.styles_image__YhQ_d svg * {
  fill: #4A4A4A;
}
.styles_image__YhQ_d:hover {
  background: #9b9b9b;
}
.styles_image__YhQ_d.styles_uploaded__3Vtni {
  background-size: cover;
  background-position: top;
}
.styles_image__YhQ_d.styles_uploaded__3Vtni:hover {
  border: 1px solid #1E9F76;
}
.styles_textTitle__1MtlF {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__37Yen {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__34rkJ {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__24UWB {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__axIId {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__1uP84 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__31YEj {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__1jKdQ {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__2AmAu {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__8X5HT {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__1GLMB {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__1pl1j {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__387Eh {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__dHI40 {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__2pCzT {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__3puK6 {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__1zuXf {
  border: 1px solid #E2164A;
}

.styles_ticketPageDisplayContainer__36txt {
  margin: 1rem;
}

.styles_ticketFormColumns__2DJhS {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.styles_warning__3PSiX {
  padding: 0.5rem 0;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #E2164A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_ticketFormButtonColumns__fDbg8 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
  height: 150px;
  margin-bottom: -15px;
}

.styles_ticketUploadControls__hUeQ- {
  margin: 0.35rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, auto));
  grid-auto-rows: minmax(20px, auto);
  align-items: center;
  grid-gap: 0.5rem;
}

.styles_signoffContainer__3aPxT {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.5rem;
  align-items: center;
  align-self: flex-start;
}

.styles_signoffButton__37hut {
  color: #000000;
  margin-bottom: 9px;
}
.styles_signoffButton__37hut svg * {
  fill: #000000;
}
.styles_signoffButton__37hut[background],
.styles_signoffButton__37hut [background] {
  border-color: #D8D8D8;
  background-color: #FFFFFF;
}
.styles_signoffButton__37hut:not(:disabled):hover {
  color: #1a1a1a;
}
.styles_signoffButton__37hut:not(:disabled):hover svg * {
  fill: #1a1a1a;
}
.styles_signoffButton__37hut:not(:disabled):hover[background],
.styles_signoffButton__37hut:not(:disabled):hover [background] {
  border-color: #f2f2f2;
  background-color: white;
}
[data-whatintent=keyboard] .styles_signoffButton__37hut:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_signoffButton__37hut:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_signoffButton__37hut:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_ticketUpload__3Ixfc {
  display: inline-grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 0.5rem;
  margin-top: 0.35rem;
  margin-bottom: 0.35rem;
}
.styles_ticketUpload__3Ixfc.styles_ticketUploaded__16VRS {
  grid-template-columns: auto;
}

.styles_attachmentUpload__1dvwq {
  display: flex;
  flex-direction: column;
}

.styles_uploadButton__3OC3d {
  height: 50px;
  width: 146px;
  margin-bottom: 16px;
}
.styles_uploadButton__3OC3d .styles_uploadButtonLabel__zWAXi {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 17px;
  font-weight: 550;
}
.styles_uploadButton__3OC3d .styles_uploadButtonContainer__57hWf {
  position: relative;
  top: 3px;
  right: 3px;
  margin-top: 18px;
  margin-bottom: 18px;
  margin-right: -8px;
}
.styles_uploadButton__3OC3d .styles_uploadIconContainer__2VkWF {
  width: 30px;
  height: 30px;
}

.styles_spinnerContainer__1CwtC {
  opacity: 0;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.35rem;
  align-items: flex-start;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}
.styles_spinnerContainer__1CwtC .styles_spinner__3-JDe {
  padding-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.styles_spinnerContainer__1CwtC .styles_spinner__3-JDe:after {
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: calc(1rem/10) solid #F6CC46;
  border-color: #F6CC46 transparent;
  -webkit-animation: styles_loadingSpinnerAnimation__1HHlp 1.2s linear infinite;
          animation: styles_loadingSpinnerAnimation__1HHlp 1.2s linear infinite;
}
@-webkit-keyframes styles_loadingSpinnerAnimation__1HHlp {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes styles_loadingSpinnerAnimation__1HHlp {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.styles_spinnerContainer__1CwtC.styles_visible__2rlVk {
  opacity: 1;
}

.styles_paddedHeader__19_ST {
  padding-bottom: 1rem;
}

.styles_ticketTitleContainer__2o7Xl {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
  padding-bottom: 1rem;
}
.styles_ticketTitleContainer__2o7Xl .styles_ticketPill__2ZXFi {
  color: #4A4A4A;
  background-color: #EEEEEE;
  border: 1px solid #9B9B9B;
}
.styles_ticketTitleContainer__2o7Xl .styles_ticketPill__2ZXFi svg * {
  fill: #9B9B9B;
}

.styles_deleteTicketsButtonParent__166y0 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_deleteTicketButton__1uGbb {
  margin: 40px 0 50px 0;
  color: #E2164A;
}
.styles_deleteTicketButton__1uGbb svg * {
  fill: #E2164A;
}
.styles_deleteTicketButton__1uGbb[background],
.styles_deleteTicketButton__1uGbb [background] {
  border-color: #D8D8D8;
  background-color: #E2164A;
}
.styles_deleteTicketButton__1uGbb:not(:disabled):hover {
  color: #ec3f6b;
}
.styles_deleteTicketButton__1uGbb:not(:disabled):hover svg * {
  fill: #ec3f6b;
}
.styles_deleteTicketButton__1uGbb:not(:disabled):hover[background],
.styles_deleteTicketButton__1uGbb:not(:disabled):hover [background] {
  border-color: #f2f2f2;
  background-color: #ec3f6b;
}
[data-whatintent=keyboard] .styles_deleteTicketButton__1uGbb:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_deleteTicketButton__1uGbb:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_deleteTicketButton__1uGbb:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_stickyMenuToBottom__3LaQm {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  background-color: white;
}

.styles_bottomStickyDivider__KdsV_ {
  margin: 0 -4px 4px -4px;
}

.styles_divider__1P3b8 {
  margin-bottom: 18px;
}

.styles_notesMargin__3oMhk {
  margin-top: 18px;
}

.styles_ticketImageContainer__2y-Dd {
  position: relative;
}

.styles_closeIcon__3Woyp {
  position: relative;
  left: 3px;
}

.styles_attachmentsList__2PavR {
  display: flex;
  overflow-y: auto;
}

.styles_formFieldLabel__30opc {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.styles_photoContainer__1kt2T {
  display: flex;
  margin-right: 16px;
}

.styles_ticketRowPhoto__3S-DG {
  position: relative;
  width: 74px;
  height: 74px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #EEEEEE;
}
.styles_ticketRowPhoto__3S-DG .styles_detachAttachmentButton__2ffMf {
  position: absolute;
  left: 54px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000;
  padding: 3px;
}
.styles_ticketRowPhoto__3S-DG .styles_detachAttachmentButton__2ffMf svg {
  width: 15px;
  height: 15px;
}
.styles_ticketRowPhoto__3S-DG .styles_detachAttachmentButton__2ffMf svg * {
  fill: #FFFFFF;
}
.styles_ticketRowPhoto__3S-DG .styles_downloadAttachmentButton__xQRFz {
  position: absolute;
  margin: 0 auto;
  bottom: 12px;
  color: #4D4D4D;
}
.styles_ticketRowPhoto__3S-DG .styles_downloadAttachmentButton__xQRFz svg * {
  fill: #767676;
}
.styles_ticketRowPhoto__3S-DG .styles_downloadAttachmentButton__xQRFz[background],
.styles_ticketRowPhoto__3S-DG .styles_downloadAttachmentButton__xQRFz [background] {
  border-color: #FFFFFF;
  background-color: #FFFFFF;
}
.styles_ticketRowPhoto__3S-DG .styles_downloadAttachmentButton__xQRFz:not(:disabled):hover {
  color: #676767;
}
.styles_ticketRowPhoto__3S-DG .styles_downloadAttachmentButton__xQRFz:not(:disabled):hover svg * {
  fill: #909090;
}
.styles_ticketRowPhoto__3S-DG .styles_downloadAttachmentButton__xQRFz:not(:disabled):hover[background],
.styles_ticketRowPhoto__3S-DG .styles_downloadAttachmentButton__xQRFz:not(:disabled):hover [background] {
  border-color: white;
  background-color: white;
}
[data-whatintent=keyboard] .styles_ticketRowPhoto__3S-DG .styles_downloadAttachmentButton__xQRFz:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_ticketRowPhoto__3S-DG .styles_downloadAttachmentButton__xQRFz:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_ticketRowPhoto__3S-DG .styles_downloadAttachmentButton__xQRFz:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_ticketRowPhoto__3S-DG .styles_downloadAttachmentButton__xQRFz.styles_hidden__l5nxh {
  opacity: 0;
}

.styles_closeIconThumbnail__2xk4H {
  width: 24px;
  height: 24px;
}
.styles_closeIconThumbnail__2xk4H > svg {
  position: relative;
  top: 2px;
  left: 3px;
}

.styles_timesInputCont__Mo43e.styles_ticketPageDisplayContainer__36txt {
  margin: 1rem;
}

.styles_ticketFormColumns__2DJhS {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.styles_ticketFormButtonColumns__fDbg8 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
  height: 150px;
  margin-bottom: -15px;
}

.styles_ticketUploadControls__hUeQ- {
  margin: 0.35rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, auto));
  grid-auto-rows: minmax(20px, auto);
  align-items: center;
  grid-gap: 0.5rem;
}

.styles_signoffContainer__3aPxT {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.5rem;
  align-items: center;
  align-self: flex-start;
}

.styles_signoffButton__37hut {
  color: #000000;
  margin-bottom: 9px;
}
.styles_signoffButton__37hut svg * {
  fill: #000000;
}
.styles_signoffButton__37hut[background],
.styles_signoffButton__37hut [background] {
  border-color: #D8D8D8;
  background-color: #FFFFFF;
}
.styles_signoffButton__37hut:not(:disabled):hover {
  color: #1a1a1a;
}
.styles_signoffButton__37hut:not(:disabled):hover svg * {
  fill: #1a1a1a;
}
.styles_signoffButton__37hut:not(:disabled):hover[background],
.styles_signoffButton__37hut:not(:disabled):hover [background] {
  border-color: #f2f2f2;
  background-color: white;
}
[data-whatintent=keyboard] .styles_signoffButton__37hut:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_signoffButton__37hut:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_signoffButton__37hut:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_ticketUpload__3Ixfc {
  display: inline-grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 0.5rem;
  margin-top: 0.35rem;
  margin-bottom: 0.35rem;
}
.styles_ticketUpload__3Ixfc.styles_ticketUploaded__16VRS {
  grid-template-columns: auto;
}

.styles_spinnerContainer__1CwtC {
  opacity: 0;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.35rem;
  align-items: flex-start;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}
.styles_spinnerContainer__1CwtC .styles_spinner__3-JDe {
  padding-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.styles_spinnerContainer__1CwtC .styles_spinner__3-JDe:after {
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: calc(1rem/10) solid #F6CC46;
  border-color: #F6CC46 transparent;
  -webkit-animation: styles_loadingSpinnerAnimation__1HHlp 1.2s linear infinite;
          animation: styles_loadingSpinnerAnimation__1HHlp 1.2s linear infinite;
}
@keyframes styles_loadingSpinnerAnimation__1HHlp {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.styles_spinnerContainer__1CwtC.styles_visible__2rlVk {
  opacity: 1;
}

.styles_paddedHeader__19_ST {
  padding-bottom: 1rem;
}

.styles_ticketTitleContainer__2o7Xl {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
  padding-bottom: 1rem;
}
.styles_ticketTitleContainer__2o7Xl .styles_ticketPill__2ZXFi {
  color: #4A4A4A;
  background-color: #EEEEEE;
  border: 1px solid #9B9B9B;
}
.styles_ticketTitleContainer__2o7Xl .styles_ticketPill__2ZXFi svg * {
  fill: #9B9B9B;
}

.styles_deleteTicketsButtonParent__166y0 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_deleteTicketButton__1uGbb {
  margin: 40px 0 50px 0;
  color: #E2164A;
}
.styles_deleteTicketButton__1uGbb svg * {
  fill: #E2164A;
}
.styles_deleteTicketButton__1uGbb[background],
.styles_deleteTicketButton__1uGbb [background] {
  border-color: #D8D8D8;
  background-color: #E2164A;
}
.styles_deleteTicketButton__1uGbb:not(:disabled):hover {
  color: #ec3f6b;
}
.styles_deleteTicketButton__1uGbb:not(:disabled):hover svg * {
  fill: #ec3f6b;
}
.styles_deleteTicketButton__1uGbb:not(:disabled):hover[background],
.styles_deleteTicketButton__1uGbb:not(:disabled):hover [background] {
  border-color: #f2f2f2;
  background-color: #ec3f6b;
}
[data-whatintent=keyboard] .styles_deleteTicketButton__1uGbb:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_deleteTicketButton__1uGbb:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_deleteTicketButton__1uGbb:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_stickyMenuToBottom__3LaQm {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  background-color: white;
}

.styles_bottomStickyDivider__KdsV_ {
  margin: 0 -4px 4px -4px;
}

.styles_divider__1P3b8 {
  margin-bottom: 18px;
}

.styles_notesMargin__3oMhk {
  margin-top: 18px;
}

.styles_ticketImageContainer__2y-Dd {
  position: relative;
}

.styles_removePhotoButton__3O12g {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  padding: 6px;
  border: 1px solid #FFFFFF;
}
.styles_removePhotoButton__3O12g svg * {
  fill: #FFFFFF;
}
.styles_removePhotoButton__3O12g svg {
  width: 36px;
  height: 36px;
}

.styles_inputContainer__1Ftzy {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.styles_inputContainer__1Ftzy .styles_inputStyles__2orki {
  flex: 1 0 41%;
  margin-right: 16px;
}
.styles_inputContainer__1Ftzy .styles_inputStylesMaxed__3c1tJ {
  flex: 1 0 61%;
  margin-right: 15px;
}

.styles_subtitle__hnx4v {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 20px;
  font-weight: 600;
}

.styles_subtitle__hnx4v {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 20px;
  font-weight: 600;
}

.styles_addAttachmentButton__1Ibvi {
  margin-bottom: 1rem;
  color: #106FEE;
}
.styles_addAttachmentButton__1Ibvi svg * {
  fill: #106FEE;
}
.styles_addAttachmentButton__1Ibvi[background],
.styles_addAttachmentButton__1Ibvi [background] {
  border-color: transparent;
  background-color: #FFFFFF;
}
.styles_addAttachmentButton__1Ibvi:not(:disabled):hover {
  color: #3f8cf2;
}
.styles_addAttachmentButton__1Ibvi:not(:disabled):hover svg * {
  fill: #3f8cf2;
}
.styles_addAttachmentButton__1Ibvi:not(:disabled):hover[background],
.styles_addAttachmentButton__1Ibvi:not(:disabled):hover [background] {
  border-color: rgba(26, 26, 26, 0);
  background-color: white;
}
[data-whatintent=keyboard] .styles_addAttachmentButton__1Ibvi:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_addAttachmentButton__1Ibvi:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_addAttachmentButton__1Ibvi:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
@media only screen and (min-width: 1010px) {
  .styles_addAttachmentButton__1Ibvi {
    margin-bottom: 0;
  }
}
.styles_addAttachmentButton__1Ibvi:disabled {
  color: #6fa8f5;
}
.styles_addAttachmentButton__1Ibvi:disabled svg * {
  fill: #6fa8f5;
}
.styles_addAttachmentButton__1Ibvi:disabled[background],
.styles_addAttachmentButton__1Ibvi:disabled [background] {
  border-color: transparent;
  background-color: #FFFFFF;
}
.styles_addAttachmentButton__1Ibvi:disabled:not(:disabled):hover {
  color: #9ec5f9;
}
.styles_addAttachmentButton__1Ibvi:disabled:not(:disabled):hover svg * {
  fill: #9ec5f9;
}
.styles_addAttachmentButton__1Ibvi:disabled:not(:disabled):hover[background],
.styles_addAttachmentButton__1Ibvi:disabled:not(:disabled):hover [background] {
  border-color: rgba(26, 26, 26, 0);
  background-color: white;
}
[data-whatintent=keyboard] .styles_addAttachmentButton__1Ibvi:disabled:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_addAttachmentButton__1Ibvi:disabled:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_addAttachmentButton__1Ibvi:disabled:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_textTitle__QbsoZ {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__31CV1 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__3JliK {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__3J4yz {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__3Tot9 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__2hTVD {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__229UM {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__3UpQe {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__3vqWo {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__OM_Z7 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__x0-SH {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__OHkP1 {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__1IT3b {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__1cLfq {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__nKS_O {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__21xph {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__3Podk {
  border: 1px solid #E2164A;
}

.styles_stopLoggingModalButtons__3PzuL {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
  margin-bottom: 0;
}

.styles_overlay__3VQOL {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1500;
  justify-content: center;
  align-items: center;
}

.styles_modal__5BlBf {
  position: relative;
  padding: 1rem;
  outline: none;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  box-shadow: none;
  background: rgba(151, 151, 151, 0.8);
  width: 100%;
  height: 100%;
}

.styles_deleteAttachmentButton__EgDZJ {
  margin-bottom: 8px;
  height: 55px;
  background-color: white;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  color: #FF3B30;
  font-size: 17px;
  font-weight: 400;
  border-radius: 15px;
}

.styles_cancelButton__20SDS {
  height: 55px;
  background-color: white;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  color: #007AFF;
  font-size: 17px;
  font-weight: 600;
  border-radius: 15px;
}
.styles_textTitle__1RTh8 {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__3GKh- {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__13MvO {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__TQDwX {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__3HGLP {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__At_zh {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__4_EHg {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__140KO {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__1z635 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel___zP60 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__2jpGb {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__31Mzh {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__1MmKA {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__3ymfW {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__3qQrn {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__3cbht {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__z3VDC {
  border: 1px solid #E2164A;
}

.styles_overrideStyles__1vSjG {
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  padding: 0.5rem;
  height: 2.5rem;
  line-height: normal;
  width: 100%;
  display: flex;
  align-items: center;
}
.styles_overrideStyles__1vSjG > div {
  width: 100%;
}
.styles_overrideStyles__1vSjG input {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  padding: 0;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}
.styles_overrideStyles__1vSjG > div > div::after, .styles_overrideStyles__1vSjG > div > div::before {
  display: none;
}
.styles_textTitle__3-9Pd {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__nVAxt {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__Jm7f7 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__1j8Qi {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__28weq {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__1dUlm {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__2Y4TO {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__2_pMF {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__2y9Li {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__rEgeW {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__7VVaH {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__19coQ {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__cq9tH {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__lG2NE {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__1pUEV {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__1Eqd- {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__2ss_Q {
  border: 1px solid #E2164A;
}

.styles_cardDivider__1nKEa {
  border-bottom: 1.85px solid #EEEEEE;
}

.styles_cardStyle__2zy_i {
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 1rem;
}
.styles_textTitle__1ajOl {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__SDHTl {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__3fvNx {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__3u_Ve {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__2ImPn {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__3vGO- {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__2UlRS {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__3FMr1 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__1kfNF {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__1tceo {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__1RbCu {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__1UG3G {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__3mFc5 {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__2dbMR {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__2zlG7 {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__ZiZGJ {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__b5d2- {
  border: 1px solid #E2164A;
}

.styles_page__1EHCF {
  display: none;
  background-color: #FAFAFA;
  height: 100%;
}
.styles_page__1EHCF.styles_fullScreen__1QCwN {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}
.styles_page__1EHCF.styles_visible__2PqxC {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
}

.styles_pageHeader__13eQd {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-bottom: 1px solid #EEEEEE;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_headerButton__2NWLe [background] {
  height: 1.75rem;
  width: 1.75rem;
}
.styles_headerButton__2NWLe.styles_hidden__2lhMM {
  visibility: hidden;
  pointer-events: none;
}

.styles_pageBody__3kARz {
  overflow: auto;
}

.styles_navMiddleTitle__9qBL8 {
  font-size: 1rem;
  font-weight: 400;
}

.styles_bottomGutter__3HkQg {
  margin: 0;
  height: 5rem;
  padding: 0;
}
.styles_textTitle__17t2c {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__3DVW6 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__3GSoC {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__1j9TY {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__1nkuG {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__Blciw {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__7xVIm {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__1PMIO {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__3ldY3 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__3I-Bt {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__KHS30 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__2exQp {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__2exQX {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__1Zi5R {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__1zteO {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__7b6hy {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__s6USX {
  border: 1px solid #E2164A;
}

.styles_signaturePage__4HuDT {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #FFFFFF;
  z-index: 5;
  padding: 1rem;
}
.styles_signaturePage__4HuDT .styles_signaturePageHeader__UeS7x {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.styles_signaturePage__4HuDT .styles_signatureInputContainer__3gpJm {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
@media screen and (max-aspect-ratio: 13/9) {
  .styles_signaturePage__4HuDT .styles_signatureInputContainer__3gpJm {
    flex-direction: column;
  }
}
.styles_signaturePage__4HuDT .styles_signatureCanvasContainer__r03K8 {
  border-bottom: 1px solid #9B9B9B;
}
.styles_signaturePage__4HuDT .styles_signatureButtonsContainer__2BIK0 {
  display: grid;
  grid-auto-flow: row;
  margin: 1rem 0 0 1rem;
  grid-gap: 1rem;
}
@media screen and (max-aspect-ratio: 13/9) {
  .styles_signaturePage__4HuDT .styles_signatureButtonsContainer__2BIK0 {
    grid-auto-flow: column;
  }
}
.styles_signaturePage__4HuDT .styles_requiredText__191Fs {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #E2164A;
  font-size: 0.85rem;
  font-weight: 600;
}
.styles_signaturePage__4HuDT .styles_wiggle__2VH5Z {
  display: flex;
  -webkit-animation: styles_wiggleAnimation__2bPLJ 0.5s ease-in-out infinite alternate;
          animation: styles_wiggleAnimation__2bPLJ 0.5s ease-in-out infinite alternate;
}
@-webkit-keyframes styles_wiggleAnimation__2bPLJ {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
}
@keyframes styles_wiggleAnimation__2bPLJ {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
}

.styles_signoffPage__2oGPY {
  padding: 1rem;
}
.styles_signoffPage__2oGPY .styles_signoffPageHeader__3Xi-9 {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}
.styles_signoffPage__2oGPY .styles_signoffPageHeader__3Xi-9 svg {
  width: 1.5rem;
  height: 1.5rem;
}
.styles_signoffPage__2oGPY .styles_signoffPageText__1yfN5 {
  margin-bottom: 1rem;
}
.styles_signoffPage__2oGPY .styles_signoffInfo__1g-uK {
  margin: 1rem 0.35rem 3rem;
  border-radius: 9px;
  padding: 1rem;
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}
.styles_signoffPage__2oGPY .styles_signoffInfoWrapper__1vpzZ {
  overflow-y: scroll;
}

.styles_getSignoffPage__39Mr8 {
  display: grid;
  height: 100%;
  grid-template-rows: 8fr 1fr;
  overflow: auto;
}
.styles_getSignoffPage__39Mr8 .styles_addSignatureButton__1IAfi {
  color: #FFFFFF;
}
.styles_getSignoffPage__39Mr8 .styles_addSignatureButton__1IAfi svg * {
  fill: #FFFFFF;
}
.styles_getSignoffPage__39Mr8 .styles_addSignatureButton__1IAfi[background],
.styles_getSignoffPage__39Mr8 .styles_addSignatureButton__1IAfi [background] {
  border-color: #1E9F76;
  background-color: #1E9F76;
}
.styles_getSignoffPage__39Mr8 .styles_addSignatureButton__1IAfi:not(:disabled):hover {
  color: white;
}
.styles_getSignoffPage__39Mr8 .styles_addSignatureButton__1IAfi:not(:disabled):hover svg * {
  fill: white;
}
.styles_getSignoffPage__39Mr8 .styles_addSignatureButton__1IAfi:not(:disabled):hover[background],
.styles_getSignoffPage__39Mr8 .styles_addSignatureButton__1IAfi:not(:disabled):hover [background] {
  border-color: #26ca96;
  background-color: #26ca96;
}
[data-whatintent=keyboard] .styles_getSignoffPage__39Mr8 .styles_addSignatureButton__1IAfi:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_getSignoffPage__39Mr8 .styles_addSignatureButton__1IAfi:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_getSignoffPage__39Mr8 .styles_addSignatureButton__1IAfi:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_getSignoffPage__39Mr8 .styles_addSignatureButtonWrapper__3VHSc {
  z-index: 2;
  box-shadow: 0 0 2rem 3rem #FFFFFF;
  padding: 0 1.5rem;
}
.styles_getSignoffPage__39Mr8 .styles_signoffVerificationText__3_eQW {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 1rem;
  font-weight: 400;
}
.styles_textTitle__3sG9g {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__2KdXf {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__3WCIQ {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__26lhG {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__IAC3b {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__WjCwi {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__1eHiD {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__3pKxa {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__WhIym {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__aJWy2 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__2yr2h {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__1xeY3 {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__CdCo8 {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__K4Hcm {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__3ePSF {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__2bAr7 {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__JVz-i {
  border: 1px solid #E2164A;
}

.styles_acceptJobSection__gQLj_ {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.35rem;
  padding: 0.5rem;
}

.styles_modalButtons__3BxSX {
  margin: 0.5rem 0;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0.35rem;
}
.styles_textCommon__VxTo1, .styles_textBold__1L81p, .styles_textSecondary__2vGxj, .styles_textDefault__2whqi {
  line-height: 1.4em;
}

.styles_textDefault__2whqi {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__2vGxj {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__1L81p {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__1WIEI {
  font-weight: 700;
}

.styles_alignRight__3iE3n {
  text-align: right;
}

.styles_alignCenter__1v4kY {
  text-align: center;
}

.styles_tagLabel__3fKYk {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__1vMYP {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__1ByQF {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__1WRTY {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__2QkD7,
.styles_textHeading__2Jozx,
.styles_textSubheading__1NM6t {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__vEvf_ {
  color: #9b9b9b;
}

.styles_container__3fjNC {
  position: relative;
}

.styles_padHeading__1FIcR {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__1n2FE {
  padding-top: 1rem;
}

.styles_padY__2mFAG, .styles_subtitle__2085d {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__SIYLM {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__1euMq {
  margin-top: 1rem;
}

.styles_marginY__WKqVl {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__2CFJo {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__1vgus {
  margin: 0;
  padding: 0;
}

.styles_wrapper__juQJ9 {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__juQJ9 > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__1uLtw {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__1uLtw > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__2bc-g {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__2bc-g > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle___0nZz {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle___0nZz > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__2d-rI, .styles_subtitle__2085d {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__2d-rI > *, .styles_subtitle__2085d > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__9ahly {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__9ahly > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__2QkD7,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__2Jozx,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__1NM6t,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__FlYz6 {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__1qRmC {
    padding: 1rem;
  }
  .styles_hideMedium__gzvMI {
    display: none !important;
  }
  .styles_hideLarge__1AUzo {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__1qRmC {
    padding: 1rem;
  }
  .styles_subtitle__2085d {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__1imEA {
    display: none !important;
  }
  .styles_rowResize__2BWbp > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__2BWbp > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__1qRmC {
    padding: 0.5rem;
  }
  .styles_padX__SIYLM {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__2whqi {
    font-size: 1.2rem;
  }
  .styles_textSecondary__2vGxj {
    font-size: 1.02rem;
  }
  .styles_textBold__1L81p {
    font-size: 1.2rem;
  }
  .styles_textHint__1vMYP {
    font-size: 0.84rem;
  }
  .styles_textLabel__1ByQF {
    font-size: 1.2rem;
  }
  .styles_navLabel__1WRTY {
    font-size: 0.84rem;
  }
  .styles_tagLabel__3fKYk {
    font-size: 0.85rem;
  }
}
.styles_stopLoggingModalButtons__3Bt8K {
  margin-top: 1rem;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0.5rem;
}
.styles_textCommon__3Myzv, .styles_textBold__1qbeB, .styles_textSecondary__34WZe, .styles_textDefault__2kL0n {
  line-height: 1.4em;
}

.styles_textDefault__2kL0n {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__34WZe {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__1qbeB {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__2Pitv {
  font-weight: 700;
}

.styles_alignRight__30M1L {
  text-align: right;
}

.styles_alignCenter__-bXrT {
  text-align: center;
}

.styles_tagLabel__3hh_8 {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__1wm2Y {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__2B01P {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__wSN1U {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__1Jqc5,
.styles_textHeading__2L_KT,
.styles_textSubheading__1cPe4 {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__3StR2 {
  color: #9b9b9b;
}

.styles_container__31Jko {
  position: relative;
}

.styles_padHeading__3s8qT {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__Y-jHH {
  padding-top: 1rem;
}

.styles_padY__23NZ7, .styles_subtitle__afCFm {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__1UFPQ {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__2w9yH {
  margin-top: 1rem;
}

.styles_marginY__1ZiIV {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__2KWeC {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__1_0Ph {
  margin: 0;
  padding: 0;
}

.styles_wrapper__3csSF {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__3csSF > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__3k5RW {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__3k5RW > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__V8tmO {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__V8tmO > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__1lxdL {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__1lxdL > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__3-qkW, .styles_subtitle__afCFm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__3-qkW > *, .styles_subtitle__afCFm > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__1qQR_ {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__1qQR_ > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__1Jqc5,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__2L_KT,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__1cPe4,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__26bP3 {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__4vg6W {
    padding: 1rem;
  }
  .styles_hideMedium__1yF_z {
    display: none !important;
  }
  .styles_hideLarge__3nuMs {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__4vg6W {
    padding: 1rem;
  }
  .styles_subtitle__afCFm {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__2LC5Z {
    display: none !important;
  }
  .styles_rowResize__1ktZ4 > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__1ktZ4 > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__4vg6W {
    padding: 0.5rem;
  }
  .styles_padX__1UFPQ {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__2kL0n {
    font-size: 1.2rem;
  }
  .styles_textSecondary__34WZe {
    font-size: 1.02rem;
  }
  .styles_textBold__1qbeB {
    font-size: 1.2rem;
  }
  .styles_textHint__1wm2Y {
    font-size: 0.84rem;
  }
  .styles_textLabel__2B01P {
    font-size: 1.2rem;
  }
  .styles_navLabel__wSN1U {
    font-size: 0.84rem;
  }
  .styles_tagLabel__3hh_8 {
    font-size: 0.85rem;
  }
}
.styles_textTitle__1Jqc5 {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__3yRAD {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__1skZn {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__AwtMk {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__342DI {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__3Dot5 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__3UGHz {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__1qbeB {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__2Fp6F {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__2ofLu {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__1XX0t {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__25TUW {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__1IEww {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__1S-jl {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__2D3PC {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__3X36y {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__3FXEP {
  border: 1px solid #E2164A;
}

.styles_stackedBtnContainer__awctv {
  margin-top: 1rem;
}
.styles_stackedBtnContainer__awctv button {
  margin-bottom: 0.5rem;
}

.styles_logJobButton__2zjZa {
  color: #FFFFFF;
}
.styles_logJobButton__2zjZa svg * {
  fill: #FFFFFF;
}
.styles_logJobButton__2zjZa[background],
.styles_logJobButton__2zjZa [background] {
  border-color: #FFFFFF;
  background-color: #FFFFFF;
}
.styles_logJobButton__2zjZa:not(:disabled):hover {
  color: white;
}
.styles_logJobButton__2zjZa:not(:disabled):hover svg * {
  fill: white;
}
.styles_logJobButton__2zjZa:not(:disabled):hover[background],
.styles_logJobButton__2zjZa:not(:disabled):hover [background] {
  border-color: white;
  background-color: white;
}
[data-whatintent=keyboard] .styles_logJobButton__2zjZa:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_logJobButton__2zjZa:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_logJobButton__2zjZa:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_textCommon__3JyPc, .styles_textBold__1Gib2, .styles_textSecondary__248h4, .styles_textDefault__2SuvQ {
  line-height: 1.4em;
}

.styles_textDefault__2SuvQ {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__248h4 {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__1Gib2 {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__2SRtL {
  font-weight: 700;
}

.styles_alignRight__2NALy {
  text-align: right;
}

.styles_alignCenter__31wcP {
  text-align: center;
}

.styles_tagLabel__2i5W8 {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__2QLyy {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__i5I_D {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__3qX4Y {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__Ntbr4,
.styles_textHeading__WDGCB,
.styles_textSubheading__3vMmd {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__Ut9cd {
  color: #9b9b9b;
}

.styles_container__2zde2 {
  position: relative;
}

.styles_padHeading__G0bYv {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__3iOza {
  padding-top: 1rem;
}

.styles_padY__39YI-, .styles_subtitle__1T4Sp {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__KgRQx {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__3VgGw {
  margin-top: 1rem;
}

.styles_marginY__2DV2k {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__2uBld {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__1Inai {
  margin: 0;
  padding: 0;
}

.styles_wrapper__2ooMY {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__2ooMY > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__11xEH {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__11xEH > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__2Pyj_ {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__2Pyj_ > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__1YzNx {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__1YzNx > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__3BL8C, .styles_subtitle__1T4Sp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__3BL8C > *, .styles_subtitle__1T4Sp > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__7DEC6 {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__7DEC6 > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__Ntbr4,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__WDGCB,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__3vMmd,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__FnGuI {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__3ESml {
    padding: 1rem;
  }
  .styles_hideMedium__CN6kk {
    display: none !important;
  }
  .styles_hideLarge__1AJjD {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__3ESml {
    padding: 1rem;
  }
  .styles_subtitle__1T4Sp {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__dpqdf {
    display: none !important;
  }
  .styles_rowResize__gUbdC > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__gUbdC > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__3ESml {
    padding: 0.5rem;
  }
  .styles_padX__KgRQx {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__2SuvQ {
    font-size: 1.2rem;
  }
  .styles_textSecondary__248h4 {
    font-size: 1.02rem;
  }
  .styles_textBold__1Gib2 {
    font-size: 1.2rem;
  }
  .styles_textHint__2QLyy {
    font-size: 0.84rem;
  }
  .styles_textLabel__i5I_D {
    font-size: 1.2rem;
  }
  .styles_navLabel__3qX4Y {
    font-size: 0.84rem;
  }
  .styles_tagLabel__2i5W8 {
    font-size: 0.85rem;
  }
}
.styles_textTitle__Ntbr4 {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__1YSiC {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__1RpcA {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__2GSed {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__3dUSR {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__1tW1X {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__3n8w1 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__1Gib2 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__32wrm {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__2C2dB {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__nwFV1 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__3sTIg {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__1tqig {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__2woCv {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__1cnJ- {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__3CtNd {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__19lv4 {
  border: 1px solid #E2164A;
}

.styles_textTitle__Ntbr4 {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__1YSiC {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__1RpcA {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__2GSed {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__3dUSR {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__1tW1X {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__3n8w1 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__1Gib2 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__32wrm {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__2C2dB {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__nwFV1 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__3sTIg {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__1tqig {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__2woCv {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__1cnJ- {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__3CtNd {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__19lv4 {
  border: 1px solid #E2164A;
}

.styles_timesheetsContainer__1soXk {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.styles_emptyState__3M2C5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 2rem;
}
.styles_emptyState__3M2C5 .styles_textBold__1Gib2 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}
.styles_emptyState__3M2C5 .styles_text__lBF2N {
  color: #767676;
}

.styles_wrap__3ESml {
  padding: 1rem;
}

.styles_title__2zvPA {
  margin-bottom: 1rem;
}

.styles_paginationControls__2fy-P .rc-pagination {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  -webkit-padding-start: 0px !important;
          padding-inline-start: 0px !important;
  list-style-type: none !important;
}
.styles_paginationControls__2fy-P .rc-pagination-prev,
.styles_paginationControls__2fy-P .rc-pagination-next {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.styles_paginationControls__2fy-P .rc-pagination-item {
  border: 0;
  position: relative;
  cursor: pointer;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}
.styles_paginationControls__2fy-P .rc-pagination-item a {
  color: #000000;
}
.styles_paginationControls__2fy-P .rc-pagination-item:hover a {
  color: #000000;
}
.styles_paginationControls__2fy-P .rc-pagination-item-active {
  background: none;
  cursor: pointer;
}
.styles_paginationControls__2fy-P .rc-pagination-item-active a {
  color: #FFFFFF;
}
.styles_paginationControls__2fy-P .rc-pagination-item-active:hover a {
  color: #FFFFFF;
}
.styles_paginationControls__2fy-P .rc-pagination-item-active::after {
  content: "";
  width: 2rem;
  height: 2rem;
  z-index: -1;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
  background-color: #000000;
}
.styles_paginationControls__2fy-P .rc-pagination-disabled a {
  color: #EEEEEE;
}

.styles_loadingSpinnerContainer__3pvGv {
  margin-top: 5rem;
  height: calc(4rem + 2 * 1rem);
  width: 100%;
}

.styles_loadingSpinner__3AwNY {
  margin: 1rem;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-75%);
          transform: translateX(-75%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.styles_loadingSpinner__3AwNY:after {
  content: "";
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: calc(4rem/10) solid #F6CC46;
  border-color: #F6CC46 transparent;
  -webkit-animation: styles_loadingSpinnerAnimation__2wJNM 1.2s linear infinite;
          animation: styles_loadingSpinnerAnimation__2wJNM 1.2s linear infinite;
}
@-webkit-keyframes styles_loadingSpinnerAnimation__2wJNM {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes styles_loadingSpinnerAnimation__2wJNM {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.styles_textCommon__IIMRK, .styles_textBold__2g899, .styles_textSecondary__3IPgb, .styles_textDefault__2WnGu {
  line-height: 1.4em;
}

.styles_textDefault__2WnGu {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__3IPgb {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__2g899 {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__1HtI_ {
  font-weight: 700;
}

.styles_alignRight__2M63T {
  text-align: right;
}

.styles_alignCenter__KADpb {
  text-align: center;
}

.styles_tagLabel__2b-kg {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__1p_XN {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__3Y1P4 {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__z8Q0E {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__1Fhvr,
.styles_textHeading__1BJfw,
.styles_textSubheading__3aZAU {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__2vtrS {
  color: #9b9b9b;
}

.styles_container__3BRp0 {
  position: relative;
}

.styles_padHeading__Hfm6x {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__1zwA0 {
  padding-top: 1rem;
}

.styles_padY__TVRvp, .styles_subtitle__1L8bw {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__2LoBc {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__385-4 {
  margin-top: 1rem;
}

.styles_marginY__2vck9 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__4gtF_ {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__3VMig {
  margin: 0;
  padding: 0;
}

.styles_wrapper__1FmQo {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__1FmQo > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__25WxE {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__25WxE > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__-qt9W {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__-qt9W > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__17Guj {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__17Guj > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__TR55A, .styles_subtitle__1L8bw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__TR55A > *, .styles_subtitle__1L8bw > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__1Ntwy {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__1Ntwy > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__1Fhvr,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__1BJfw,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__3aZAU,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__3yeSp {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__yJsjo {
    padding: 1rem;
  }
  .styles_hideMedium__3JA1H {
    display: none !important;
  }
  .styles_hideLarge__3a1xe {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__yJsjo {
    padding: 1rem;
  }
  .styles_subtitle__1L8bw {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__1us3c {
    display: none !important;
  }
  .styles_rowResize__tvO2j > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__tvO2j > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__yJsjo {
    padding: 0.5rem;
  }
  .styles_padX__2LoBc {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__2WnGu {
    font-size: 1.2rem;
  }
  .styles_textSecondary__3IPgb {
    font-size: 1.02rem;
  }
  .styles_textBold__2g899 {
    font-size: 1.2rem;
  }
  .styles_textHint__1p_XN {
    font-size: 0.84rem;
  }
  .styles_textLabel__3Y1P4 {
    font-size: 1.2rem;
  }
  .styles_navLabel__z8Q0E {
    font-size: 0.84rem;
  }
  .styles_tagLabel__2b-kg {
    font-size: 0.85rem;
  }
}
.styles_textTitle__1Fhvr {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__2SV4Y {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__Cuk6K {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__1vfzo {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__3vW7D {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__39Iqv {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__4oVEp {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__2g899 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__3Jo9v {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__3taeN {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__1XUZo {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__ZfHxn {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__1bWKD {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__3T5va {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__3FZfH {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__j9CAR {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__3IlHp {
  border: 1px solid #E2164A;
}

.styles_timesheetsContainer__2BCfE {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.styles_title__11ie0 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}
.styles_textCommon__wGOp1, .styles_textBold__lhcHK, .styles_textSecondary__3QimF, .styles_textDefault__3cJy_ {
  line-height: 1.4em;
}

.styles_textDefault__3cJy_ {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__3QimF {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__lhcHK {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__2SZ0g {
  font-weight: 700;
}

.styles_alignRight__3GGN1 {
  text-align: right;
}

.styles_alignCenter__3q61m {
  text-align: center;
}

.styles_tagLabel__ofw-6 {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__3PsYJ {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__2U29N {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__2TaBS {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__1ch13,
.styles_textHeading__2JKxy,
.styles_textSubheading__j6xYr {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__3lpjE {
  color: #9b9b9b;
}

.styles_container__1q_5e {
  position: relative;
}

.styles_padHeading__2KJLl {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__3X0K0 {
  padding-top: 1rem;
}

.styles_padY__23pfE, .styles_subtitle__30M8F {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__2eY2r {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__1p-t8 {
  margin-top: 1rem;
}

.styles_marginY__35lOe {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__1urDC {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__26tbj {
  margin: 0;
  padding: 0;
}

.styles_wrapper__3NvFU {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__3NvFU > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__3OP31 {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__3OP31 > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__28CkJ {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__28CkJ > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__1uI_V {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__1uI_V > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__27ZqK, .styles_subtitle__30M8F {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__27ZqK > *, .styles_subtitle__30M8F > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__38pNb {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__38pNb > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__1ch13,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__2JKxy,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__j6xYr,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__31hig {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__1YVxl {
    padding: 1rem;
  }
  .styles_hideMedium__1cJ7e {
    display: none !important;
  }
  .styles_hideLarge__2a92F {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__1YVxl {
    padding: 1rem;
  }
  .styles_subtitle__30M8F {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__1iyXw {
    display: none !important;
  }
  .styles_rowResize__1oyee > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__1oyee > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__1YVxl {
    padding: 0.5rem;
  }
  .styles_padX__2eY2r {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__3cJy_ {
    font-size: 1.2rem;
  }
  .styles_textSecondary__3QimF {
    font-size: 1.02rem;
  }
  .styles_textBold__lhcHK {
    font-size: 1.2rem;
  }
  .styles_textHint__3PsYJ {
    font-size: 0.84rem;
  }
  .styles_textLabel__2U29N {
    font-size: 1.2rem;
  }
  .styles_navLabel__2TaBS {
    font-size: 0.84rem;
  }
  .styles_tagLabel__ofw-6 {
    font-size: 0.85rem;
  }
}
.styles_textTitle__1ch13 {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__3XIz- {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__35gyL {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__1Aarw {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__2JOt1 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__39KLx {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__3qFJ2 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__lhcHK {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__2gSbp {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__11Hu7 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__2z6NH {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__2Pu0v {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__1jV_G {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__3FKu6 {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__VchmI {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__sQG0N {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__13opE {
  border: 1px solid #E2164A;
}

.styles_timesheetCardContainer__39IZl {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  border: 2px solid #eeeeee;
  border-radius: 9px;
}
.styles_timesheetCardContainer__39IZl .styles_companyText__2kArX {
  font-weight: 300;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.styles_timesheetCardContainer__39IZl .styles_clock__RM9CF {
  width: 8rem;
}
.styles_timesheetCardContainer__39IZl .styles_timeText__2JCNl {
  font-size: 1.5rem;
  font-weight: 600;
}
.styles_timesheetCardContainer__39IZl .styles_dropOffText__3_Udt {
  font-weight: 300;
  font-size: 1.1rem;
  padding-bottom: 0.5rem;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetBlackButton__2D_Em {
  color: #FFFFFF;
  border-radius: 9px;
  height: 3rem;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetBlackButton__2D_Em svg * {
  fill: #106FEE;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetBlackButton__2D_Em[background],
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetBlackButton__2D_Em [background] {
  border-color: #D8D8D8;
  background-color: #000000;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetBlackButton__2D_Em:not(:disabled):hover {
  color: white;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetBlackButton__2D_Em:not(:disabled):hover svg * {
  fill: #3f8cf2;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetBlackButton__2D_Em:not(:disabled):hover[background],
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetBlackButton__2D_Em:not(:disabled):hover [background] {
  border-color: #f2f2f2;
  background-color: #1a1a1a;
}
[data-whatintent=keyboard] .styles_timesheetCardContainer__39IZl .styles_viewTimesheetBlackButton__2D_Em:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_timesheetCardContainer__39IZl .styles_viewTimesheetBlackButton__2D_Em:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetBlackButton__2D_Em:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_check__hWF86 {
  color: #FFFFFF;
  border-radius: 32px;
  height: 2.5rem;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_check__hWF86 svg * {
  fill: #258750;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_check__hWF86[background],
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_check__hWF86 [background] {
  border-color: #258750;
  background-color: #258750;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_check__hWF86:not(:disabled):hover {
  color: white;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_check__hWF86:not(:disabled):hover svg * {
  fill: #30af68;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_check__hWF86:not(:disabled):hover[background],
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_check__hWF86:not(:disabled):hover [background] {
  border-color: #30af68;
  background-color: #30af68;
}
[data-whatintent=keyboard] .styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_check__hWF86:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_check__hWF86:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_check__hWF86:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_button__3_enJ {
  color: #490BF4;
  border-radius: 32px;
  height: 2.5rem;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_button__3_enJ svg * {
  fill: #490BF4;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_button__3_enJ[background],
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_button__3_enJ [background] {
  border-color: #FFFFFF;
  background-color: #FFFFFF;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_button__3_enJ:not(:disabled):hover {
  color: #6d3cf6;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_button__3_enJ:not(:disabled):hover svg * {
  fill: #6d3cf6;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_button__3_enJ:not(:disabled):hover[background],
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_button__3_enJ:not(:disabled):hover [background] {
  border-color: white;
  background-color: white;
}
[data-whatintent=keyboard] .styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_button__3_enJ:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_button__3_enJ:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_button__3_enJ:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignedOff__1fqfh .styles_button__3_enJ svg path {
  fill: none !important;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_pen__34UyH {
  color: #4A4A4A;
  border-radius: 32px;
  height: 2.5rem;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_pen__34UyH svg * {
  fill: #490BF4;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_pen__34UyH[background],
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_pen__34UyH [background] {
  border-color: #F9F3FF;
  background-color: #F9F3FF;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_pen__34UyH:not(:disabled):hover {
  color: #646464;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_pen__34UyH:not(:disabled):hover svg * {
  fill: #6d3cf6;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_pen__34UyH:not(:disabled):hover[background],
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_pen__34UyH:not(:disabled):hover [background] {
  border-color: white;
  background-color: white;
}
[data-whatintent=keyboard] .styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_pen__34UyH:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_pen__34UyH:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_pen__34UyH:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_button__3_enJ {
  color: #490BF4;
  border-radius: 32px;
  height: 2.5rem;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_button__3_enJ svg * {
  fill: #490BF4;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_button__3_enJ[background],
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_button__3_enJ [background] {
  border-color: #FFFFFF;
  background-color: #FFFFFF;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_button__3_enJ:not(:disabled):hover {
  color: #6d3cf6;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_button__3_enJ:not(:disabled):hover svg * {
  fill: #6d3cf6;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_button__3_enJ:not(:disabled):hover[background],
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_button__3_enJ:not(:disabled):hover [background] {
  border-color: white;
  background-color: white;
}
[data-whatintent=keyboard] .styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_button__3_enJ:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_button__3_enJ:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_button__3_enJ:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_timesheetCardContainer__39IZl .styles_viewTimesheetSignOffRequested__3zcXy .styles_button__3_enJ svg path {
  fill: none !important;
}
.styles_timesheetCardContainer__39IZl a {
  width: 100%;
}
.styles_textTitle__29vNS {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__3j6y5 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__1L6H4 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__l_o-U {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__zxGPm {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__2Xugq {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__qemed {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__3GwTC {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__2fsMM {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__1jGs5 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__3zBcV {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__36t7S {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__13oMG {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__32oaF {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__2tqUP {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__13RQ- {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__2CMpD {
  border: 1px solid #E2164A;
}

.styles_bottomSheet__2f1h7 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  pointer-events: none;
  overflow: hidden;
  z-index: 99;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_bottomSheet__2f1h7.styles_visible__2Ap3L {
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.55);
}
.styles_bottomSheet__2f1h7.styles_visible__2Ap3L .styles_bottomSheetContent__1_SKE {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.styles_bottomSheet__2f1h7 .styles_bottomSheetContent__1_SKE {
  border-radius: 12px 12px 0 0;
  padding: 1.5rem 1rem 1rem 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFFFFF;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.styles_textCommon__2lq7-, .styles_textBold__20-8t, .styles_textSecondary__27x1m, .styles_textDefault__ULZJd {
  line-height: 1.4em;
}

.styles_textDefault__ULZJd {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__27x1m {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__20-8t {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__3a9WU {
  font-weight: 700;
}

.styles_alignRight__1yoOD {
  text-align: right;
}

.styles_alignCenter__1gsEA {
  text-align: center;
}

.styles_tagLabel__2uO1H {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__e2VsE {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__lBsoI {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__uRie3 {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__3gnmd,
.styles_textHeading__2IRze,
.styles_textSubheading__2yaQo {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__1v9uK {
  color: #9b9b9b;
}

.styles_container__3DiMf {
  position: relative;
}

.styles_padHeading__1xZ-s {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__3I0TL {
  padding-top: 1rem;
}

.styles_padY__2v9-c, .styles_subtitle__28Y_f {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__3Bt_E {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__1-WSy {
  margin-top: 1rem;
}

.styles_marginY__1ZGEh {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__3s-uS {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__2IkcA {
  margin: 0;
  padding: 0;
}

.styles_wrapper__1hQKW {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__1hQKW > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__Ib1Mq {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__Ib1Mq > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__19mSS {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__19mSS > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__1PNNB {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__1PNNB > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__29s9e, .styles_subtitle__28Y_f {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__29s9e > *, .styles_subtitle__28Y_f > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__2nygN {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__2nygN > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__3gnmd,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__2IRze,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__2yaQo,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__2Acu9 {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__3gYgu {
    padding: 1rem;
  }
  .styles_hideMedium__2wdAF {
    display: none !important;
  }
  .styles_hideLarge__2pSiB {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__3gYgu {
    padding: 1rem;
  }
  .styles_subtitle__28Y_f {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__HxLKH {
    display: none !important;
  }
  .styles_rowResize__3LW4A > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__3LW4A > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__3gYgu {
    padding: 0.5rem;
  }
  .styles_padX__3Bt_E {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__ULZJd {
    font-size: 1.2rem;
  }
  .styles_textSecondary__27x1m {
    font-size: 1.02rem;
  }
  .styles_textBold__20-8t {
    font-size: 1.2rem;
  }
  .styles_textHint__e2VsE {
    font-size: 0.84rem;
  }
  .styles_textLabel__lBsoI {
    font-size: 1.2rem;
  }
  .styles_navLabel__uRie3 {
    font-size: 0.84rem;
  }
  .styles_tagLabel__2uO1H {
    font-size: 0.85rem;
  }
}
.styles_textTitle__3gnmd {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__1s3Dn {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__1Zthq {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__27fLV {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__2Ul5R {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__2KZg8 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__aU1M8 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__20-8t {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__3WiA7 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__3o7CJ {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__1WFL8 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__1uiD- {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__3pCHJ {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__GY9F1 {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__2Jio4 {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__1kxWS {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__1B4lQ {
  border: 1px solid #E2164A;
}

.styles_genericErrorMessage__31XBg {
  color: inherit;
  font-size: 0.85rem;
  font-family: "Assistant", sans-serif;
  color: #9b9b9b;
  line-height: 1.4rem;
  margin: 0.35rem 0;
  color: #E2164A;
  border-radius: 4px;
  padding: calc(0.35rem/2) 0;
}

.styles_inputFileUpload__Q_Qqh .styles_fileUpload__2duOb {
  display: flex;
  align-items: center;
}

.styles_uploadButton__FIgPE {
  margin-left: 0.5rem;
  color: #4A4A4A;
}
.styles_uploadButton__FIgPE svg * {
  fill: #106FEE;
}
.styles_uploadButton__FIgPE[background],
.styles_uploadButton__FIgPE [background] {
  border-color: #D8D8D8;
  background-color: #FFFFFF;
}
.styles_uploadButton__FIgPE:not(:disabled):hover {
  color: #646464;
}
.styles_uploadButton__FIgPE:not(:disabled):hover svg * {
  fill: #3f8cf2;
}
.styles_uploadButton__FIgPE:not(:disabled):hover[background],
.styles_uploadButton__FIgPE:not(:disabled):hover [background] {
  border-color: #f2f2f2;
  background-color: white;
}
[data-whatintent=keyboard] .styles_uploadButton__FIgPE:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_uploadButton__FIgPE:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_uploadButton__FIgPE:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_textTitle__2c7qu {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__1X3AG {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__tyWAN {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__2w0Fr {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__OVfmQ {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__tsA0j {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__31sal {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__MTUSw {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__3dlRK {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__r_z2F {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__1S0_w {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__3mImP {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__WePLK {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__3VtP8 {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__3xVcy {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__2pbB6 {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__2wE1S {
  border: 1px solid #E2164A;
}

.styles_loadingSpinner__2GoYy {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.styles_loadingSpinner__2GoYy:after {
  content: "";
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: calc(2rem/10) solid #F6CC46;
  border-color: #F6CC46 transparent;
  -webkit-animation: styles_loadingSpinnerAnimation__1kFI3 1.2s linear infinite;
          animation: styles_loadingSpinnerAnimation__1kFI3 1.2s linear infinite;
}
@-webkit-keyframes styles_loadingSpinnerAnimation__1kFI3 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes styles_loadingSpinnerAnimation__1kFI3 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.styles_timesheetDetailContainer__2fVav {
  padding: 1rem;
}

.styles_timesheetDetailHeader__1VPte {
  margin-top: 28px;
  margin-bottom: 1rem;
}

.styles_timesheetDetailSubHeader__3C5cV {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #767676;
  font-size: 1rem;
  font-weight: 300;
}

.styles_borderedContainer__1ilvr {
  background-color: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 1rem;
}

.styles_adjustedHoursValue__3Fz-_ {
  display: flex;
  justify-content: space-between;
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 2rem;
  font-weight: 900;
}

.styles_timesheetDetailSignoffRequest__1mCUf {
  padding: 1rem;
}
.styles_timesheetDetailSignoffRequest__1mCUf .styles_timesheetDetailSignoffText__1GKte {
  text-align: center;
  margin-bottom: 12px;
}
.styles_timesheetDetailSignoffRequest__1mCUf .styles_signoffButton__35sa9 {
  color: #FFFFFF;
}
.styles_timesheetDetailSignoffRequest__1mCUf .styles_signoffButton__35sa9 svg * {
  fill: #FFFFFF;
}
.styles_timesheetDetailSignoffRequest__1mCUf .styles_signoffButton__35sa9[background],
.styles_timesheetDetailSignoffRequest__1mCUf .styles_signoffButton__35sa9 [background] {
  border-color: #000000;
  background-color: #000000;
}
.styles_timesheetDetailSignoffRequest__1mCUf .styles_signoffButton__35sa9:not(:disabled):hover {
  color: white;
}
.styles_timesheetDetailSignoffRequest__1mCUf .styles_signoffButton__35sa9:not(:disabled):hover svg * {
  fill: white;
}
.styles_timesheetDetailSignoffRequest__1mCUf .styles_signoffButton__35sa9:not(:disabled):hover[background],
.styles_timesheetDetailSignoffRequest__1mCUf .styles_signoffButton__35sa9:not(:disabled):hover [background] {
  border-color: #1a1a1a;
  background-color: #1a1a1a;
}
[data-whatintent=keyboard] .styles_timesheetDetailSignoffRequest__1mCUf .styles_signoffButton__35sa9:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_timesheetDetailSignoffRequest__1mCUf .styles_signoffButton__35sa9:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_timesheetDetailSignoffRequest__1mCUf .styles_signoffButton__35sa9:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_timesheetDetailSignoffStatus__1cq8D {
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  padding: 12px;
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 28px;
}
.styles_timesheetDetailSignoffStatus__1cq8D .styles_signoffSvg__1CiCL {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: right;
  align-self: center;
  margin-right: 0.35rem;
}
.styles_timesheetDetailSignoffStatus__1cq8D .styles_signoffSvg__1CiCL .styles_penSVG__2HvCh {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.styles_timesheetDetailSignoffStatus__1cq8D .styles_signoffSvg__1CiCL .styles_penSVG__2HvCh svg * {
  fill: #B538DE;
}
.styles_timesheetDetailSignoffStatus__1cq8D .styles_signoffSvg__1CiCL svg {
  width: 2rem;
  height: 2rem;
}

.styles_divider__3n6GM {
  margin-top: 1.5rem;
  color: #D8D8D8;
}

.styles_timesheetDetailTasks__1ZaZL {
  margin: 1rem 0;
}

.styles_timesheetDetailSectionHeader__oO4X- {
  margin: 1rem 0;
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.65rem;
  font-weight: 900;
}

.styles_timesheetDetailSection__2FWF_ {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: "icon header" "content content";
}
.styles_timesheetDetailSection__2FWF_ .styles_sectionSVG__1N-yJ {
  display: flex;
  align-self: center;
  padding-right: 1rem;
  grid-area: icon;
}
.styles_timesheetDetailSection__2FWF_ .styles_sectionSVG__1N-yJ svg {
  width: 2rem;
  height: 2rem;
}
.styles_timesheetDetailSection__2FWF_ .styles_timesheetSectionText__2QQwc {
  grid-area: header;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 1rem;
  font-weight: 500;
}

.styles_timesheetPhoto__iaYhX {
  grid-area: content;
}

.styles_taskLink__3UViN {
  color: #490BF4;
  cursor: pointer;
}

.styles_uploadDisabled__3VskW {
  background-color: #EEEEEE;
  pointer-events: none;
}

.styles_timesheetAddPhotoContainer__Q2cgu {
  margin: 1rem;
}
.styles_timesheetAddPhotoContainer__Q2cgu .styles_timesheetPhotoUploader__3Bei6 {
  padding: 1rem 0;
}
.styles_timesheetAddPhotoContainer__Q2cgu .styles_removePhotoButton__1tZ_h {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  padding: 6px;
  border: 1px solid #FFFFFF;
}
.styles_timesheetAddPhotoContainer__Q2cgu .styles_removePhotoButton__1tZ_h svg * {
  fill: #FFFFFF;
}
.styles_timesheetAddPhotoContainer__Q2cgu .styles_removePhotoButton__1tZ_h svg {
  width: 36px;
  height: 36px;
}

.styles_requestedInfoHeader__149xB {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.styles_requestedInfoSubHeader__T9Xe9 {
  margin: 1rem 0;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #767676;
  font-size: 1rem;
  font-weight: 300;
}

.styles_requestedInfoButton__3FtJA {
  color: #FFFFFF;
}
.styles_requestedInfoButton__3FtJA svg * {
  fill: #B538DE;
}
.styles_requestedInfoButton__3FtJA[background],
.styles_requestedInfoButton__3FtJA [background] {
  border-color: #D8D8D8;
  background-color: #000000;
}
.styles_requestedInfoButton__3FtJA:not(:disabled):hover {
  color: white;
}
.styles_requestedInfoButton__3FtJA:not(:disabled):hover svg * {
  fill: #c564e5;
}
.styles_requestedInfoButton__3FtJA:not(:disabled):hover[background],
.styles_requestedInfoButton__3FtJA:not(:disabled):hover [background] {
  border-color: #f2f2f2;
  background-color: #1a1a1a;
}
[data-whatintent=keyboard] .styles_requestedInfoButton__3FtJA:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_requestedInfoButton__3FtJA:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_requestedInfoButton__3FtJA:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_requestedInfoFields__3BfBe {
  overflow: auto;
  max-height: 60vh;
}
.styles_textCommon__-VSwY, .styles_textBold__w-0gw, .styles_textSecondary__3etD5, .styles_textDefault__3hIu1 {
  line-height: 1.4em;
}

.styles_textDefault__3hIu1 {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__3etD5 {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__w-0gw {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__2ruLN {
  font-weight: 700;
}

.styles_alignRight__18eIj {
  text-align: right;
}

.styles_alignCenter__2snq2 {
  text-align: center;
}

.styles_tagLabel__zCNyu {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__1T_Cj {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__1evZS {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__meqUN {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__3IVgv,
.styles_textHeading__2kpJQ,
.styles_textSubheading__1-y5X {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__2TNBI {
  color: #9b9b9b;
}

.styles_container__3gNGE {
  position: relative;
}

.styles_padHeading__3t2eF {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__LAyu4 {
  padding-top: 1rem;
}

.styles_padY__2zbiu, .styles_subtitle__2E5ye {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__1eb1Q {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__x6ik8 {
  margin-top: 1rem;
}

.styles_marginY__1qp75 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__LnUIt {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__2Yo2j {
  margin: 0;
  padding: 0;
}

.styles_wrapper__3Wz7W {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__3Wz7W > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__2DONz {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__2DONz > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__1waI_ {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__1waI_ > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__1vRM3 {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__1vRM3 > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__2_Oa5, .styles_subtitle__2E5ye {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__2_Oa5 > *, .styles_subtitle__2E5ye > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__2OyIF {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__2OyIF > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__3IVgv,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__2kpJQ,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__1-y5X,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__P-i5m {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__3s73X {
    padding: 1rem;
  }
  .styles_hideMedium__1Ld9B {
    display: none !important;
  }
  .styles_hideLarge__1QkzN {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__3s73X {
    padding: 1rem;
  }
  .styles_subtitle__2E5ye {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__34ays {
    display: none !important;
  }
  .styles_rowResize__2iSsv > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__2iSsv > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__3s73X {
    padding: 0.5rem;
  }
  .styles_padX__1eb1Q {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__3hIu1 {
    font-size: 1.2rem;
  }
  .styles_textSecondary__3etD5 {
    font-size: 1.02rem;
  }
  .styles_textBold__w-0gw {
    font-size: 1.2rem;
  }
  .styles_textHint__1T_Cj {
    font-size: 0.84rem;
  }
  .styles_textLabel__1evZS {
    font-size: 1.2rem;
  }
  .styles_navLabel__meqUN {
    font-size: 0.84rem;
  }
  .styles_tagLabel__zCNyu {
    font-size: 0.85rem;
  }
}
.styles_textTitle__3IVgv {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__3WgWR {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__23Qv1 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__28dMB {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__3L5N9 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__1137k {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__17WH1 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__w-0gw {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__357TI {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__2_XAX {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__3m5ok {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__1-crL {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__WgQO8 {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__1ibO6 {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__1LcYA {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__3F5FR {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__2U1zi {
  border: 1px solid #E2164A;
}

.styles_jobCardContainer__2IsWM {
  display: flex;
  flex-direction: column;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  padding: 1rem;
  background: #FFFFFF;
}
.styles_jobCardContainer__2IsWM .styles_selfLoggedBadge__3MDPM {
  display: flex;
  flex-direction: row;
  background: #767676;
  border-radius: 15px;
  margin-bottom: 0.5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #FFFFFF;
  padding-left: 1rem;
  padding-right: 1rem;
}
.styles_jobCardContainer__2IsWM .styles_jobInfo__2WKzv {
  display: flex;
  flex-direction: column;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}
.styles_jobCardContainer__2IsWM .styles_jobInfo__2WKzv .styles_numberText__1x825 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
  margin-bottom: 1rem;
}
.styles_jobCardContainer__2IsWM .styles_jobInfoProps__XjzQa {
  display: flex;
  flex-direction: row;
}
.styles_jobCardContainer__2IsWM .styles_jobInfoProps__XjzQa .styles_label__3Anhq {
  min-width: 90px;
}
.styles_jobCardContainer__2IsWM .styles_divider__3spaP {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #D8D8D8;
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}
.styles_jobCardContainer__2IsWM .styles_tickets__8u2ey {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.styles_jobCardContainer__2IsWM .styles_tickets__8u2ey .styles_ticketsCountText__2I8OY {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}
.styles_jobCardContainer__2IsWM .styles_tickets__8u2ey .styles_button__2HHLd {
  color: #490BF4;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  height: 2.5rem;
}
.styles_jobCardContainer__2IsWM .styles_tickets__8u2ey .styles_button__2HHLd svg * {
  fill: #490BF4;
}
.styles_jobCardContainer__2IsWM .styles_tickets__8u2ey .styles_button__2HHLd[background],
.styles_jobCardContainer__2IsWM .styles_tickets__8u2ey .styles_button__2HHLd [background] {
  border-color: #D8D8D8;
  background-color: #FFFFFF;
}
.styles_jobCardContainer__2IsWM .styles_tickets__8u2ey .styles_button__2HHLd:not(:disabled):hover {
  color: #6d3cf6;
}
.styles_jobCardContainer__2IsWM .styles_tickets__8u2ey .styles_button__2HHLd:not(:disabled):hover svg * {
  fill: #6d3cf6;
}
.styles_jobCardContainer__2IsWM .styles_tickets__8u2ey .styles_button__2HHLd:not(:disabled):hover[background],
.styles_jobCardContainer__2IsWM .styles_tickets__8u2ey .styles_button__2HHLd:not(:disabled):hover [background] {
  border-color: #f2f2f2;
  background-color: white;
}
[data-whatintent=keyboard] .styles_jobCardContainer__2IsWM .styles_tickets__8u2ey .styles_button__2HHLd:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_jobCardContainer__2IsWM .styles_tickets__8u2ey .styles_button__2HHLd:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_jobCardContainer__2IsWM .styles_tickets__8u2ey .styles_button__2HHLd:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_textCommon__2ANkl, .styles_textBold__1UIkS, .styles_textSecondary__7QEzL, .styles_textDefault__2wwXI {
  line-height: 1.4em;
}

.styles_textDefault__2wwXI {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__7QEzL {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__1UIkS {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__1mnx_ {
  font-weight: 700;
}

.styles_alignRight__2unun {
  text-align: right;
}

.styles_alignCenter__3Jqzq {
  text-align: center;
}

.styles_tagLabel__pnyDU {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__2Eq4T {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__3wen4 {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__1-QDR {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__2B84L,
.styles_textHeading__1fYep,
.styles_textSubheading__3x-Ef {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__1a7Ai {
  color: #9b9b9b;
}

.styles_container__pMSrU {
  position: relative;
}

.styles_padHeading__2FOBn {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__3droZ {
  padding-top: 1rem;
}

.styles_padY__3X-Hp, .styles_subtitle___hmgK {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__2KlS5 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__18Ypy {
  margin-top: 1rem;
}

.styles_marginY__A-udw {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__2ZXiJ {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__39kZ9 {
  margin: 0;
  padding: 0;
}

.styles_wrapper__1Jm5y {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__1Jm5y > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__2WrE3 {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__2WrE3 > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__1APnS {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__1APnS > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__3t0Su {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__3t0Su > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__13bFI, .styles_subtitle___hmgK {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__13bFI > *, .styles_subtitle___hmgK > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__2dY-8 {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__2dY-8 > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__2B84L,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__1fYep,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__3x-Ef,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__3VHVd {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__3ZSf4 {
    padding: 1rem;
  }
  .styles_hideMedium__2eu2A {
    display: none !important;
  }
  .styles_hideLarge__27c8b {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__3ZSf4 {
    padding: 1rem;
  }
  .styles_subtitle___hmgK {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__3yEwG {
    display: none !important;
  }
  .styles_rowResize__3Fiae > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__3Fiae > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__3ZSf4 {
    padding: 0.5rem;
  }
  .styles_padX__2KlS5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__2wwXI {
    font-size: 1.2rem;
  }
  .styles_textSecondary__7QEzL {
    font-size: 1.02rem;
  }
  .styles_textBold__1UIkS {
    font-size: 1.2rem;
  }
  .styles_textHint__2Eq4T {
    font-size: 0.84rem;
  }
  .styles_textLabel__3wen4 {
    font-size: 1.2rem;
  }
  .styles_navLabel__1-QDR {
    font-size: 0.84rem;
  }
  .styles_tagLabel__pnyDU {
    font-size: 0.85rem;
  }
}
.styles_textTitle__2B84L {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__lGSba {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__LTjLp {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__2dCQc {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__hTWlP {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__3M9oE {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__B9F66 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__1UIkS {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__2SR4s {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__1bnPd {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__22N9u {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__2nf9J {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__237pW {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__9RHe- {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__1DQjh {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__2eDYD {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__1L1au {
  border: 1px solid #E2164A;
}

.styles_jobsContainer__2gD2e {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.styles_textCommon__3bskM, .styles_textBold__3Eqqf, .styles_textSecondary__1gp_t, .styles_textDefault__2Fh_W {
  line-height: 1.4em;
}

.styles_textDefault__2Fh_W {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__1gp_t {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__3Eqqf {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__3mtvB {
  font-weight: 700;
}

.styles_alignRight__24-_M {
  text-align: right;
}

.styles_alignCenter__VXMpG {
  text-align: center;
}

.styles_tagLabel__3bLsq {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__1j_tL {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__1aT8e {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__2pn0b {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__1IbmJ,
.styles_textHeading__2_fKl,
.styles_textSubheading__1_81O {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__3Za3z {
  color: #9b9b9b;
}

.styles_container__1AJuc {
  position: relative;
}

.styles_padHeading__1dthv {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__2-PDa {
  padding-top: 1rem;
}

.styles_padY__14_CQ, .styles_subtitle__2Ts5t {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__3Lbn_ {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__1edOo {
  margin-top: 1rem;
}

.styles_marginY__23l6r {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__S3UqO {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__3Frhm {
  margin: 0;
  padding: 0;
}

.styles_wrapper__3rvb4 {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__3rvb4 > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__88_Kk {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__88_Kk > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__2oiDz {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__2oiDz > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__3Ko3K {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__3Ko3K > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__wQUOa, .styles_subtitle__2Ts5t {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__wQUOa > *, .styles_subtitle__2Ts5t > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__3i2ty {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__3i2ty > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__1IbmJ,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__2_fKl,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__1_81O,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__27YFf {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__LT6N5 {
    padding: 1rem;
  }
  .styles_hideMedium__33Vs_ {
    display: none !important;
  }
  .styles_hideLarge__LJ86j {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__LT6N5 {
    padding: 1rem;
  }
  .styles_subtitle__2Ts5t {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__8GVs- {
    display: none !important;
  }
  .styles_rowResize__2EvH1 > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__2EvH1 > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__LT6N5 {
    padding: 0.5rem;
  }
  .styles_padX__3Lbn_ {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__2Fh_W {
    font-size: 1.2rem;
  }
  .styles_textSecondary__1gp_t {
    font-size: 1.02rem;
  }
  .styles_textBold__3Eqqf {
    font-size: 1.2rem;
  }
  .styles_textHint__1j_tL {
    font-size: 0.84rem;
  }
  .styles_textLabel__1aT8e {
    font-size: 1.2rem;
  }
  .styles_navLabel__2pn0b {
    font-size: 0.84rem;
  }
  .styles_tagLabel__3bLsq {
    font-size: 0.85rem;
  }
}
.styles_textTitle__1IbmJ {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__3wxQM {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__qyFKG {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__3RCz8 {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__2quTN {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__2185S {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__1sqOx {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__3Eqqf {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__16M8m {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__7GxuT {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__1UkYo {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__25Am4 {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__3NQZe {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__8nYyq {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__3YN7p {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__xCxm2 {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__3ck9N {
  border: 1px solid #E2164A;
}

.styles_jobInfo__2wSDb {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  grid-gap: 0.35rem;
  gap: 0.35rem;
}
.styles_jobInfo__2wSDb .styles_title___gU-K {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_ticketsContainer__2GqkM {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem;
}
.styles_ticketsContainer__2GqkM .styles_button__1RY0n {
  color: #490BF4;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  height: 2.5rem;
}
.styles_ticketsContainer__2GqkM .styles_button__1RY0n svg * {
  fill: #490BF4;
}
.styles_ticketsContainer__2GqkM .styles_button__1RY0n[background],
.styles_ticketsContainer__2GqkM .styles_button__1RY0n [background] {
  border-color: #D8D8D8;
  background-color: #FFFFFF;
}
.styles_ticketsContainer__2GqkM .styles_button__1RY0n:not(:disabled):hover {
  color: #6d3cf6;
}
.styles_ticketsContainer__2GqkM .styles_button__1RY0n:not(:disabled):hover svg * {
  fill: #6d3cf6;
}
.styles_ticketsContainer__2GqkM .styles_button__1RY0n:not(:disabled):hover[background],
.styles_ticketsContainer__2GqkM .styles_button__1RY0n:not(:disabled):hover [background] {
  border-color: #f2f2f2;
  background-color: white;
}
[data-whatintent=keyboard] .styles_ticketsContainer__2GqkM .styles_button__1RY0n:not(:disabled):focus[background],
[data-whatintent=keyboard] .styles_ticketsContainer__2GqkM .styles_button__1RY0n:not(:disabled):focus [background] {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}
.styles_ticketsContainer__2GqkM .styles_button__1RY0n:not(:disabled):focus-visible {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px #106FEE;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.styles_loadingSpinner__VfuLL {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.styles_loadingSpinner__VfuLL:after {
  content: "";
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: calc(2rem/10) solid #F6CC46;
  border-color: #F6CC46 transparent;
  -webkit-animation: styles_loadingSpinnerAnimation__1Bubh 1.2s linear infinite;
          animation: styles_loadingSpinnerAnimation__1Bubh 1.2s linear infinite;
}
@-webkit-keyframes styles_loadingSpinnerAnimation__1Bubh {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes styles_loadingSpinnerAnimation__1Bubh {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.styles_textCommon__3WeTM, .styles_textBold__2by0q, .styles_textSecondary__2zmXd, .styles_textDefault__199Of {
  line-height: 1.4em;
}

.styles_textDefault__199Of {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__2zmXd {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__2by0q {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__1xgiL {
  font-weight: 700;
}

.styles_alignRight__uAygJ {
  text-align: right;
}

.styles_alignCenter__1sXsj {
  text-align: center;
}

.styles_tagLabel__3LLhJ {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__fzndv {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__n0jdJ {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__2PRZY {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__2Uwnt,
.styles_textHeading__2u_ub,
.styles_textSubheading__2wgy8 {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__p9Ve9 {
  color: #9b9b9b;
}

.styles_container__ay2hl {
  position: relative;
}

.styles_padHeading__36e1x {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__37yK4 {
  padding-top: 1rem;
}

.styles_padY__37oHp, .styles_subtitle__1MOdQ {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__3NnwE {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__28wyC {
  margin-top: 1rem;
}

.styles_marginY__2Oq4Y {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__3ZW0E {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__38pAO {
  margin: 0;
  padding: 0;
}

.styles_wrapper__S0ZRz {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__S0ZRz > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__-khOg {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__-khOg > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__1Oy9S {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__1Oy9S > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__paSNo {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__paSNo > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__1VHTd, .styles_subtitle__1MOdQ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__1VHTd > *, .styles_subtitle__1MOdQ > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__2AvR3 {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__2AvR3 > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__2Uwnt,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__2u_ub,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__2wgy8,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__5sf1B {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__37udr {
    padding: 1rem;
  }
  .styles_hideMedium__3W9b- {
    display: none !important;
  }
  .styles_hideLarge__191IK {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__37udr {
    padding: 1rem;
  }
  .styles_subtitle__1MOdQ {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__2hGnk {
    display: none !important;
  }
  .styles_rowResize__33GPj > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__33GPj > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__37udr {
    padding: 0.5rem;
  }
  .styles_padX__3NnwE {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__199Of {
    font-size: 1.2rem;
  }
  .styles_textSecondary__2zmXd {
    font-size: 1.02rem;
  }
  .styles_textBold__2by0q {
    font-size: 1.2rem;
  }
  .styles_textHint__fzndv {
    font-size: 0.84rem;
  }
  .styles_textLabel__n0jdJ {
    font-size: 1.2rem;
  }
  .styles_navLabel__2PRZY {
    font-size: 0.84rem;
  }
  .styles_tagLabel__3LLhJ {
    font-size: 0.85rem;
  }
}
.styles_textTitle__2Uwnt {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.styles_textHeader__MA0kh {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.styles_textSubheader__2Nwbd {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.styles_tertiaryHeader__25vHq {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.styles_textSubSubheader__1dkdu {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.styles_textBody__V4IGA {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.styles_textSmallBody__7QiiU {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.styles_textBold__2by0q {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.styles_textLargeLabel__1II36 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.styles_textRegularLabel__rx8LM {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.styles_textSmallLabel__WS-15 {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.styles_textNavigation__LGg1S {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.styles_backgroundFlat__1-WVf {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.styles_backgroundClick__1wdpG {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.styles_backgroundHover__2EtlK {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.styles_backgroundSelected__304nb {
  border: 1px solid #B538DE;
}

.styles_backgroundAlert__3BMo8 {
  border: 1px solid #E2164A;
}

.styles_ticketCardContainer__3-fUn {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.styles_ticketCardContainer__3-fUn .styles_time__3st2d {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}
.styles_ticketCardContainer__3-fUn .styles_card__3Ka_j {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  padding: 1rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}
.styles_ticketCardContainer__3-fUn .styles_card__3Ka_j .styles_col1__3up8S .styles_row1__DtK-L {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 0.5rem;
}
.styles_ticketCardContainer__3-fUn .styles_card__3Ka_j .styles_col1__3up8S .styles_row1__DtK-L .styles_status__AAwBL {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.styles_ticketCardContainer__3-fUn .styles_card__3Ka_j .styles_col1__3up8S .styles_row1__DtK-L .styles_status__AAwBL .styles_statusText__26voa {
  margin-left: 0.5rem;
}
.styles_ticketCardContainer__3-fUn .styles_card__3Ka_j .styles_col1__3up8S .styles_row2__1KEU9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  color: #767676;
}
.styles_ticketCardContainer__3-fUn .styles_card__3Ka_j .styles_col1__3up8S .styles_row2__1KEU9 svg circle {
  fill: #767676 !important;
  stroke: transparent !important;
}
.styles_ticketCardContainer__3-fUn .styles_card__3Ka_j .styles_col2__IDfKf {
  display: flex;
  align-items: center;
}
.styles_ticketCardContainer__3-fUn .styles_card__3Ka_j .styles_col2__IDfKf svg path {
  fill: #767676 !important;
  stroke: transparent !important;
}
.styles_textCommon__zQ_8U, .styles_textBold__3XI1_, .styles_textSecondary__25Aps, .styles_textDefault__DBEAr {
  line-height: 1.4em;
}

.styles_textDefault__DBEAr {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__25Aps {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__3XI1_ {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__3YUN0 {
  font-weight: 700;
}

.styles_alignRight__1sDgg {
  text-align: right;
}

.styles_alignCenter__STDFu {
  text-align: center;
}

.styles_tagLabel__2KmdE {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__CY1_2 {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__212Eu {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__2vsO9 {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__2GVij,
.styles_textHeading__A48JC,
.styles_textSubheading__2C1wo {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__6f9CT {
  color: #9b9b9b;
}

.styles_container__bqG43 {
  position: relative;
}

.styles_padHeading__CDE4G {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__3STWb {
  padding-top: 1rem;
}

.styles_padY__14Esa, .styles_subtitle__2ctWH {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__1zNLC {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__2xOiD {
  margin-top: 1rem;
}

.styles_marginY__3jbmP {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__OmsFn {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__1VAJP {
  margin: 0;
  padding: 0;
}

.styles_wrapper__1-bkd {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__1-bkd > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__1Jnf7 {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__1Jnf7 > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__3Hmbb {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__3Hmbb > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__Ia_6H {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__Ia_6H > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__35-Re, .styles_subtitle__2ctWH {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__35-Re > *, .styles_subtitle__2ctWH > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__3Y03x {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__3Y03x > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__2GVij,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__A48JC,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__2C1wo,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__2BBIs {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__1SsGl {
    padding: 1rem;
  }
  .styles_hideMedium__1Dn1w {
    display: none !important;
  }
  .styles_hideLarge__xl9rw {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__1SsGl {
    padding: 1rem;
  }
  .styles_subtitle__2ctWH {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__3z928 {
    display: none !important;
  }
  .styles_rowResize__1DrT2 > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__1DrT2 > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__1SsGl {
    padding: 0.5rem;
  }
  .styles_padX__1zNLC {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__DBEAr {
    font-size: 1.2rem;
  }
  .styles_textSecondary__25Aps {
    font-size: 1.02rem;
  }
  .styles_textBold__3XI1_ {
    font-size: 1.2rem;
  }
  .styles_textHint__CY1_2 {
    font-size: 0.84rem;
  }
  .styles_textLabel__212Eu {
    font-size: 1.2rem;
  }
  .styles_navLabel__2vsO9 {
    font-size: 0.84rem;
  }
  .styles_tagLabel__2KmdE {
    font-size: 0.85rem;
  }
}
.styles_tabBar__ImhA1 {
  width: 100%;
  box-shadow: 0 -4px 14px 0px rgba(0, 0, 0, 0.12);
  z-index: 3;
  background-color: #fff;
}

.styles_tabBarToggle__GzsA9 {
  display: flex;
  padding: 0.5rem;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.styles_featuredAssignment__3JAGa {
  padding: 0 1rem;
}

.styles_logButton__7FUuc {
  padding: 0.5rem 0;
}
.styles_textCommon__EC86q, .styles_textBold__1MPXX, .styles_textSecondary__1GY49, .styles_textDefault__27Hp_ {
  line-height: 1.4em;
}

.styles_textDefault__27Hp_ {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__1GY49 {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__1MPXX {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__2x1yw {
  font-weight: 700;
}

.styles_alignRight__3DsmB {
  text-align: right;
}

.styles_alignCenter__2K7LI {
  text-align: center;
}

.styles_tagLabel__Qd8Nn {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__2uPuE {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__df34E {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__3jPU7, .styles_help__12-CU {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__C_O0j,
.styles_textHeading__2K_Gi,
.styles_textSubheading__3Izf9 {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__2EzBU {
  color: #9b9b9b;
}

.styles_container__2YlfY {
  position: relative;
}

.styles_padHeading__3vQQT {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__3LoiP {
  padding-top: 1rem;
}

.styles_padY__3dK1O, .styles_subtitle__21HtY {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__1JDyx {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__2PStx {
  margin-top: 1rem;
}

.styles_marginY__1G0vH {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__YyWUx {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__2Jlww {
  margin: 0;
  padding: 0;
}

.styles_wrapper__1Iuc2 {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__1Iuc2 > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__15cGJ {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__15cGJ > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__N3N16 {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__N3N16 > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__2CFlO {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__2CFlO > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__3KL_J, .styles_subtitle__21HtY {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__3KL_J > *, .styles_subtitle__21HtY > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__3LJi7 {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__3LJi7 > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__C_O0j,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__2K_Gi,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__3Izf9,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__1fTs- {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__1p06D, .styles_topNavBar__3-GZ2 {
    padding: 1rem;
  }
  .styles_hideMedium__JPO_x {
    display: none !important;
  }
  .styles_hideLarge__oQ7ul {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__1p06D, .styles_topNavBar__3-GZ2 {
    padding: 1rem;
  }
  .styles_subtitle__21HtY {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__1Qfwa {
    display: none !important;
  }
  .styles_rowResize__321b8 > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__321b8 > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__1p06D, .styles_topNavBar__3-GZ2 {
    padding: 0.5rem;
  }
  .styles_padX__1JDyx {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__27Hp_ {
    font-size: 1.2rem;
  }
  .styles_textSecondary__1GY49 {
    font-size: 1.02rem;
  }
  .styles_textBold__1MPXX {
    font-size: 1.2rem;
  }
  .styles_textHint__2uPuE {
    font-size: 0.84rem;
  }
  .styles_textLabel__df34E {
    font-size: 1.2rem;
  }
  .styles_navLabel__3jPU7, .styles_help__12-CU {
    font-size: 0.84rem;
  }
  .styles_tagLabel__Qd8Nn {
    font-size: 0.85rem;
  }
}
.styles_topNavBar__3-GZ2 {
  background: #000;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.styles_topNavBar__3-GZ2 svg * {
  fill: #fff;
}

.styles_environment__2fL5U {
  background: #eeeeee;
  color: #4D4D4D;
  text-align: center;
  font-weight: 500;
  font-size: 0.8rem;
  letter-spacing: 0.025rem;
  padding: 0.25rem 0.5rem;
  text-transform: uppercase;
  line-height: 1.2;
}

.styles_help__12-CU {
  display: flex;
  align-items: center;
  color: #fff;
  margin-right: 0.35rem;
}
.styles_help__12-CU svg * {
  fill: #fff;
}
.styles_help__12-CU span {
  position: relative;
  top: 2px;
  margin-right: 0.35rem;
}
.styles_textCommon__3PJyV, .styles_textBold__1mXfY, .styles_textSecondary__3kbtD, .styles_textDefault__1-yJ9 {
  line-height: 1.4em;
}

.styles_textDefault__1-yJ9 {
  font-size: 1rem;
  color: #4D4D4D;
}

.styles_textSecondary__3kbtD {
  font-size: 0.85rem;
  color: #9b9b9b;
}

.styles_textBold__1mXfY {
  font-size: 1rem;
  color: #000;
  font-weight: 700;
}

.styles_bold__2RUYj {
  font-weight: 700;
}

.styles_alignRight__2Y200 {
  text-align: right;
}

.styles_alignCenter__3ubjZ {
  text-align: center;
}

.styles_tagLabel__2MXCI {
  font-size: 0.765rem;
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-weight: 700;
}

.styles_textHint__2T6e_ {
  color: #9b9b9b;
  font-size: 0.7rem;
}

.styles_textLabel__21XTz {
  color: inherit;
  font-size: 16px;
  font-family: "Assistant", sans-serif;
  line-height: 1.4rem;
}

.styles_navLabel__3lceT {
  font-size: 0.7rem;
  font-family: "Assistant", sans-serif;
}

.styles_textTitle__3QC2p,
.styles_textHeading__3kadv,
.styles_textSubheading__37yjW {
  line-height: 1em;
  font-family: "Assistant", sans-serif;
}

.styles_light__3HfIk {
  color: #9b9b9b;
}

.styles_container__1Cfj- {
  position: relative;
}

.styles_padHeading__23m_3 {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.styles_padTop__3iZZ_ {
  padding-top: 1rem;
}

.styles_padY__3J03C, .styles_subtitle__3iIZ6 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles_padX__cQtCj {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles_marginTop__2zH7C {
  margin-top: 1rem;
}

.styles_marginY__2jF-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.styles_marginX__3rVCQ {
  margin-left: 1rem;
  margin-right: 1rem;
}

.styles_margin0__1hDpF {
  margin: 0;
  padding: 0;
}

.styles_wrapper__1qwWj {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapper__1qwWj > * {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperLeft__3FOpp {
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperLeft__3FOpp > * {
  display: flex;
  align-items: center;
  justify-content: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperRight__2redE {
  display: flex;
  justify-content: right;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperRight__2redE > * {
  display: flex;
  align-items: center;
  justify-content: right;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperMiddle__1AF0R {
  display: flex;
  justify-content: center;
  align-items: left;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperMiddle__1AF0R > * {
  display: flex;
  align-items: left;
  justify-content: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_wrapperSpaceBetween__vVjab, .styles_subtitle__3iIZ6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.styles_wrapperSpaceBetween__vVjab > *, .styles_subtitle__3iIZ6 > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 3;
}

.styles_buttonWrap__38kpH {
  display: flex;
  align-items: center;
}
.styles_buttonWrap__38kpH > * {
  margin: 0.5rem;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.styles_textTitle__3QC2p,
h1 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
}

.styles_textHeading__3kadv,
h2 {
  font-weight: 700;
  color: #4D4D4D;
  font-size: 1.65rem;
}

.styles_textSubheading__37yjW,
h3 {
  font-weight: 700;
  font-size: 1.3rem;
  color: #4D4D4D;
}

.styles_whiteSpaceWrap__stgKq {
  white-space: pre-wrap;
}

@media (min-width: 961px) {
  .styles_wrap__GISrs {
    padding: 1rem;
  }
  .styles_hideMedium__ThF6l {
    display: none !important;
  }
  .styles_hideLarge__1uICw {
    display: none !important;
  }
}
@media (max-width: 960px) {
  .styles_wrap__GISrs {
    padding: 1rem;
  }
  .styles_subtitle__3iIZ6 {
    display: block;
    padding: 0 0.5rem;
  }
  .styles_hideSmall__2twce {
    display: none !important;
  }
  .styles_rowResize__3v6em > *:first-child {
    flex: 2 1;
  }
  .styles_rowResize__3v6em > *:last-child {
    flex: 1 1;
  }
}
@media (max-width: 500px) {
  .styles_wrap__GISrs {
    padding: 0.5rem;
  }
  .styles_padX__cQtCj {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 320px) and (max-width: 640px) {
  .styles_textDefault__1-yJ9 {
    font-size: 1.2rem;
  }
  .styles_textSecondary__3kbtD {
    font-size: 1.02rem;
  }
  .styles_textBold__1mXfY {
    font-size: 1.2rem;
  }
  .styles_textHint__2T6e_ {
    font-size: 0.84rem;
  }
  .styles_textLabel__21XTz {
    font-size: 1.2rem;
  }
  .styles_navLabel__3lceT {
    font-size: 0.84rem;
  }
  .styles_tagLabel__2MXCI {
    font-size: 0.85rem;
  }
}
.styles_main__xf0EZ {
  display: flex;
  flex-flow: column nowrap;
  overflow-y: scroll;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

.styles_main__xf0EZ::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
#app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

*:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}
.textTitle {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 1.9rem;
  font-weight: 900;
}

.textHeader {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.4rem;
  font-weight: 900;
}

.textSubheader {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.35rem;
  font-weight: 900;
}

.tertiaryHeader {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 1.1rem;
  font-weight: 900;
}

.textSubSubheader {
  font-family: "Assistant", sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 900;
}

.textBody {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.9rem;
  font-weight: 300;
}

.textSmallBody {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #4A4A4A;
  font-size: 0.8rem;
  font-weight: 300;
}

.textBold {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 600;
}

.textLargeLabel {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}

.textRegularLabel {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}

.textSmallLabel {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  color: #000000;
  font-size: 0.85rem;
  font-weight: 400;
}

.textNavigation {
  font-family: "Assistant", sans-serif;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 900;
}

.backgroundFlat {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
}

.backgroundClick {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.33);
}

.backgroundHover {
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}

.backgroundSelected {
  border: 1px solid #B538DE;
}

.backgroundAlert {
  border: 1px solid #E2164A;
}

html,
body {
  font-family: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: normal;
  color: #4A4A4A;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
  cursor: auto !important;
  user-select: auto !important;
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
}

button {
  display: block;
  font-family: inherit;
  font-size: inherit;
  background: none;
  border: inherit;
}
button:focus {
  outline: none;
}

a,
a:visited,
a:hover {
  text-decoration: none;
  color: inherit;
}

p {
  line-height: 1.4em;
}
