@import "./variables.scss";

// Text
@import url("https://fonts.googleapis.com/css?family=Hind:300,500,700");
@import url("https://fonts.googleapis.com/css?family=Assistant:400,700&display=swap");


html,
body {
  font-family: $font;
  font-size: $font-size-default;
  font-weight: $font-weight-light;
  line-height: normal;
  color: $colorText;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;

  // XXX: react-beautiful-dnd likes to globally set styles during drag events.
  // We prevent the most expensive of those styles from taking effect here.
  //
  // This reduces style recalculation time by ~40% on large project lists.
  cursor: auto !important;
  user-select: auto !important;
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
}

button {
  display: block;
  font-family: inherit;
  font-size: inherit;
  background: none;
  border: inherit;
  &:focus {
    outline: none;
  }
}

a,
a:visited,
a:hover {
  text-decoration: none;
  color: inherit;
}

p {
  line-height: 1.4em;
}
