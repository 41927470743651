@import "components/OldCommon/Styles/variables.scss";

.main {
  display: flex;
  flex-flow: column nowrap;
  overflow-y: scroll;
  height:100%;
  -webkit-overflow-scrolling: touch;
}

.main::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
