$color-brand: #F6CC46;
$color-brand-highlight: #ffdf00;
$color-primary: #4D4D4D;
$color-secondary: #106FEE;
$color-secondaryLight: $color-secondary;
$color-selected: #B538DE;
$color-black: #000;
$color-strong: $color-black;
$color-actionable: #1E9F76;
$color-alert: #E2164A;
$color-middle: #E9610D;
$color-white: #fff;
$color-lines: #eeeeee;
$color-disabled: #d8d8d8;
$color-background: $color-white;
$color-notification: #fff4c9;

$text: $color-primary;
$text-primary: $color-primary;
$text-lite: #9b9b9b;
$text-invert: $color-background;
$text-strong: $color-strong;
$text-holder: #c3c3c3;

$font: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
$headingFont: "Assistant", sans-serif;
$font-size-default: 1rem;
$font-size-title: 2rem;
$font-size-heading: 1.65rem;
$font-size-large: 1.3rem;
$font-size-small: 0.85rem;
$font-size-xsmall: 0.7rem;

$font-weight-xbold: 900;
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-default: 300;

$line-height-default: 1.35em;
$line-height-title: 1.2em;

$letter-spacing: 0.05em;

// Border
$border: 1px;
$border-thick: 2px;
$border-radius: 3px;
$border-radius-small: 2px;
$border-radius-large: 8px;

// Spaces
$spacer: 1rem;
$spacer-large: 2rem;
$spacer-medium: 0.5rem;
$spacer-small: 0.35rem;

@mixin transition($timing) {
  -moz-transition: all $timing ease-in-out;
  -o-transition: all $timing ease-in-out;
  -webkit-transition: all $timing ease-in-out;
  transition: all $timing ease-in-out;
}

@mixin calc($key, $value) {
  #{$key}: -webkit-calc(#{$value});
  #{$key}: -moz-calc(#{$value});
  #{$key}: calc(#{$value});
}

// Text

.textCommon {
  line-height: 1.4em;
}

.textDefault {
  font-size: $font-size-default;
  color: $text-primary;
  @extend .textCommon;
}

.textSecondary {
  font-size: $font-size-small;
  color: $text-lite;
  @extend .textCommon;
}

.textBold {
  font-size: $font-size-default;
  color: $text-strong;
  font-weight: $font-weight-bold;
  @extend .textCommon;
}

.bold {
  font-weight: $font-weight-bold;
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.tagLabel {
  font-size: $font-size-small*0.9;
  font-family: $font;
  font-weight: $font-weight-bold;
}

.textHint {
  color: $text-lite;
  font-size: $font-size-xsmall;
}

.textLabel {
  color: inherit;
  font-size: 16px;
  font-family: $headingFont;
  line-height: 1.4rem;
}

.navLabel {
  font-size: $font-size-xsmall;
  font-family: $headingFont;
}

.textTitle,
.textHeading,
.textSubheading {
  line-height: 1em;
  font-family: $headingFont;
}

.light {
  color: $text-lite;
}

// Wrapper
.container {
  position: relative;
}

.padHeading {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.padTop {
  padding-top: $spacer;
}

.padY {
  padding-top: $spacer;
  padding-bottom: $spacer;
}

.padX {
  padding-left: $spacer;
  padding-right: $spacer;
}

.marginTop {
  margin-top: $spacer;
}

.marginY {
  margin-top: $spacer;
  margin-bottom: $spacer;
}

.marginX {
  margin-left: $spacer;
  margin-right: $spacer;
}

.margin0 {
  margin: 0;
  padding: 0;
}

@mixin wrapper($horizontal, $vertical) {
  display: flex;
  justify-content: $horizontal;
  align-items: $vertical;
  @include transition(0.25s);
  & > * {
    display: flex;
    align-items: $vertical;
    justify-content: $horizontal;
    @include transition(0.25s);
    z-index: 3;
  }
}

.wrapper {
  @include wrapper(center, center);
}
.wrapperLeft {
  @include wrapper(left, center);
}
.wrapperRight {
  @include wrapper(right, center);
}

.wrapperMiddle {
  @include wrapper(center, left);
}

.wrapperSpaceBetween {
  @include wrapper(space-between, center);
}

.buttonWrap {
  display: flex;
  align-items: center;
  & > * {
    margin: $spacer-medium;
  }
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1.2em;
}

.textTitle,
h1 {
  font-weight: $font-weight-bold;
  color: $color-strong;
  font-size: $font-size-title;
}

.textHeading,
h2 {
  font-weight: $font-weight-bold;
  color: $text-primary;
  font-size: $font-size-heading;
}

.textSubheading,
h3 {
  font-weight: $font-weight-bold;
  font-size: $font-size-large;
  color: $text-primary;
}

.whiteSpaceWrap {
  white-space: pre-wrap;
}

.subtitle {
  @extend .wrapperSpaceBetween;
  @extend .padY;
}

@media (min-width: 961px) {
  .wrap {
    padding: $spacer;
  }

  .hideMedium {
    display: none !important;
  }
  .hideLarge {
    display: none !important;
  }
}

@media (max-width: 960px) {
  .wrap {
    padding: $spacer;
  }
  .subtitle {
    display: block;
    padding: 0 $spacer-medium;
  }
  .hideSmall {
    display: none !important;
  }
  .rowResize {
    & > * {
      &:first-child {
        flex: 2;
      }
      &:last-child {
        flex: 1;
      }
    }
  }
}

@media (max-width: 500px) {
  .wrap {
    padding: $spacer-medium;
  }
  .padX {
    padding-left: $spacer-medium;
    padding-right: $spacer-medium;
  }
}

@media (min-width: 320px) and (max-width: 640px) {
  .textDefault {
    font-size: $font-size-default*1.2;
  }

  .textSecondary {
    font-size: $font-size-small*1.2;
  }

  .textBold {
    font-size: $font-size-default*1.2;
  }

  .textHint {
    font-size: $font-size-xsmall*1.2;
  }

  .textLabel {
    font-size: $font-size-default*1.2;
  }

  .navLabel {
    font-size: $font-size-xsmall*1.2;
  }

  .tagLabel {
    font-size: $font-size-small*1;
  }
}
